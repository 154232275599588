import axios from 'axios'
import EntryType from 'common/entryType'
import { Dispatch } from 'redux'
import { addVideoHistoryEntryFlow } from 'store/history/actions'
import { showModalFlow } from 'store/modal/actions'

import {
  createLabelboxDataset,
  createLabelboxProject,
  fetchLabelboxDatasets,
  fetchLabelboxProjects,
  fetchProjectDatasetRelations,
  FrameUploadPayload,
  uploadLabelboxFrame,
  uploadLabelboxImage,
} from './actionCreators'

export const fetchProjectDatasetRelationsFlow = (page: number) => (
  dispatch: Dispatch,
) => {
  dispatch(fetchProjectDatasetRelations.request({ page }))
  axios
    .get(`/labelbox/relations?page=${page}`)
    .then(res => dispatch(fetchProjectDatasetRelations.success(res.data)))
    .catch(err => dispatch(fetchProjectDatasetRelations.failure(err)))
}

export const fetchLabelboxProjectsFlow = () => (dispatch: Dispatch) => {
  dispatch(fetchLabelboxProjects.request())
  axios
    .get('/labelbox/projects')
    .then(res => dispatch(fetchLabelboxProjects.success(res.data.projects)))
    .catch(err => dispatch(fetchLabelboxProjects.failure(err)))
}

export const fetchLabelboxDatasetsFlow = () => (dispatch: Dispatch) => {
  dispatch(fetchLabelboxDatasets.request())
  axios
    .get('/labelbox/datasets')
    .then(res => dispatch(fetchLabelboxDatasets.success(res.data.datasets)))
    .catch(err => dispatch(fetchLabelboxDatasets.failure(err)))
}

export const createLabelboxProjectFlow = (payload: string) => (
  dispatch: Dispatch,
) => {
  dispatch(createLabelboxProject.request(payload))
  axios
    .post('/labelbox/project', { projectName: payload })
    .then(res => dispatch(createLabelboxProject.success(res.data.project)))
    .catch(err => dispatch(createLabelboxProject.failure(err)))
}

export const createLabelboxDatasetFlow = (payload: string) => (
  dispatch: Dispatch,
) => {
  dispatch(createLabelboxDataset.request(payload))
  axios
    .post('/labelbox/dataset', { datasetName: payload })
    .then(res => dispatch(createLabelboxDataset.success(res.data.dataset)))
    .catch(err => dispatch(createLabelboxDataset.failure(err)))
}

export const uploadLabelboxImageFlow = (payload: FormData) => (
  dispatch: Dispatch,
) => {
  dispatch(uploadLabelboxImage.request(payload))
  axios
    .post('/labelbox/upload-images/v2', payload, {
      headers: {
        ...axios.defaults.headers,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(() => {
      dispatch(
        uploadLabelboxImage.success({
          projectId: payload.get('projectId') as string,
          datasetId: payload.get('datasetId') as string,
        }),
      )
      showModalFlow('UPLOAD_LB_SUCCESS_MODAL', {
        isOpen: true,
        projectId: payload.get('projectId'),
      })(dispatch)
    })
    .catch(err => dispatch(uploadLabelboxImage.failure(err)))
}

export const uploadLabelboxFrameFlow = (
  payload: FrameUploadPayload,
  videoId: string,
  currUserId: string,
  projectName: string,
  datasetName: string,
) => (dispatch: Dispatch) => {
  dispatch(uploadLabelboxFrame.request(payload))
  axios
    .post('/labelbox/upload-frames/v2', payload)
    .then(() => {
      dispatch(
        uploadLabelboxFrame.success({
          projectId: payload.projectId,
          datasetId: payload.datasetId,
        }),
      )
      showModalFlow('UPLOAD_LB_SUCCESS_MODAL', {
        isOpen: true,
        projectId: payload.projectId,
      })(dispatch)
      addVideoHistoryEntryFlow({
        videoId,
        userId: currUserId,
        action: `uploaded ${payload.frameIds.length} frames to labelbox project ${projectName} and dataset ${datasetName}`,
        type: EntryType.LABELBOX_UPLOAD,
      })(dispatch)
    })
    .catch(err => dispatch(uploadLabelboxFrame.failure(err)))
}
