import { Typography } from 'antd'
import { DARK_BLUE, GREY_BLUE } from 'common/colors'
import styled from 'styled-components'

const AntText = Typography.Text
const AntParagraph = Typography.Paragraph

interface Props {
  color?: string
  fontWeight?: string | number
  fontSize?: number
  wordBreak?: string
}

interface TruncatedTextProps extends Props {
  textwidth?: number
}

const Text = styled(AntText)<Props>`
  color: ${({ color }) => getColors(color)};
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px`};
  ${({ wordBreak }) => wordBreak && `word-break: ${wordBreak}`};
`

const TruncatedText = styled(Text)<TruncatedTextProps>`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ textwidth }) => textwidth && `width: ${textwidth}px`}
`

const Title = styled(Text)<Props>`
  color: ${DARK_BLUE};
  font-weight: 600;
  font-size: 18px;
`

const Paragraph = styled(AntParagraph)<Props>`
  ${({ color }) => `color: ${color}`}
`

const getColors = (color?: string) => {
  if (color) {
    return color
  }
  return GREY_BLUE
}

export { Paragraph,Text, Title, TruncatedText }
export default Text
