import _ from 'lodash'

export const parseModel = (model: any) => {
  const _id = _.get(model, ['_id', '$oid'], model['$oid'])
  const timestamp = _.get(model, ['timestamp', '$date'])
  return {
    ...model,
    _id,
    timestamp,
  }
}
export const parseVersion = (version: any) => {
  const _id = _.get(version, ['_id', '$oid'])
  const timestamp = _.get(version, ['timestamp', '$date'])
  const model = _.get(version, ['model'])

  return {
    ...version,
    _id,
    timestamp,
    model: {
      _id: _.get(model, ['_id', '$oid'], model['$oid']),
      name: _.get(model, ['name']),
    },
  }
}
