import './index.css'

import { Position,Toaster as BPToaster } from '@blueprintjs/core'

const Toaster = BPToaster.create({
  className: 'Toaster',
  position: Position.TOP,
})

export default Toaster
