import { User } from 'common/models'
import { createAsyncAction, createStandardAction } from 'typesafe-actions'

export const loginUser = createAsyncAction(
  'Login_User__Request',
  'Login_User__Success',
  'Login_User__Failure',
)<void, void, Error>()

export const logoutUser = createAsyncAction(
  'Logout_User__Request',
  'Logout_User__Success',
  'Logout_User__Failure',
)<void, void, Error>()

export const setCurrentUser = createStandardAction('Set Current User')<User>()

export const fetchCurrUserInfo = createAsyncAction(
  'Fetch_User_Info__Request',
  'Fetch_User_Info__Success',
  'Fetch_User_Info__Failure',
)<void, void, Error>()

export const fetchAllUsersInfo = createAsyncAction(
  'Fetch_All_Users_Info__Request',
  'Fetch_All_Users_Info__Success',
  'Fetch_All_Users_Info__Failure',
)<void, User[], Error>()

export const updateUserInfo = createAsyncAction(
  'Update_User_Info__Request',
  'Update_User_Info__Success',
  'Update_User_Info__Failure',
)<Partial<User>, void, Error>()

export const verifyUser = createAsyncAction(
  'Verify_User_Info__Request',
  'Verify_User_Info__Success',
  'Verify_User_Info__Failure',
)<{ token: string }, void, Error>()

export const resendVerificationEmail = createAsyncAction(
  'Resend_Verification_Email__Request',
  'Resend_Verification_Email__Success',
  'Resend_Verification_Email__Failure',
)<{ email: string }, void, Error>()

export const forgetPassword = createAsyncAction(
  'Forget_Password__Request',
  'Forget_Password__Success',
  'Forget_Password__Failure',
)<{ email: string }, void, Error>()

export const resetPassword = createAsyncAction(
  'Reset_Password__Request',
  'Reset_Password__Success',
  'Reset_Password__Failure',
)<void, void, Error>()

/**
 * @desc this api is for creating uploader account from dashboard
 */
export const createUploaderAccount = createAsyncAction(
  'Create_Uploader_Account__Request',
  'Create_Uploader_Account__Success',
  'Create_Uploader_Account__Failure',
)<{ name: string; email: string }, void, Error>()
