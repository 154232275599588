import { createSelector } from 'reselect'
import { RootState } from 'store/rootReducer'

export const authSelector = (s: RootState) => s.auth

export const getCurrentUser = createSelector(authSelector, s => s.user)

export const getAllUsers = createSelector(authSelector, s => s.users)

export const getUserIdOfCurrUser = createSelector(
  getCurrentUser,
  user => user.uid,
)

export const getAuthenticatedState = createSelector(
  authSelector,
  auth => auth.isAuthenticated,
)
