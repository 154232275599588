import { Input, Select } from 'antd'
import { Model } from 'common/models'
import { Spacing } from 'common/stylings'
import FormItem from 'components/Form/FormItem'
import Modal from 'components/Modal'
import Stack from 'components/Stack'
import { map } from 'lodash'
import React from 'react'
import {
  Field,
  FieldRenderProps,
  Form,
  FormRenderProps,
} from 'react-final-form'
import { AnyAction,Dispatch } from 'redux'
import { required } from 'utilities/validations'

const { Option } = Select

interface Props {
  isOpen: boolean
  hideModal: (...args: any) => void
  createModelVersion: (payload: {
    model: string
    major_version: string
    minor_version: string
  }) => (disptach: Dispatch<AnyAction>) => void
  models: Dict<Model>
}

const NewModelVersionModal: React.FC<Props> = ({
  isOpen,
  hideModal,
  createModelVersion,
  models,
}) => {
  const getInitialFormValues = () => ({
    model: '',
    major_version: '',
    minor_version: '',
  })

  const onSubmit = (values: {
    model: string
    major_version: string
    minor_version: string
  }) => {
    createModelVersion(values)
  }

  return (
    <Form onSubmit={onSubmit} initialValues={getInitialFormValues()}>
      {(formProps: FormRenderProps) => (
        <form onSubmit={formProps.handleSubmit}>
          <Modal
            visible={isOpen}
            onCancel={() => hideModal({ isOpen: false })}
            onOk={() => formProps.handleSubmit()}
            okButtonProps={{ htmlType: 'submit' }}
            title="Create New Version"
          >
            <Stack vertical gutter={Spacing.MEDIUM} fillParentWidth>
              <Field name="model" validate={required}>
                {(p: FieldRenderProps<string, HTMLElement>) => (
                  <FormItem
                    label="Model"
                    meta={p.meta}
                    input={
                      <Select
                        showSearch
                        onChange={p.input.onChange}
                        value={p.input.value}
                      >
                        {map(models, (model, index) => (
                          <Option key={index} value={model._id}>
                            {model.name}
                          </Option>
                        ))}
                      </Select>
                    }
                  />
                )}
              </Field>
              <Field name="major_version" validate={required}>
                {(p: FieldRenderProps<string, HTMLElement>) => (
                  <FormItem
                    label="Major Version"
                    meta={p.meta}
                    input={
                      <Input
                        value={p.input.value}
                        onChange={p.input.onChange}
                      />
                    }
                  />
                )}
              </Field>
              <Field name="minor_version" validate={required}>
                {(p: FieldRenderProps<string, HTMLElement>) => (
                  <FormItem
                    label="Minor Version"
                    meta={p.meta}
                    input={
                      <Input
                        value={p.input.value}
                        onChange={p.input.onChange}
                      />
                    }
                  />
                )}
              </Field>
            </Stack>
          </Modal>
        </form>
      )}
    </Form>
  )
}

export default NewModelVersionModal
