import { createStandardAction } from 'typesafe-actions'

export const addFilters = createStandardAction('Add_Filters')<string>()

export const addSearchField = createStandardAction('Add_SearchField')<string>()

export const addVideo = createStandardAction('Add_Video')<string>()

export const addDoctorIDs = createStandardAction('Add_Doctor_IDs')<string[]>()

export const addInputSearch = createStandardAction('Add_Input_Search')<string>()

export const addUserFilters = createStandardAction('Add_User_Filters')<Record<
  string,
  React.ReactText[] | null
> | null>()

export const addFiltersCheck = createStandardAction('Add_FiltersCheck')<
  boolean
>()
