import styled from 'styled-components'

interface Props {
  maxWidth?: number
  minWidth?: number
  width?: string
}

const Paper = styled.div<Props>`
  box-shadow: 0px 2px 4px 0px rgba(82, 63, 105, 0.05);
  background-color: #fefefe;
  width: 100%;
  padding: 40px 30px;
  overflow-x: auto;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`}
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px`}
  ${({ width }) => width && `width: ${width}`}
`

export default Paper
