import { Modal as AntModal } from 'antd'
import styled from 'styled-components'

const Modal = styled(AntModal)`
  .ant-modal-content {
    margin-top: 32px;
    width: 1000px;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 1024px) {
      width: 80%;
    }
  }
`
export const { confirm, warning, error, info, success } = AntModal

export default Modal
