import dayjs, { Dayjs } from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import _ from 'lodash'
dayjs.extend(isoWeek)

/**
 * @param date in the format of Date() time e.g Mon, 07 Jan 2019 00:00:00 GMT
 * @returns the formatted date in DD MMM YYYY
 */
export const getLongDateString = (date: string) =>
  dayjs(date).format('DD MMM YYYY')

export const getDifferenceInDays = (startDate: string, endDate: string) => {
  const start = dayjs(startDate)
  const end = dayjs(endDate)
  return end.diff(start, 'days')
}

/**
 * Returns the formatted date in YYYY-MM-DDTHH:mm:ssT
 * @param date Dayjs
 * @return isodate
 */
export const getISODate = (date: Dayjs) => date.toISOString()

export const getStartDateOfISOWeek = (date: string) =>
  dayjs(date).clone().startOf('isoWeek')

export const getEndDateOfISOWeek = (date: string) =>
  dayjs(date).clone().endOf('isoWeek')

/**
 * @param date in the format of Date() time e.g Mon, 07 Jan 2019 00:00:00 GMT
 * @returns the formatted date in MMM YYYY
 */
export const getMonthAndYearString = (date: string) =>
  dayjs(date).format('MMM YYYY')

export const getWeekRangeString = (date: string) => {
  const weekStart = getStartDateOfISOWeek(date).format('DD MMM YY')
  const weekEnd = getEndDateOfISOWeek(date).format('DD MMM YY')
  return weekStart + ' - ' + weekEnd
}

export const getAllWeeksFromDateRange = (
  startDate: string,
  endDate: string,
) => {
  const firstWeekISOStartDate = getStartDateOfISOWeek(startDate)
  const lastWeekISOEndDate = getEndDateOfISOWeek(endDate)
  const diffInWeeks = lastWeekISOEndDate.diff(firstWeekISOStartDate, 'weeks')

  const allWeeks = _.times(diffInWeeks + 1, i => {
    const week = firstWeekISOStartDate.clone().add(i++, 'week')
    const weekStart = week.startOf('isoWeek').format('DD MMM YY')
    const weekEnd = week.endOf('isoWeek').format('DD MMM YY')
    return weekStart + ' - ' + weekEnd
  })

  return allWeeks
}

export const getAllMonthsFromDateRange = (
  startDate: string,
  endDate: string,
) => {
  const firstWeekISOStartDate = getStartDateOfISOWeek(startDate)
  const lastWeekISOEndDate = getEndDateOfISOWeek(endDate)
  const diffInMonths = lastWeekISOEndDate.diff(firstWeekISOStartDate, 'months')

  const allMonths = _.times(diffInMonths + 1, i => {
    const month = firstWeekISOStartDate.clone().add(i++, 'month')
    return month.format('MMM YYYY')
  })
  return allMonths
}

/**🤔
 * @param seconds in seconds
 * @returns the formatted time range in HH:mm:ss
 */
export const getHourMinSecString = (seconds: number) => {
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor((seconds % 3600) % 60)
  return [h, m, s].join(':')
}

/**
 * @param startTime in seconds
 * @param endTime in seconds
 * @returns the formatted time range in HH:mm:ss - HH:mm:ss
 */
export const getTimeRangeString = (startTime: number, endTime: number) =>
  getHourMinSecString(startTime) + ' - ' + getHourMinSecString(endTime)
