import { Icon } from '@blueprintjs/core'
import { VideoSegment } from 'common/models'
import { Spacing } from 'common/stylings'
import Stack from 'components/Stack'
import { Text } from 'components/Typography'
import { searchString } from 'main/videoAssets/FilterPanel/index'
import VideoModal from 'main/videoAssets/VideoModal'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import {
  addFiltersFlow,
  addSearchFieldFlow,
  addVideoFlow,
} from 'store/restoreVideoState/actions'

import { customFilters } from './VideoColumns'

type Props = StoreProps<() => {}, typeof mapDispatchToProps> & VideoProps

interface VideoProps {
  video: VideoSegment
}

const PlayColumn: React.FC<Props> = ({
  video,
  addFilters,
  addVideo,
  addSearchField,
}) => {
  const [isVisible, toggleVisible] = useState(false)
  const saveParameters = () => {
    // Save filters
    const userFilters = JSON.stringify(customFilters)
    addFilters(userFilters)

    // Save video details
    const videoString = JSON.stringify(video)
    addVideo(videoString)

    // Show video on click
    toggleVisible(true)

    // Save search input
    addSearchField(searchString)
  }

  return (
    <div>
      <Stack
        gutter={Spacing.SMALL}
        onClick={() => saveParameters()}
        style={{ cursor: 'pointer' }}>
        <Text
          style={{
            paddingRight: '15px',
          }}>
          {video.filename}
        </Text>
        <Icon icon="play" style={{ fontSize: 18, alignSelf: 'center' }} />
      </Stack>
      {isVisible && (
        <VideoModal
          video={video}
          isVisible={isVisible}
          toggleVisible={toggleVisible}
          activeKey={'1'}
        />
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addFilters: addFiltersFlow,
      addVideo: addVideoFlow,
      addSearchField: addSearchFieldFlow,
    },
    dispatch,
  )

export default connect(null, mapDispatchToProps)(PlayColumn)
