import './CreateUploaderAccountModal.css'

import { Button, Input } from 'antd'
import { Spacing } from 'common/stylings'
import ErrorText from 'components/ErrorText'
import Modal from 'components/Modal'
import Stack from 'components/Stack'
import React from 'react'
import {
  Field,
  FieldRenderProps,
  Form,
  FormRenderProps,
} from 'react-final-form'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { createDoctorAccountFlow } from 'store/doctor/actions'
import {
  composeValidators,
  mustBe8Char,
  mustBeEmail,
  required,
} from 'utilities/validations'

type Props = OwnProps & StoreProps<() => {}, typeof mapDispatchToProps>

interface OwnProps {
  isOpen: boolean
  hideModal: (...args: any) => void
}

const CreateNewDoctor: React.FC<Props> = ({
  isOpen,
  hideModal,
  createNewDoctor,
}) => {
  const onSubmit = (values: {
    name: string
    email: string
    password: string
  }) => {
    createNewDoctor(values.name, values.email.toLowerCase(), values.password)
  }

  const getInitialFormValues = () => ({
    name: '',
    email: '',
    password: '',
  })

  return (
    <Modal
      title="Create a user account for video uploader"
      visible={isOpen}
      onCancel={() => hideModal({ isOpen: false })}
      footer={null}>
      <Stack vertical gutter={Spacing.MEDIUM}>
        <Form onSubmit={onSubmit} initialValues={getInitialFormValues()}>
          {(formProps: FormRenderProps) => (
            <form onSubmit={formProps.handleSubmit}>
              <div>
                <Field name="name" validate={required}>
                  {(p: FieldRenderProps<string, HTMLElement>) => (
                    <div className="field-box">
                      <Input
                        onChange={p.input.onChange}
                        placeholder="Name"
                        size="large"
                      />
                      <ErrorText
                        error={p.meta.error}
                        touched={p.meta.touched}
                      />
                    </div>
                  )}
                </Field>
              </div>
              <div className="field-box">
                <Field
                  name="email"
                  validate={composeValidators(mustBeEmail, required)}>
                  {(p: FieldRenderProps<string, HTMLElement>) => (
                    <div>
                      <Input
                        onChange={p.input.onChange}
                        placeholder="Email"
                        size="large"
                      />
                      <ErrorText
                        error={p.meta.error}
                        touched={p.meta.touched}
                      />
                    </div>
                  )}
                </Field>
              </div>
              <div className="field-box">
                <Field
                  name="password"
                  validate={composeValidators(mustBe8Char, required)}>
                  {(p: FieldRenderProps<string, HTMLElement>) => (
                    <div>
                      <Input.Password
                        onChange={p.input.onChange}
                        placeholder="Password"
                        size="large"
                      />
                      <ErrorText
                        error={p.meta.error}
                        touched={p.meta.touched}
                      />
                    </div>
                  )}
                </Field>
              </div>
              <Button
                style={{ width: '100%', borderRadius: '0px' }}
                htmlType="submit">
                Submit
              </Button>
            </form>
          )}
        </Form>
      </Stack>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createNewDoctor: createDoctorAccountFlow,
    },
    dispatch,
  )

export default connect(null, mapDispatchToProps)(CreateNewDoctor)
