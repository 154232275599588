import { LIGHT_GREY,PALE_GREY } from 'common/colors'
import styled from 'styled-components'

export const TableWrapper = styled.div`
  .ant-table-thead > tr > th,
  tr.ant-table-expanded-row {
    background: #fff;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 2px solid #f3f4fb;
  }
`

export const ExpandedTableWrapper = styled.div`
  .ant-table-thead > tr > th {
    border-bottom: none !important;
    padding: 5px 10px !important;
    background-color: ${PALE_GREY};
    color: ${LIGHT_GREY};
    font-size: 10px;
    font-weight: 600;
    border-radius: 3px;
  }
  .ant-table-tbody > tr > td {
    border-bottom: none;
    padding: 5px 10px;
  }
`
