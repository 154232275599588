import { RootState } from 'store/rootReducer'

export const getVideoIdFromProps = (_: RootState, p: { videoId: string }) =>
  p.videoId

export const getUserIdFromProps = (_: RootState, p: { userId: string }) =>
  p.userId

export const getLBProjectIdFromProps = (
  _: RootState,
  p: { projectId: string },
) => p.projectId

export const getLBDatasetIdFromProps = (
  _: RootState,
  p: { datasetId: string },
) => p.datasetId

export const getModelIdFromProps = (_: RootState, p: { modelId: string }) =>
  p.modelId

export const getVersionIdFromProps = (_: RootState, p: { versionId: string }) =>
  p.versionId

export const getPageNumberFromProps = (_: RootState, p: { page: number }) =>
  p.page
