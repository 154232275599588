/**
 * @desc: Commented out 'region and procedure' related removing functions
 * @desc: as it's currently designed in a way that tags and procedures
 * @desc: cant be removed
 */

import EntryType, { PropertyType } from 'common/entryType'
import _ from 'lodash'
import { getVideoIdFromProps } from 'main/propSelector'
import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'
import { getAllUsers } from 'store/authentication/selectors'
import { RootState } from 'store/rootReducer'

const historySelector = (s: RootState) => s.history

export const getAllEntries = createSelector(historySelector, s => s.entries)

export const getEntriesByVideoId = createCachedSelector(
  [getAllEntries, getVideoIdFromProps],
  (entries, videoId) => _.filter(entries, entry => entry.video_id === videoId),
)((_, p) => p.videoId)

export const matchVideoEntriesWithUserName = createSelector(
  [getEntriesByVideoId, getAllUsers],
  (entries, users) =>
    _.reduce(
      entries,
      (result, value, key) => {
        result[key] = {
          ...value,
          fullname: users[value.user_id].fullname,
        }
        return result
      },
      {},
    ),
)

export const getHistoryActionFromTagChange = (type: EntryType, tag: string) => {
  if (type === EntryType.ADD_TAG) {
    return `added tag: ${tag}`
  }
  if (type === EntryType.REMOVE_TAG) {
    return `removed tag: ${tag}`
  }
  return 'error entry'
}

export const getHistoryTypeFromRegion = (property: PropertyType) => {
  // if (!_.isEmpty(newValue)) {
  //   if (property === PropertyType.REGION) {
  //     return EntryType.REMOVE_REGION
  //   }
  //   if (property === PropertyType.PROCEDURE) {
  //     return EntryType.REMOVE_PROCEDURE
  //   }
  // }
  if (property === PropertyType.REGION) {
    return EntryType.ADD_REGION
  }
  // if (property === PropertyType.PROCEDURE) {
  //   return EntryType.ADD_PROCEDURE
  // }

  return EntryType.ERROR
}

export const getHistoryActionFromRegion = (
  type: EntryType,
  oldValue: string,
  newValue: string,
) => {
  if (type === EntryType.ADD_REGION) {
    return `changed region: ${newValue}`
  }
  if (type === EntryType.ADD_PROCEDURE) {
    return `changed procedure: ${newValue}`
  }
  // if (type === EntryType.REMOVE_REGION) {
  //   return `removed region: ${oldValue}`
  // }
  // if (type === EntryType.REMOVE_PROCEDURE) {
  //   return `removed procedure: ${oldValue}`
  // }
  return `error entry`
}
