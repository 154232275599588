import './QA.css'

import { Button, Table } from 'antd'
import { Input } from 'antd'
import { DARK_BLUE } from 'common/colors'
import { ExportLog } from 'common/models'
import Stack from 'components/Stack'
import { Text } from 'components/Typography'
import { values } from 'lodash'
import React, { FC } from 'react'
import { CSVLink } from 'react-csv'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { fetchExportLogsFlow } from 'store/exportLogs/actions'
import { getExportLogs } from 'store/exportLogs/selectors'
import { RootState } from 'store/rootReducer'

type Props = OwnProps &
  StoreProps<typeof mapStateToProps, typeof mapDispatchToProps>

interface OwnProps {
  exportLogs?: Dict<ExportLog>
}

const { Search } = Input

const QA: FC<Props> = ({ fetchExportLogs, exportLogs }) => {
  const loadLogs = () => {
    fetchExportLogs()
  }

  React.useEffect(loadLogs, [fetchExportLogs])

  // filter for only video metadata because image metadata also exists in export_logs metadata
  const filteredVideoNameKeys = Object.keys(exportLogs).filter(key => {
    return 'videoName' in exportLogs[key]
  })

  const filteredVideos = filteredVideoNameKeys.reduce((obj, key) => {
    obj[key] = exportLogs[key]
    return obj
  }, {})

  const exportLogsArray = Object.values(filteredVideos)

  function convertListToNestedObject(list: any) {
    const nestedObject: any = {}

    for (const item of list) {
      // eslint-disable-next-line no-prototype-builtins
      if (!nestedObject.hasOwnProperty(item.datasetName)) {
        nestedObject[item.datasetName] = {
          datasetName: item.datasetName,
          videos: [],
        }
      }
      nestedObject[item.datasetName].videos.push(item)
    }

    return nestedObject
  }

  const nestedExportLogs = convertListToNestedObject(exportLogsArray)

  const exportClasses: any[] = []
  const exportFrameCount: any[] = []
  const annotators: any[] = []
  const exportCorrectedClasses: any[] = []
  const exportCorrectedFrameCount: any[] = []

  const transformedClassesChartData: any[] = []
  const transformedCorrectedClassesChartData: any[] = []

  exportLogsArray.forEach((object: any) => exportClasses.push(object.classes))

  exportLogsArray.forEach((object: any) =>
    exportFrameCount.push(object.annotatedFrameCount),
  )

  exportLogsArray.forEach((object: any) =>
    annotators.push(object.annotatorEmail),
  )

  exportLogsArray.forEach((object: any) =>
    exportCorrectedClasses.push(object.correctedClass),
  )

  exportLogsArray.forEach((object: any) =>
    exportCorrectedFrameCount.push(object.correctedClassFrameCount),
  )

  interface classesChartData {
    className: string
    frameCount: string
    annotatorEmail: string
  }

  interface correctedClassesChartData {
    correctedClassName: string
    correctedFrameCount: string
    correctedAnnotatorEmail: string
  }

  exportClasses.forEach((classAnnotation, index) => {
    const frameCount = exportFrameCount[index]
    const annotatorEmail = annotators[index]
    const classChart: classesChartData = {
      className: classAnnotation,
      frameCount: frameCount,
      annotatorEmail: annotatorEmail,
    }
    classChart.className = classAnnotation
    classChart.frameCount = frameCount
    classChart.annotatorEmail = annotatorEmail
    transformedClassesChartData.push(classChart)
  })

  exportCorrectedClasses.forEach((correctedClassAnnotation, index) => {
    const correctedFrameCount = exportCorrectedFrameCount[index] ?? 0
    const correctedAnnotatorEmail =
      annotators[index] ?? 'Unknown annotator email'
    if (correctedClassAnnotation) {
      const correctedClassChart: correctedClassesChartData = {
        correctedClassName: correctedClassAnnotation,
        correctedFrameCount: correctedFrameCount,
        correctedAnnotatorEmail: correctedAnnotatorEmail,
      }
      transformedCorrectedClassesChartData.push(correctedClassChart)
    }
  })

  // Add annotator to each unique class in each video object
  const transformedChartWithAnnotator: any[] = []
  const transformedCorrectedChartWithAnnotator: any[] = []

  for (let i = 0; i < transformedClassesChartData.length; i++) {
    const classAnnotation = transformedClassesChartData[i].className
    const flatFrameCount = transformedClassesChartData[i].frameCount
    const annotatorEmail = transformedClassesChartData[i].annotatorEmail

    if (!Array.isArray(classAnnotation)) {
      continue
    }

    classAnnotation.forEach(
      (classAnnotation: string, index: string | number) => {
        const frameCount = flatFrameCount[index]

        const classChart: classesChartData = {
          className: classAnnotation,
          frameCount: frameCount,
          annotatorEmail: annotatorEmail,
        }
        classChart.className = classAnnotation
        classChart.frameCount = frameCount
        classChart.annotatorEmail = annotatorEmail
        transformedChartWithAnnotator.push(classChart)
      },
    )
  }

  for (let i = 0; i < transformedCorrectedClassesChartData.length; i++) {
    const correctedClassAnnotation =
      transformedCorrectedClassesChartData[i].correctedClassName
    const correctedFlatFrameCount =
      transformedCorrectedClassesChartData[i].correctedFrameCount
    const correctedAnnotatorEmail =
      transformedCorrectedClassesChartData[i].correctedAnnotatorEmail

    correctedClassAnnotation.forEach(
      (correctedClassAnnotation: string, index: string | number) => {
        const correctedFrameCount = correctedFlatFrameCount[index]

        const correctedClassChart: correctedClassesChartData = {
          correctedClassName: correctedClassAnnotation,
          correctedFrameCount: correctedFrameCount,
          correctedAnnotatorEmail: correctedAnnotatorEmail,
        }
        correctedClassChart.correctedClassName = correctedClassAnnotation
        correctedClassChart.correctedFrameCount = correctedFrameCount
        correctedClassChart.correctedAnnotatorEmail = correctedAnnotatorEmail
        transformedCorrectedChartWithAnnotator.push(correctedClassChart)
      },
    )
  }

  // Group table entries with similar className and annotatorEmail together
  const helper: any = {}
  const classesData = transformedChartWithAnnotator.reduce(function (
    resultArray,
    element,
  ) {
    const key = element.className + '-' + element.annotatorEmail

    if (!helper[key]) {
      helper[key] = Object.assign({}, element)
      resultArray.push(helper[key])
    } else {
      helper[key].frameCount += element.frameCount
    }

    return resultArray
  },
  [])

  const correctedClassesData = transformedCorrectedChartWithAnnotator.reduce(
    function (resultArray, element) {
      const key =
        element.correctedClassName + '-' + element.correctedAnnotatorEmail

      if (!helper[key]) {
        helper[key] = Object.assign({}, element)
        resultArray.push(helper[key])
      } else {
        helper[key].correctedFrameCount += element.correctedFrameCount
      }

      return resultArray
    },
    [],
  )

  function addSpaceToElements(params: any) {
    return `${params.join(', ')}`
  }

  // Sum frame counts for each annotator
  function sumFrameCounts(params: any) {
    const arrayToSum = params.join(',')
    const array = JSON.parse('[' + arrayToSum + ']')
    const sum = array.reduce(function (a: any, b: any) {
      return a + b
    }, 0)

    return `${sum}`
  }

  const getOuterColumns = () => {
    const columns = [
      {
        title: 'Dataset Name',
        dataIndex: 'datasetName',
        key: 'datasetName',
        width: '50%',
        sorter: (a: any, b: any) =>
          a.datasetName.localeCompare(
            b.datasetName,
            navigator.languages[0] || navigator.language,
            {
              numeric: true,
              ignorePunctuation: true,
              sensitivity: 'base',
            },
          ),
      },
      {
        title: 'Total Annotated Frames For This Dataset',
        dataIndex: 'totalAnnotatedFrames',
        key: 'totalAnnotatedFrames',
        width: '50%',
        sorter: (a: any, b: any) =>
          a.totalAnnotatedFrames - b.totalAnnotatedFrames,
      },
    ]

    return columns
  }

  const outerColumn = getOuterColumns()

  function extractSumOfArrayInAnnotatedFrameCount(input: {
    [x: string]: { videos: any }
  }) {
    const sumOfArrayInAnnotatedFrameCount = []

    for (const folder in input) {
      const folderVideos = input[folder].videos
      let folderSum = 0

      for (let i = 0; i < folderVideos.length; i++) {
        const video = folderVideos[i]

        if (Array.isArray(video.annotatedFrameCount)) {
          folderSum += video.annotatedFrameCount.reduce(
            (acc: any, val: any) => acc + val,
            0,
          )
        } else {
          folderSum += 0
        }
      }

      sumOfArrayInAnnotatedFrameCount.push(folderSum)
    }

    return sumOfArrayInAnnotatedFrameCount
  }

  const sumArray = extractSumOfArrayInAnnotatedFrameCount(nestedExportLogs)

  function addTotalAnnotatedFrames(
    nestedExportLogs: { [x: string]: any },
    sumArray: any[],
  ) {
    let index = 0
    const exportLogsData: any = {}

    for (const folder in nestedExportLogs) {
      const folderSum = sumArray[index]

      exportLogsData[folder] = {
        ...nestedExportLogs[folder],
        totalAnnotatedFrames: folderSum,
      }

      index++
    }

    return exportLogsData
  }

  const exportLogsData = addTotalAnnotatedFrames(nestedExportLogs, sumArray)

  const getInnerColumns = () => {
    const columns = [
      {
        title: 'Video Name',
        dataIndex: 'videoName',
        key: 'videoName',
        width: '25%',
      },
      {
        title: 'Annotator email',
        dataIndex: 'annotatorEmail',
        key: 'annotatorEmail',
        width: '25%',
      },
      {
        title: 'Reviewers',
        dataIndex: 'reviewersName',
        key: 'reviewersName',
        width: '25%',
        render: (reviewers: any) => <div>{addSpaceToElements(reviewers)}</div>,
      },

      {
        title: 'Total Annotated Frames',
        dataIndex: 'annotatedFrameCount',
        key: 'annotatedFrameCount',
        width: '25%',
        render: (annotatedFrames: any) => (
          <div>{sumFrameCounts(annotatedFrames)}</div>
        ),
      },
    ]
    return columns
  }

  const expandedRow = (row: any) => {
    const columns = getInnerColumns()

    let nestedData: any[] = []
    const currentBatch = values(nestedExportLogs)
    for (let i = 0; i < currentBatch.length; i++) {
      if (row.datasetName === currentBatch[i].datasetName) {
        nestedData = currentBatch[i].videos
        break
      }
    }

    nestedData.sort((a, b) =>
      a.videoName.localeCompare(
        b.videoName,
        navigator.languages[0] || navigator.language,
        {
          numeric: true,
          ignorePunctuation: true,
          sensitivity: 'base',
        },
      ),
    )

    return (
      <Table
        columns={columns}
        dataSource={nestedData}
        pagination={{
          defaultPageSize: 5,
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20'],
        }}
        rowKey={(batch: any) => batch._id}
      />
    )
  }

  const columnsForClassesChart = [
    {
      title: 'Class',
      dataIndex: 'className',
      key: 'className',
      sorter: (a: any, b: any) =>
        a.className.localeCompare(
          b.className,
          navigator.languages[0] || navigator.language,
          {
            numeric: true,
            ignorePunctuation: true,
            sensitivity: 'base',
          },
        ),
    },
    {
      title: 'Annotator email',
      dataIndex: 'annotatorEmail',
      key: 'annotatorEmail',
      sorter: (a: any, b: any) =>
        a.annotatorEmail.localeCompare(
          b.annotatorEmail,
          navigator.languages[0] || navigator.language,
          {
            numeric: true,
            ignorePunctuation: true,
            sensitivity: 'base',
          },
        ),
    },
    {
      title: 'Total Annotated Frames',
      dataIndex: 'frameCount',
      key: 'frameCount',
      sorter: (a: any, b: any) => a.frameCount - b.frameCount,
    },
  ]

  const columnsForCorrectedClassesChart = [
    {
      title: 'Corrected class',
      dataIndex: 'correctedClassName',
      key: 'correctedClassName',
      sorter: (a: any, b: any) =>
        a.correctedClassName.localeCompare(
          b.correctedClassName,
          navigator.languages[0] || navigator.language,
          {
            numeric: true,
            ignorePunctuation: true,
            sensitivity: 'base',
          },
        ),
    },
    {
      title: 'Annotator email',
      dataIndex: 'correctedAnnotatorEmail',
      key: 'correctedAnnotatorEmail',
      sorter: (a: any, b: any) =>
        a.annotatorEmail.localeCompare(
          b.annotatorEmail,
          navigator.languages[0] || navigator.language,
          {
            numeric: true,
            ignorePunctuation: true,
            sensitivity: 'base',
          },
        ),
    },
    {
      title: 'Total corrected frames',
      dataIndex: 'correctedFrameCount',
      key: 'correctedFrameCount',
      sorter: (a: any, b: any) => a.correctedFrameCount - b.correctedFrameCount,
    },
  ]

  // For QA Logs search
  const [, setSearchClassName] = React.useState('')
  const [filteredClasses, setFilteredClasses] = React.useState<any>(
    values(classesData),
  )

  React.useEffect(() => {
    if (classesData && filteredClasses.length === 0 && classesData.length > 0) {
      setFilteredClasses(values(classesData))
    }
  }, [classesData, filteredClasses])
  const setClassNameSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    setSearchClassName(value)
    const filteredData = values(classesData).filter(record =>
      record.className.toLowerCase().includes(value.toLowerCase()),
    )
    setFilteredClasses(filteredData)
  }

  // For Corrected Classes Logs search
  const [, setSearchCorrectedClassName] = React.useState('')
  const [filteredCorrectedClasses, setFilteredCorrectedClasses] =
    React.useState<any>(values(correctedClassesData))

  React.useEffect(() => {
    if (
      correctedClassesData &&
      filteredCorrectedClasses.length === 0 &&
      correctedClassesData.length > 0
    ) {
      setFilteredCorrectedClasses(values(correctedClassesData))
    }
  }, [correctedClassesData, filteredCorrectedClasses])

  const setCorrectedClassNameSearch = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value

    setSearchCorrectedClassName(value)
    const filteredData = values(correctedClassesData).filter(record =>
      record.correctedClassName.toLowerCase().includes(value.toLowerCase()),
    )
    setFilteredCorrectedClasses(filteredData)
  }

  const csvData = [
    [
      'Dataset Name',
      'Video Name',
      'Annotator Email',
      'Reviewer Names',
      'Total Annotated Frame Count',
    ],
    ...Object.values(
      nestedExportLogs as {
        [key: string]: { datasetName: any; videos: any[] }
      },
    ).flatMap(({ datasetName, videos }) =>
      videos.map(
        ({ videoName, annotatorEmail, reviewersName, annotatedFrameCount }) => {
          const dataset = datasetName || ''
          const video = videoName || ''
          const annotator = annotatorEmail || ''
          const reviewers = Array.isArray(reviewersName)
            ? reviewersName.join(', ')
            : ''
          const count = Array.isArray(annotatedFrameCount)
            ? annotatedFrameCount.reduce((total, count) => total + count, 0)
            : 0

          return [dataset, video, annotator, reviewers, count]
        },
      ),
    ),
  ]

  return (
    <div style={{ paddingBottom: '30px' }}>
      <Stack>
        <h1 style={{ paddingRight: '1rem' }}>Authorship Logs</h1>
        <Button>
          <CSVLink filename={'authorship-logs.csv'} data={csvData}>
            Download as CSV
          </CSVLink>
        </Button>
      </Stack>

      <Table
        columns={outerColumn}
        expandable={{
          expandedRowRender: expandedRow,
        }}
        dataSource={values(exportLogsData).filter(
          (log: any) => log.datasetName,
        )}
        rowKey={(batch: any) => batch.datasetName}
        pagination={{
          defaultPageSize: 5,
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20'],
        }}
      />
      <Stack vertical>
        <Text color={DARK_BLUE} fontWeight={600} fontSize={14}>
          Search by class name
        </Text>
        <div style={{ width: '30%', paddingBottom: '30px' }}>
          <Search
            placeholder={'Enter class name..'}
            enterButton={true}
            onChange={setClassNameSearch}
            size="large"
            allowClear={true}
          />
        </div>
      </Stack>
      <h1>QA Logs</h1>
      <Table
        columns={columnsForClassesChart}
        dataSource={filteredClasses}
        rowKey={(row: any) =>
          row.className + row.annotatorEmail + row.frameCount
        }
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20'],
        }}
      />
      <Stack vertical>
        <Text color={DARK_BLUE} fontWeight={600} fontSize={14}>
          Search by corrected class name
        </Text>
        <div style={{ width: '30%', paddingBottom: '30px' }}>
          <Search
            placeholder={'Enter corrected class name..'}
            enterButton={true}
            onChange={setCorrectedClassNameSearch}
            size="large"
            allowClear={true}
          />
        </div>
      </Stack>
      <h1>Corrected Classes Logs</h1>
      <Table
        columns={columnsForCorrectedClassesChart}
        dataSource={filteredCorrectedClasses}
        rowKey={(row: any) =>
          row.correctedClassName +
          row.correctedAnnotatorEmail +
          row.correctedFrameCount
        }
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20'],
        }}
      />
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  exportLogs: getExportLogs(state),
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchExportLogs: fetchExportLogsFlow,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(QA)
