import { Icon } from '@blueprintjs/core'
import { Popover, Select, Tag } from 'antd'
import { Spacing } from 'common/stylings'
import { confirm } from 'components/Modal'
import Stack from 'components/Stack'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { getUserIdOfCurrUser } from 'store/authentication/selectors'
import { RootState } from 'store/rootReducer'
import { editVideoTagFlowIndiv } from 'store/videoSegments/actions'
import {
  getAllClassificationTags,
  getVideoAssetsDateRange,
} from 'store/videoSegments/selectors'

import { VideoSegment } from '../../../common/models'

const { Option } = Select

type Props = StoreProps<typeof mapStateToProps, typeof mapDispatchToProps> &
  OwnProps

interface OwnProps {
  videos?: VideoSegment[]
  classificationTags?: string[]
}

const ClassficationTagColumn: React.FC<Props> = ({
  editVideoTag,
  currUserId,
  dateRange,
  videos = [],
  classificationTags = [],
  allClassificationTags = [],
}) => {
  const [tagToRemove, setTagToRemove] = React.useState<string | null>(null)
  const [isWarningModalOpen, toggleWarningModalVisible] = React.useState(false)

  // Helper function to ensure classificationTags is always an array
  const getValidTags = (classificationTags: string[] | undefined) => {
    return Array.isArray(classificationTags) ? classificationTags : []
  }

  const addClassificationTag = (newClassificationTag: string) => {
    const validClassificationTags = getValidTags(classificationTags)

    if (
      !_.isEmpty(newClassificationTag) &&
      !validClassificationTags.includes(newClassificationTag)
    ) {
      for (const id of extractVideoIds(videos)) {
        editVideoTag(
          {
            videoId: id,
            classificationTags: [
              ...validClassificationTags,
              newClassificationTag,
            ],
            batch: videos[0].batch,
          },
          currUserId,
          dateRange,
        )
      }
    }
  }

  const extractVideoIds = (videos: VideoSegment[]) => {
    const videoIds: string[] = []
    for (const video in videos) {
      videoIds.push(videos[video]._id)
    }
    return videoIds
  }

  const removeTag = () => {
    const validClassificationTags = getValidTags(classificationTags)

    for (const id of extractVideoIds(videos)) {
      const newClassificationTags = _.filter(
        validClassificationTags,
        classificationTag => classificationTag !== tagToRemove,
      )
      editVideoTag(
        {
          videoId: id,
          classificationTags: newClassificationTags,
          batch: videos[0].batch,
        },
        currUserId,
        dateRange,
      )
      setTagToRemove(null)
    }
  }

  const displayWarningModal = () => {
    confirm({
      title: 'Do you want to delete the tag?',
      content: "You can't recover the tag after deleting it.",
      icon: <Icon icon="warning-sign" intent="warning" />,
      onOk: () => {
        removeTag()
        toggleWarningModalVisible(false)
      },
      onCancel: () => toggleWarningModalVisible(false),
    })
  }

  const renderHoverPopoverContent = (classificationTags: string[]) => {
    return (
      <div>
        {classificationTags.map((classificationTag: string) => (
          <Tag key={classificationTag}>{classificationTag}</Tag>
        ))}
      </div>
    )
  }

  const renderTagsOverview = () => {
    const validClassificationTags = getValidTags(classificationTags)
    const sortedClassificationTags = validClassificationTags.sort(
      Intl.Collator().compare,
    )
    const size = sortedClassificationTags ? sortedClassificationTags.length : 0
    const extraTags = sortedClassificationTags.slice(3)

    if (size) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              maxWidth: '165px',
            }}>
            {validClassificationTags.map(
              (classificationTag, index) =>
                index < 3 && (
                  <div key={index}>
                    <Tag
                      style={{
                        whiteSpace: 'normal',
                        maxWidth: '165px',
                      }}>
                      {classificationTag}
                    </Tag>
                  </div>
                ),
            )}
          </div>
          {size > 3 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flexShrink: 0,
                marginRight: '4px',
              }}>
              <Popover
                overlayStyle={{
                  width: 'max-content',
                }}
                content={renderHoverPopoverContent(extraTags)}>
                <Tag>+ {size - 3}</Tag>
              </Popover>
            </div>
          ) : null}
        </div>
      )
    }
    return null
  }

  const renderPopoverContent = () => {
    const validClassificationTags = getValidTags(classificationTags)
    return (
      <>
        <Stack
          gutter={Spacing.REGULAR}
          vertical
          style={{ minWidth: 150, maxWidth: 150, position: 'relative' }}>
          <Select
            value={validClassificationTags}
            mode="tags"
            autoFocus
            onSelect={(e: string) => addClassificationTag(e)}
            onDeselect={(e: string) => {
              setTagToRemove(e)
              toggleWarningModalVisible(true)
            }}>
            {allClassificationTags.map((classificationTag: string) => (
              <Option key={classificationTag} value={classificationTag}>
                {classificationTag}
              </Option>
            ))}
          </Select>
        </Stack>
        {isWarningModalOpen && displayWarningModal()}
      </>
    )
  }

  return (
    <Stack justifyContent={'flex-start'} gutter={Spacing.SMALL}>
      {renderTagsOverview()}
      <Popover content={renderPopoverContent()} trigger="click">
        <Icon
          style={{
            cursor: 'pointer',
            position: 'absolute',
            right: 0,
            alignSelf: 'center',
          }}
          icon="edit"
        />
      </Popover>
    </Stack>
  )
}

const mapStateToProps = (s: RootState) => ({
  currUserId: getUserIdOfCurrUser(s),
  dateRange: getVideoAssetsDateRange(s),
  allClassificationTags: getAllClassificationTags(s),
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      editVideoTag: editVideoTagFlowIndiv,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClassficationTagColumn)
