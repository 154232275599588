import './KLCSVViewer.css'

import { CopyFilled, CopyTwoTone } from '@ant-design/icons'
import { Intent } from '@blueprintjs/core'
import { Button, Spin, Table, Tooltip } from 'antd'
import { Colors } from 'common/colors'
import { Spacing } from 'common/stylings'
import Stack from 'components/Stack'
import Toaster from 'components/Toaster'
import Text from 'components/Typography'
import Papa from 'papaparse'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { RootState } from 'store/rootReducer'
import { editVideoCountFlow } from 'store/videoSegments/actions'

type Props = KLCSVViewerProps &
  StoreProps<typeof mapStateToProps, typeof mapDispatchToProps>

interface KLCSVViewerProps {
  url: string
  csvName: string
  video: any
}

interface CsvData {
  [key: string]: any
}

const KLCSSViewer: React.FC<Props> = ({
  url,
  csvName,
  video,
  editVideoCount,
}) => {
  const [data, setData] = useState<CsvData[]>([])
  const [columns, setColumns] = useState<
    Array<{
      title: string
      dataIndex: string
      key: string
      render?: (text: any) => JSX.Element
    }>
  >([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [clickCopy, setClickCopy] = useState(false)
  const [isDownloaded, setIsDownloaded] = useState(false)

  useEffect(() => {
    const fetchCSVData = async () => {
      try {
        setIsLoading(true)
        const response = await fetch(url)
        const csvData = await response.text()

        Papa.parse(csvData, {
          header: true,
          dynamicTyping: true,
          complete: (result: any) => {
            const parsedData: CsvData[] = result.data
            setData(parsedData)
            if (parsedData.length > 0) {
              const keys = Object.keys(parsedData[0])
              const cols = keys.map(key => ({
                title: key,
                dataIndex: key,
                key: key,
                render: (text: any) => {
                  let displayText = text
                  if (text instanceof Date) {
                    displayText = text.toLocaleDateString()
                  } else if (typeof text === 'object' && text !== null) {
                    displayText = JSON.stringify(text)
                  } else if (text === null || text === undefined) {
                    displayText = ''
                  } else {
                    displayText = String(text)
                  }

                  const tooltipTitle = displayText ? (
                    <div
                      style={{
                        maxHeight: '400px',
                        overflowY: 'auto',
                      }}>
                      {displayText}
                    </div>
                  ) : null

                  return (
                    <Tooltip
                      title={tooltipTitle}
                      overlayStyle={{
                        width: 'auto',
                        maxWidth: '400px',
                      }}>
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '140px',
                          display: 'inline-block',
                        }}>
                        {displayText}
                      </span>
                    </Tooltip>
                  )
                },
              }))
              setColumns(cols)
            }
            setIsLoading(false)
          },
        })
      } catch (error) {
        console.error('Error fetching the CSV file:', error)
        setIsLoading(false)
      }
    }

    fetchCSVData()
  }, [url])

  const handleDownload = () => {
    if (!isDownloaded) {
      editVideoCount({
        videoId: video._id,
        download_count: video.download_count + 1,
        batch: video.batch,
      })
      setIsDownloaded(true)
    }
  }

  const renderDownloadButton = () => {
    return (
      <Stack justifyContent="flex-start">
        <a
          href={url}
          download
          style={{ textDecoration: 'none' }}
          onClick={handleDownload}>
          <Button
            disabled={isDownloaded}
            type="primary"
            style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
            Download CSV
          </Button>
        </a>
      </Stack>
    )
  }

  const renderCopyButton = () => {
    const toggleAfterCopy = () => {
      setClickCopy(true)
      navigator.clipboard.writeText(video.uri)
      Toaster.show({
        icon: 'tick',
        intent: Intent.SUCCESS,
        message: 'Copied to Clipboard!',
      })
    }
    return !clickCopy ? (
      <CopyTwoTone
        style={{ marginLeft: '5px' }}
        onClick={() => toggleAfterCopy()}></CopyTwoTone>
    ) : (
      <CopyFilled style={{ marginLeft: '5px' }}> </CopyFilled>
    )
  }

  return (
    <Stack vertical gutter={Spacing.MEDIUM}>
      <div className="csv-viewer">
        <Stack vertical>
          <Text
            style={{
              fontSize: '18px',
            }}
            fontSize={16}
            fontWeight="bold">
            {csvName}
          </Text>
          <Text color={Colors.PALE_GREY} fontSize={12}>
            {video.uri}
            {renderCopyButton()}
            {renderDownloadButton()}
          </Text>
          {isLoading ? (
            <div className="csv-viewer-wrapper">
              <Spin tip="Loading CSV data..." />
            </div>
          ) : (
            <div>
              <Table
                className="csv-viewer"
                dataSource={data}
                columns={columns}
                rowKey={(record: any, index: any) =>
                  record.id || index.toString()
                }
              />
            </div>
          )}
        </Stack>
      </div>
    </Stack>
  )
}

const mapStateToProps = (state: RootState) => ({})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      editVideoCount: editVideoCountFlow,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(KLCSSViewer)
