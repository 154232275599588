import { Model, ModelVersion } from 'common/models'
import { createAsyncAction } from 'typesafe-actions'

export const retrieveAllModels = createAsyncAction(
  'Retrieve_All_Models__Request',
  'Retrieve_All_Models__Success',
  'Retrieve_All_Models__Failure',
)<void, { models: Model[] }, Error>()

export const createNewModel = createAsyncAction(
  'Create_Model__Request',
  'Create_Model__Success',
  'Create_Model__Failure',
)<{ name: string }, { model: Model }, Error>()

export const retrieveAllModelVersions = createAsyncAction(
  'Retrieve_All_ModelVersions__Request',
  'Retrieve_All_ModelVersions__Success',
  'Retrieve_All_ModelVersions__Failure',
)<{ params: Partial<ModelVersion> }, { versions: ModelVersion[] }, Error>()

export const createNewModelVersion = createAsyncAction(
  'Create_Model_Version__Request',
  'Create_Model_Version__Success',
  'Create_Model_Version__Failure',
)<void, { version: ModelVersion }, Error>()

export const retrieveModelVersion = createAsyncAction(
  'Retrieve_Model_Version__Request',
  'Retrieve_Model_Version__Success',
  'Retrieve_Model_Version__Failure',
)<{ versionId: string }, { version: ModelVersion }, Error>()

export const updateModelVersion = createAsyncAction(
  'Update_Model_Version__Request',
  'Update_Model_Version__Success',
  'Update_Model_Version__Failure',
)<
  Partial<ModelVersion>,
  { payload: Partial<ModelVersion>; versionId: string },
  Error
>()

export const setLatestVersion = createAsyncAction(
  'Set_Latest_Version__Request',
  'Set_Latest_Version__Success',
  'Set_Latest_Version__Failure',
)<{ versionId: string }, { versionId: string }, Error>()
