import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { Icon } from '@blueprintjs/core'
import { Popover } from 'antd'
import { Colors, LIGHT_GREY } from 'common/colors'
import { PropertyType } from 'common/entryType'
import { Doctor, FilterFields, VideoBatch, VideoSegment } from 'common/models'
import { Spacing } from 'common/stylings'
import Stack from 'components/Stack'
import { Text } from 'components/Typography'
import { get, uniq } from 'lodash'
import { mapSurgeonIDtoEmail } from 'main/Analytics/helper'
import React, { ReactText } from 'react'
import { getLongDateString } from 'utilities/datetime'

import BatchClassificationTagColumn from './BatchClassificationTagColumn'
import ClassificationTagColumn from './ClassificationTagColumn'
import EditableColumn from './EditableColumn'
import IndividualTagColumn from './IndividualTagColumn'
import PlayColumn from './PlayColumn'
import TagColumn from './TagColumn'

export let customFilters: Object

export const getTitle = (title: string, withIcon?: boolean) => {
  if (withIcon) {
    return (
      <Stack center gutter={Spacing.EXTRA_SMALL}>
        <Text color={Colors.DARK} fontWeight="bold">
          {title}
        </Text>
        <Popover content="This field is editable">
          <Icon icon="info-sign" style={{ color: LIGHT_GREY }} />
        </Popover>
      </Stack>
    )
  }
  return (
    <Text color={Colors.DARK} fontWeight="bold">
      {title}
    </Text>
  )
}

// const compare = (dataIndex: string, a: VideoSegment, b: VideoSegment) => {
//   if (a[dataIndex] && b[dataIndex]) {
//     return a[dataIndex].length - b[dataIndex].length
//   }
//   if (a[dataIndex]) {
//     return 1
//   }
//   if (b[dataIndex]) {
//     return -1
//   }
//   return 0
// }

export const getDistinctDoctorNames = (
  doctorIds: string[] | undefined,
  allDoctors: Dict<Doctor> | undefined,
): string[] => {
  if (!doctorIds || !allDoctors) {
    return []
  }
  const names = doctorIds.map(id => get(allDoctors[id], ['fullname'], ''))
  return uniq(names)
}

/* HACK
 * Ant table is not too flexible,
 * so I'm unable to use hooks to update the state of the selected keys
 * The `onFilter` function is unable to do exclusive filter involving `~a and ~b`
 */
/**
 * Commenting this method out because it is not in use + it is giving a type error
 */
// export const getTagFilterProps = (
//   dataIndex: string,
//   filterFields: FilterFields,
//   isIncludedSearch: boolean,
//   toggleSearchPattern: (isInclusive: boolean) => void,
//   tagFilterSelectedKeys: string[],
//   setTagFilter: React.Dispatch<React.SetStateAction<string[]>>,
//   filters: Record<string, ReactText[] | null> | null,
// ): ColumnProps<VideoSegment> => ({
//   filteredValue: get(filters, 'tags', null),
//   filterDropdown: ({ setSelectedKeys, confirm }) => {
//     const onChange = (checkedValues: CheckboxValueType[]) => {
//       const values = checkedValues.map(val => val.toString())
//       setSelectedKeys!(values)
//       setTagFilter(values)
//     }
//
//     return (
//       <Stack
//         vertical
//         style={{ padding: 12, maxHeight: 300 }}
//         gutter={Spacing.SMALL}>
//         <Radio.Group
//           onChange={e => toggleSearchPattern(!!e.target.value)}
//           value={isIncludedSearch}>
//           <Radio value={true}>Include</Radio>
//           {/* <Radio value={false}>Exclude</Radio> */}
//         </Radio.Group>
//         <Divider style={{ margin: 2 }} />
//         <Stack vertical style={{ overflowY: 'auto' }}>
//           <Checkbox.Group onChange={onChange}>
//             <Stack vertical>
//               {filterFields.tags.filter(Boolean).map((tag, index) => (
//                 <Checkbox key={index} value={tag} style={{ marginLeft: 0 }}>
//                   {tag}
//                 </Checkbox>
//               ))}
//             </Stack>
//           </Checkbox.Group>
//         </Stack>
//         <Divider style={{ margin: 2 }} />
//         <Button onClick={confirm} size="small">
//           OK
//         </Button>
//       </Stack>
//     )
//   },
// })

export const getOuterColumns = (
  filterFields: FilterFields,
  allDoctors: Dict<Doctor>,
  videoBatches: Dict<VideoBatch>,
  isIncludedSearch: boolean,
  toggleSearchPattern: (isInclusive: boolean) => void,
  tagFilterSelectedKeys: string[] | [],
  setTagFilter: React.Dispatch<React.SetStateAction<string[]>>,
  filters: Record<string, ReactText[] | null> | null,
  isSMEVideoAssetsPage: boolean,
) => {
  const columns = [
    {
      title: 'Upload Batch',
      dataIndex: 'batchName',
      key: 'batch',
      width: '23%',
      render: (_: any, batch: { batch: React.ReactNode }) => (
        <Stack vertical>
          <Text>{batch.batch}</Text>
        </Stack>
      ),
    },
    {
      title: isSMEVideoAssetsPage ? 'Uploader' : 'Doctor',
      dataIndex: 'doctor',
      key: 'doctor',
      width: '12%',
      filteredValue: get(filters, 'doctor', null),
      filters: getDistinctDoctorNames(filterFields.doctors, allDoctors).map(
        name => ({
          text: name,
          value: name,
        }),
      ),
      // sorter: (a, b) => compare('doctor', a, b),
      render: (doctorId: string | number) => (
        <Stack vertical>
          <Text>{get(allDoctors[doctorId], 'fullname', '')}</Text>
        </Stack>
      ),
    },
    {
      title: getTitle('Date'),
      dataIndex: 'upload_date',
      key: 'date',
      width: '13%',
      render: (upload_date: string) => (
        <Text>{getLongDateString(upload_date)}</Text>
      ),
    },
    {
      title: getTitle('Region'),
      dataIndex: 'region',
      key: 'region',
      width: '13%',
      filteredValue: get(filters, 'region', null),
      filters:
        filterFields.regions &&
        filterFields.regions.filter(Boolean).map(region => ({
          text: region,
          value: region,
        })),
      render: (
        region: string | undefined,
        batch: { batch: string; videos: VideoSegment[] },
      ) => (
        <EditableColumn
          value={region}
          property={PropertyType.REGION}
          videos={batch.videos}
        />
      ),
    },
    {
      title: getTitle('Dataset Tags (Batch)'),
      dataIndex: 'tags',
      key: 'tags',
      width: '14%',
      filteredValue: get(filters, 'tags', null),
      filters:
        filterFields.tags &&
        filterFields.tags
          .filter(Boolean)
          .sort(Intl.Collator().compare)
          .map(tags => ({
            text: tags,
            value: tags,
          })),
      render: (
        tags: string[] | undefined,
        batch: { batch: string; videos: VideoSegment[] },
      ) => <TagColumn tags={tags} videos={batch.videos} />,
    },
    {
      title: getTitle('Classification Tags (Batch)'),
      dataIndex: 'classificationTags',
      key: 'classificationTags',
      width: '15%',
      filteredValue: get(filters, 'classificationTags', null),
      filters:
        filterFields.classificationTags &&
        filterFields.classificationTags
          .filter(Boolean)
          .sort(Intl.Collator().compare)
          .map(tags => ({
            text: tags,
            value: tags,
          })),
      render: (
        classificationTags: string[] | undefined,
        batch: { batch: string; videos: VideoSegment[] },
      ) => {
        return (
          <BatchClassificationTagColumn
            classificationTags={classificationTags}
            videos={batch.videos}
          />
        )
      },
    },
    {
      title: getTitle('Exclude Batch Tags'),
      dataIndex: 'excludeTags',
      key: 'excludeTags',
      width: '10%',
      filteredValue: get(filters, 'excludeTags', null),
      filters: [
        ...(filterFields.tags || []),
        ...(filterFields.classificationTags || []),
      ]
        .filter(Boolean)
        .sort(Intl.Collator().compare)
        .map(tag => ({
          text: tag,
          value: tag,
        })),
    },
  ]
  // For doctor filters
  const doctor = columns[1].filteredValue
  customFilters = { ...customFilters, doctor }
  // For region filters
  const region = columns[3].filteredValue
  customFilters = { ...customFilters, region }
  // For tag filters
  const tags = columns[4].filteredValue
  customFilters = { ...customFilters, tags }
  // For classification tags filters
  const classificationTags = columns[5].filteredValue
  customFilters = { ...customFilters, classificationTags }
  // For exclude_tag filters
  const excludeTags = columns[6].filteredValue
  customFilters = { ...customFilters, excludeTags }
  return columns
}

export const getInnerColumns = (
  filterFields: FilterFields,
  allDoctors: Dict<Doctor>,
  isIncludedSearch: boolean,
  toggleSearchPattern: (isInclusive: boolean) => void,
  tagFilterSelectedKeys: string[] | [],
  setTagFilter: React.Dispatch<React.SetStateAction<string[]>>,
  filters: Record<string, ReactText[] | null> | null,
  exportLogs: any,
  trainingLogs: any,
  isSMEVideoAssetsPage: boolean,
) => {
  const modifiedExportLogs: any[] = exportLogs
    ? Object.values(exportLogs).map((item: any) => {
        const videoName = item?.videoName || item?.imageName
        const surgeonEmail = item?.surgeonEmail
        const result = {
          videoName: videoName ? videoName.replace(/-\d+fps/g, '') : undefined,
          surgeonEmail: surgeonEmail,
        }
        return result
      })
    : []

  const modifiedTrainingLogs: any[] = trainingLogs
    ? Object.values(trainingLogs).map((item: any) => {
        const videoName = item?.video_name
          ? item.video_name.replace(/-\d+fps/g, '')
          : undefined
        const surgeonEmail = mapSurgeonIDtoEmail(parseInt(item?.surgeon_id))
        const result = {
          videoName: videoName,
          surgeonEmail: surgeonEmail,
        }
        return result
      })
    : []

  const columns = [
    {
      title: getTitle('Video File'),
      dataIndex: 'filename',
      key: 'filename',
      width: '20%',
      // sorter: (a, b) => compare('filename', a, b),
      render: (_: any, video: VideoSegment) => <PlayColumn video={video} />,
    },
    {
      title: getTitle('Downloaded from Inhance'),
      dataIndex: 'download_count',
      key: 'download_count',
      width: '14%',
      render: (_: any, video: VideoSegment) =>
        video.download_count > 0 ? (
          <CheckCircleTwoTone twoToneColor="#52C41A" />
        ) : (
          <CloseCircleTwoTone twoToneColor="#C41E3A" />
        ),
    },
    {
      title: getTitle('Exported to SA'),
      dataIndex: 'export_count',
      key: 'export_count',
      width: '14%',
      render: (_: any, video: VideoSegment) =>
        video.export_count > 0 ? (
          <CheckCircleTwoTone twoToneColor="#52C41A" />
        ) : (
          <CloseCircleTwoTone twoToneColor="#C41E3A" />
        ),
    },
    {
      title: getTitle('Labeled in SA'),
      width: '14%',
      render: (_: any, video: VideoSegment) => {
        const filename = video?.filename
        const surgeonEmail = get(allDoctors[video?.doctor], 'email', '')

        const hasSimilarObject = modifiedExportLogs
          ? modifiedExportLogs.some(videoSegment => {
              return (
                videoSegment.videoName === filename &&
                videoSegment.surgeonEmail === surgeonEmail
              )
            })
          : false

        return hasSimilarObject ? (
          <CheckCircleTwoTone twoToneColor="#52C41A" />
        ) : (
          <CloseCircleTwoTone twoToneColor="#C41E3A" />
        )
      },
    },
    {
      title: getTitle('Used for training'),
      // dataIndex: 'export_count',
      // key: 'export_count',
      width: '14%',
      render: (_: any, video: VideoSegment) => {
        const filename = video?.filename
        const surgeonEmail = get(allDoctors[video?.doctor], 'email', '')

        const hasSimilarObject = modifiedTrainingLogs
          ? modifiedTrainingLogs.some(videoSegment => {
              return (
                videoSegment.videoName === filename &&
                videoSegment.surgeonEmail === surgeonEmail
              )
            })
          : false

        return hasSimilarObject ? (
          <CheckCircleTwoTone twoToneColor="#52C41A" />
        ) : (
          <CloseCircleTwoTone twoToneColor="#C41E3A" />
        )
      },
    },
    {
      title: getTitle('Dataset Tags'),
      dataIndex: 'tags',
      key: 'tags',
      width: '12%',
      render: (tags: string[], video: VideoSegment) => (
        <IndividualTagColumn tags={tags} videos={[video]} />
      ),
    },
    {
      title: getTitle('Classification Tags'),
      dataIndex: 'classificationTags',
      key: 'classificationTags',
      width: '12%',
      render: (classificationTags: string[], video: VideoSegment) => {
        return (
          <ClassificationTagColumn
            classificationTags={classificationTags}
            videos={[video]}
          />
        )
      },
    },
  ]

  const smeVideoAssetsColumn = [
    {
      title: getTitle('Video File'),
      dataIndex: 'filename',
      key: 'filename',
      width: '18%',
      // sorter: (a, b) => compare('filename', a, b),
      render: (_: any, video: VideoSegment) => <PlayColumn video={video} />,
    },
    {
      title: getTitle('Downloaded from Inhance'),
      dataIndex: 'download_count',
      key: 'download_count',
      width: '11%',
      render: (_: any, video: VideoSegment) =>
        video.download_count > 0 ? (
          <CheckCircleTwoTone twoToneColor="#52C41A" />
        ) : (
          <CloseCircleTwoTone twoToneColor="#C41E3A" />
        ),
    },
    {
      title: getTitle('Exported to SA'),
      dataIndex: 'export_count',
      key: 'export_count',
      width: '8%',
      render: (_: any, video: VideoSegment) =>
        video.export_count > 0 ? (
          <CheckCircleTwoTone twoToneColor="#52C41A" />
        ) : (
          <CloseCircleTwoTone twoToneColor="#C41E3A" />
        ),
    },
    {
      title: getTitle('Labeled in SA'),
      width: '8%',
      render: (_: any, video: VideoSegment) => {
        const filename = video?.filename
        const surgeonEmail = get(allDoctors[video?.doctor], 'email', '')

        const hasSimilarObject = modifiedExportLogs
          ? modifiedExportLogs.some(videoSegment => {
              return (
                videoSegment.videoName === filename &&
                videoSegment.surgeonEmail === surgeonEmail
              )
            })
          : false

        return hasSimilarObject ? (
          <CheckCircleTwoTone twoToneColor="#52C41A" />
        ) : (
          <CloseCircleTwoTone twoToneColor="#C41E3A" />
        )
      },
    },
    {
      title: getTitle('Used for training'),
      width: '8%',
      render: (_: any, video: VideoSegment) => {
        const filename = video?.filename
        const surgeonEmail = get(allDoctors[video?.doctor], 'email', '')

        const hasSimilarObject = modifiedTrainingLogs
          ? modifiedTrainingLogs.some(videoSegment => {
              return (
                videoSegment.videoName === filename &&
                videoSegment.surgeonEmail === surgeonEmail
              )
            })
          : false

        return hasSimilarObject ? (
          <CheckCircleTwoTone twoToneColor="#52C41A" />
        ) : (
          <CloseCircleTwoTone twoToneColor="#C41E3A" />
        )
      },
    },
    {
      title: getTitle('Dataset Tags'),
      dataIndex: 'tags',
      key: 'tags',
      width: '10%',
      render: (tags: string[], video: VideoSegment) => (
        <IndividualTagColumn tags={tags} videos={[video]} />
      ),
    },
    {
      title: getTitle('Classification Tags'),
      dataIndex: 'classificationTags',
      key: 'classificationTags',
      width: '11%',
      render: (classificationTags: string[], video: VideoSegment) => {
        return (
          <ClassificationTagColumn
            classificationTags={classificationTags}
            videos={[video]}
          />
        )
      },
    },
    {
      title: getTitle('Surgery Facility'),
      dataIndex: 'surgery_facility',
      key: 'surgery_facility',
      width: '8%',
      render: (_: any, video: VideoSegment) => (
        <Text>{video.surgery_facility}</Text>
      ),
    },
    {
      title: getTitle('Surgeon Name'),
      dataIndex: 'surgeon_name',
      key: 'surgeon_name',
      width: '8.5%',
      render: (_: any, video: VideoSegment) => (
        <Text>{video.surgeon_name}</Text>
      ),
    },
    {
      title: getTitle('Procedure Type'),
      dataIndex: 'procedure_type',
      key: 'procedure_type',
      width: '9.5%',
      render: (_: any, video: VideoSegment) => (
        <Text>{video.procedure_type}</Text>
      ),
    },
  ]

  if (isSMEVideoAssetsPage) {
    return smeVideoAssetsColumn
  } else {
    return columns
  }
}
