import { Empty as AntEmpty } from 'antd'
import styled from 'styled-components'

export const Empty = styled(AntEmpty)`
  height: 663px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const EmptyHistory = styled(AntEmpty)`
  height: 705px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`