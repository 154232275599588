import { OAuthError, useAuth0 } from '@auth0/auth0-react'
import configureAxios from 'configs/api'
import { useEffect, useState } from 'react'

export const JWT_ACCESS_TOKEN = 'jwtAccessToken'

export const setAuthToken = (accessToken: string) => {
  localStorage.setItem(JWT_ACCESS_TOKEN, accessToken)
}

export const initialiseAuthentication = () => {
  const accessToken = localStorage.getItem(JWT_ACCESS_TOKEN)
  configureAxios()
  if (!accessToken) {
    console.log('No access token found')
    return
  }
  return setupAuthentication(accessToken)
}

export const setupAuthentication = (accessToken: string) => {
  setAuthToken(accessToken)
  configureAxios(accessToken)
}

export interface AccessTokenOptions {
  audience: string
  scope?: string
}

export interface AccessTokenResponse {
  accessToken: string
  refresh: () => void
}

export const useGetAccessToken = (
  options: AccessTokenOptions = {
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
  },
): AccessTokenResponse => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  const [accessToken, setAccessToken] = useState('')
  const [refreshIndex, setRefreshIndex] = useState(0)

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently(options)
        setAccessToken(token)
      } catch (error) {
        if ((error as OAuthError).error === 'consent_required') {
          const token = await getAccessTokenWithPopup(options)
          setAccessToken(token)
        } else if ((error as OAuthError).error === 'login_required') {
          // do nothing
        } else if ((error as OAuthError).error === 'interaction_required') {
          // do nothing
        } else {
          throw error
        }
      }
    })()
  }, [refreshIndex])

  return {
    accessToken,
    refresh: () => setRefreshIndex(refreshIndex + 1),
  }
}
