import './AnalyticsTab.css'

import { Spin } from 'antd'
import { Empty } from 'components/Empty'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { fetchVideoDataFlow } from 'store/history/actions'
import { getAllEntries } from 'store/history/selectors'
import { RootState } from 'store/rootReducer'

type Props = StoreProps<typeof mapStateToProps, typeof mapDispatchToProps> &
  OwnProps

interface OwnProps {
  videoId: string
}

const AnalyticsTab: React.FC<Props> = ({
  videoId,
  videoEntries,
  fetchVideoData,
}) => {
  const loadInitialData = () => {
    fetchVideoData({ videoId })
  }
  React.useEffect(loadInitialData, [])

  const graphURIs: string[] = []

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1300)
  }, [])

  if (Object.keys(videoEntries).length !== 0) {
    const uriArray =
      videoEntries[0].kalGraphURI === undefined
        ? []
        : videoEntries[0].kalGraphURI
    for (const index of uriArray) {
      const part = index.split('/')[5]
      graphURIs.push(part)
    }

    if (_.isEmpty(uriArray)) {
      return <Empty description={'No KalGraph charts found.'} />
    }
  }

  return loading ? (
    <div className="spinner">
      <Spin />
    </div>
  ) : graphURIs.length === 1 ? (
    <div>
      {graphURIs.map((graphURI: string, index: number) => (
        <div key={index}>
          <div className="header">Anatomy KalGraph</div>
          <img
            className="kalgraph"
            src={`https://drive.google.com/uc?export=view&id=${graphURI}`}
            alt="Anatomy KalGraph chart"
          />
        </div>
      ))}
    </div>
  ) : (
    <div>
      <div className="header">Anatomy KalGraph</div>
      <img
        className="kalgraph"
        src={`https://drive.google.com/uc?export=view&id=${graphURIs[0]}`}
        alt="Anatomy KalGraph chart"
      />
      <div className="header">Pathology KalGraph</div>
      <img
        className="kalgraph"
        src={`https://drive.google.com/uc?export=view&id=${graphURIs[1]}`}
        alt="Pathology KalGraph chart"
      />
    </div>
  )
}

const mapStateToProps = (s: RootState) => ({
  videoEntries: getAllEntries(s),
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchVideoData: fetchVideoDataFlow,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsTab)
