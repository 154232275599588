/**
 * The implementation is adapted from
 * https://stackoverflow.com/questions/35623656/how-can-i-display-a-modal-dialog-in-redux-that-performs-asynchronous-actions/35641680#35641680
 */
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators,Dispatch } from 'redux'
import { hideModalFlow } from 'store/modal/actions'
import { RootState } from 'store/rootReducer'

import MODAL_COMPONENTS from './ModalComponents'

type Props = StoreProps<typeof mapStateToProps, typeof mapDispatchToProps>

const ModalContainer: React.FC<Props> = ({
  modalType,
  modalProps,
  hideModal,
}) => {
  if (modalType === null) {
    return null
  }
  const SpecificModal = MODAL_COMPONENTS[modalType]
  return <SpecificModal {...modalProps} hideModal={hideModal} />
}

const mapStateToProps = (s: RootState) => ({
  modalType: s.modal.modalType,
  modalProps: s.modal.modalProps,
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      hideModal: hideModalFlow,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer)
