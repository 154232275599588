import { Popover } from 'antd'
import { BLUE } from 'common/colors'
import { Spacing } from 'common/stylings'
import ErrorText from 'components/ErrorText'
import Icon from 'components/Icon'
import Spacer from 'components/Spacer'
import Stack from 'components/Stack'
import React, { ReactNode } from 'react'
import { FieldMetaState } from 'react-final-form'
import styled from 'styled-components'

interface Props {
  label: string
  input: ReactNode
  info?: string | React.ReactNode
  meta?: FieldMetaState<any>
}

const FormItem: React.FC<Props> = ({ label, input, info, meta }) => {
  const renderInfoSign = () => {
    return (
      <Popover content={info}>
        <Icon icon="info-sign" style={{ color: BLUE }} />
      </Popover>
    )
  }

  return (
    <Stack gutter={Spacing.LARGE}>
      <Stack justifyContent="flex-start" gutter={Spacing.EXTRA_SMALL}>
        <Label>{label}</Label>
        {info && renderInfoSign()}
      </Stack>
      <Stack fillParentWidth vertical gutter={Spacing.EXTRA_SMALL}>
        {input}
        {meta && <ErrorText error={meta.error} touched={meta.touched} />}
      </Stack>
      <Spacer />
    </Stack>
  )
}

const Label = styled.label`
  width: 140px;
  text-align: right;
  font-weight: bold;
`

export default FormItem
