import NavBar from 'components/NavBar'
import Stack from 'components/Stack'
import React, { FC } from 'react'

import BodyLayout from './BodyLayout'
import HeaderLayout from './HeaderLayout'

const AppLayout: FC<{}> = ({ children }) => {
  return (
    <Stack vertical>
      <HeaderLayout style={{ zIndex: 999 }}>
        <NavBar />
      </HeaderLayout>
      <BodyLayout>{children}</BodyLayout>
    </Stack>
  )
}

export default AppLayout
