import { Frame } from 'common/models'
import { keyBy, omit } from 'lodash'
import * as videoActions from 'store/videoSegments/actionCreators'
import { VideoSegmentsActionType } from 'store/videoSegments/reducer'
import { ActionType, getType } from 'typesafe-actions'

import * as frameActions from './actionCreators'

export type FrameActionType = ActionType<typeof frameActions>

interface FrameState {
  frames: Dict<Frame>
}

const initialState: Readonly<FrameState> = {
  frames: {},
}

export default (
  state = initialState,
  action: FrameActionType | VideoSegmentsActionType,
) => {
  switch (action.type) {
    case getType(frameActions.retrieveVideoFrames.success): {
      const frameDict: Dict<Frame> = keyBy(action.payload, '_id')
      return {
        ...state,
        frames: {
          ...state.frames,
          ...frameDict,
        },
      }
    }
    case getType(frameActions.deleteFrame.success): {
      const { frameId } = action.payload
      const newFrames = omit(state.frames, frameId)
      return {
        ...state,
        frames: newFrames,
      }
    }
    case getType(frameActions.bulkDeleteFrames.success): {
      const { frameIds } = action.payload
      const newFrames = omit(state.frames, frameIds)
      return {
        ...state,
        frames: newFrames,
      }
    }
    case getType(videoActions.extractVideoFrames.success): {
      const frameDict: Dict<Frame> = keyBy(action.payload.frames, '_id')
      return {
        ...state,
        frames: {
          ...state.frames,
          ...frameDict,
        },
      }
    }
    default:
      return state
  }
}
