import { createStandardAction } from 'typesafe-actions'

export const showModal = createStandardAction('Open Modal')<{
  modalType: any
  modalProps: any
}>()

export const hideModal = createStandardAction('Close Modal')<{
  modalProps: any
}>()
