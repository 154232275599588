import { get } from 'lodash'

export const parseHTTPError = (err: Error) => {
  const message: string = get(
    err,
    ['response', 'data', 'message'],
    'An unknown error has occured',
  )
  const status: number = get(err, ['response', 'status'], 500)
  return {
    message,
    status,
  }
}
