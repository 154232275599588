import { Tabs as AntTabs } from 'antd'
import styled from 'styled-components'

export const { TabPane } = AntTabs

const TabContainer = styled(AntTabs)`
  && {
    font-size: 16px;
  }
  .ant-tabs {
    &-nav {
      display: flex;

      .ant-tabs-tab {
        flex: 1;
        text-align: center;
      }
    }
  }
`
export default TabContainer
