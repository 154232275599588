import axios from 'axios'
import { Doctor } from 'common/models'
import { Dispatch } from 'redux'
import { showModalFlow } from 'store/modal/actions'

import { createNewDoctor,fetchAllDoctorsInfo } from './actionCreators'

export const fetchAllDoctorsInfoFlow = () => (dispatch: Dispatch) => {
  dispatch(fetchAllDoctorsInfo.request())
  axios
    .get('/doctor/all')
    .then(res => {
      const doctors: Doctor[] = res.data.doctors
      dispatch(fetchAllDoctorsInfo.success(doctors))
    })
    .catch(err => dispatch(fetchAllDoctorsInfo.failure(err)))
}

/**
 * Upon creation of an uploader account on auth0, the new doctor's credential
 * will be inserted into mongodb through asset manager.
 * This is to verify the uploader's credentials when video data gets inserted
 * from uploader to dashboard
 */
export const createDoctorAccountFlow = (
  fullname: string,
  email: string,
  password: string,
) => (dispatch: Dispatch) => {
  dispatch(createNewDoctor.request({ fullname, email }))
  axios
    .post('/doctor/new', { fullname, email, password })
    .then(res => {
      const _id = res.data._id
      dispatch(createNewDoctor.success({ _id, fullname, email }))
      showModalFlow('SUCCESS', {
        isOpen: true,
        message: 'The account is created successfully',
      })(dispatch)
    })
    .catch(err => {
      dispatch(createNewDoctor.failure(err))
      let message = err.message
      if (
        err.response.status === 400 &&
        err.response.data.message ===
          'Error encountered while validating fields!'
      ) {
        message = 'Error encountered while validating field parameters'
      }
      if (err.response.status === 403) {
        message = 'You do not have permission to create an uploader account'
      }
      showModalFlow('ERROR', {
        isOpen: true,
        status: err.status,
        message: message,
      })(dispatch)
    })
}
