enum EntryType {
  ADD_TAG = 'ADD_TAG',
  REMOVE_TAG = 'REMOVE_TAG',
  ADD_REGION = 'ADD_REGION',
  // REMOVE_REGION = 'REMOVE_REGION',
  ADD_PROCEDURE = 'ADD_PROCEDURE',
  // REMOVE_PROCEDURE = 'REMOVE_PROCEDURE',
  DELETE_FRAMES = 'DELETE_FRAMES',
  EXTRACT_FRAMES = 'EXTRACT_FRAMES',
  LABELBOX_UPLOAD = 'LABELBOX_UPLOAD',
  ERROR = 'ERROR',
}

export enum PropertyType {
  REGION = 'region',
  TAG = 'tag',
  PROCEDURE = 'procedure',
  FRAME = 'frame',
  LABELBOX = 'labelbox',
}

export default EntryType
