import { Spacing } from 'common/stylings'
import styled from 'styled-components'

interface Props {
  vertical?: boolean
  gutter?: Spacing
  center?: boolean
  scrollable?: boolean
  fillParentWidth?: boolean
  fillParentHeight?: boolean
  alignItems?: string
  justifyContent?: string
  flexWrap?: string
  width?: string
  height?: string
}

const Stack = styled.div<Props>`
  display: flex;

  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};

  ${({ fillParentHeight }) => fillParentHeight && 'height: 100%;'}
  ${({ fillParentWidth }) => fillParentWidth && 'width: 100%;'}
  ${({ width }) => width && `width: ${width}`}
  ${({ height }) => height && `height: ${height}`}

  ${({ scrollable }) => scrollable && 'overflow: scroll;'}
  ${({ center }) => center && 'align-items: center; justify-content: center;'}

  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`}
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent}`}
   ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap}`}

  & > * {
    margin-bottom: ${({ vertical, gutter }) =>
      !vertical ? Spacing.NONE : gutter}
    margin-right: ${({ vertical, gutter }) =>
      vertical ? Spacing.NONE : gutter}
  }

  & > *:last-child {
    ${({ vertical }) =>
      vertical
        ? `margin-bottom: ${Spacing.NONE};`
        : `margin-right: ${Spacing.NONE};`}
  }
`

export default Stack
