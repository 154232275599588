import { RED } from 'common/colors'
import { Text } from 'components/Typography'
import React, { FC } from 'react'

/**
 * @desc: Red error text for react-final-form validation
 */

interface Props {
  /**
   * @desc: Error message to be displayed
   */
  error?: string
  /**
   * @desc: Returns true if the field has ever gained and lost focus. false otherwise.
   */
  touched?: boolean
}

const ErrorText: FC<Props> = ({ error, touched }) => {
  if (error && touched) {
    return (
      <Text fontSize={12} color={RED}>
        {error}
      </Text>
    )
  }
  return null
}

export default ErrorText
