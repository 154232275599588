import { ActionType, getType } from 'typesafe-actions'

import * as historyActions from './actionCreators'

export type HistoryActionType = ActionType<typeof historyActions>

interface HistoryState {
  entries: any
}

const initialState: Readonly<HistoryState> = {
  entries: {},
}

export default (state = initialState, action: HistoryActionType) => {
  let entry
  switch (action.type) {
    case getType(historyActions.fetchVideoData.success):
      return {
        ...state,
        entries: action.payload,
      }
    case getType(historyActions.addVideoHistoryEntry.success):
      entry = action.payload
      return {
        ...state,
        entries: {
          ...state.entries,
          [entry._id]: entry,
        },
      }
    default:
      return state
  }
}
