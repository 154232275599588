import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist-kaliber'
import storage from 'redux-persist-kaliber/lib/storage'
import { StateType } from 'typesafe-actions'

import augmentedMasksReducer from './augmentedMasks/reducer'
import authReducer from './authentication/reducer'
import doctorReducer from './doctor/reducer'
import exportLogsReducer from './exportLogs/reducer'
import frameReducer from './frames/reducer'
import historyReducer from './history/reducer'
import importLogsReducer from './importLogs/reducer'
import modalReducer from './modal/reducer'
import modelsReducer from './models/reducer'
import filterReducer from './restoreVideoState/reducer'
import routerReducer from './router/reducer'
import statusReducer from './status/reducer'
import trainingLogsReducer from './trainingLogs/reducer'
import videoSegmentsReducer from './videoSegments/reducer'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['restoreVideo'],
}

const rootReducer = combineReducers({
  auth: authReducer,
  videoSegment: videoSegmentsReducer,
  augmentedMasks: augmentedMasksReducer,
  status: statusReducer,
  models: modelsReducer,
  frame: frameReducer,
  history: historyReducer,
  router: routerReducer,
  doctor: doctorReducer,
  modal: modalReducer,
  restoreVideo: filterReducer,
  importLogs: importLogsReducer,
  exportLogs: exportLogsReducer,
  trainingLogs: trainingLogsReducer,
})

export type RootState = StateType<typeof rootReducer>

export default persistReducer(persistConfig, rootReducer)
