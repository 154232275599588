import { Button } from 'antd'
import { forEach, get, isEmpty } from 'lodash'
import VideoModal from 'main/videoAssets/VideoModal'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators,Dispatch } from 'redux'
import { getAllDoctors } from 'store/doctor/selectors'
import {
  addDoctorIDsFlow,
  addFiltersCheckFlow,
  addFiltersFlow,
  addInputSearchFlow,
  addUserFiltersFlow,
} from 'store/restoreVideoState/actions'
import {
  initialFilterSelector,
  initialSearchFieldSelector,
  initialVideoSelector,
} from 'store/restoreVideoState/selectors'
import { RootState } from 'store/rootReducer'

type AllProps = StoreProps<typeof mapStateToProps, typeof mapDispatchToProps> &
  OwnProps

interface OwnProps {
  onFiltersChange: (
    filters: Record<string, React.ReactText[] | null> | null,
  ) => void
  onDoctorIdsChange: (ids: string[]) => void
  onSearch: (value: string) => void
  video: any
}

const RestoreVideoColumn: React.FC<AllProps> = ({
  video,
  initialFilter,
  initialSearchField,
  initialVideo,
  allDoctors,
  addDoctorID,
  addInputSearch,
  addUserFilters,
  addFiltersCheck,
}) => {
  const [isVisible, toggleVisible] = useState(false)

  const saveFilters = () => {
    const userFilters = JSON.parse(initialFilter)
    const selectedDoctorNames = get(userFilters, 'doctor', []) as string[]

    // Save filter check to see if user clicked on 'Restore Session' button
    addFiltersCheck(true)

    // Show video tab
    toggleVisible(true)

    // Save input search field to redux store
    addInputSearch(initialSearchField)

    // Save user filters to redux store
    addUserFilters(userFilters)

    if (isEmpty(selectedDoctorNames)) {
      addDoctorID([])
      addUserFilters(userFilters)
      return
    }

    const selectedDoctorIds: string[] = []
    forEach(allDoctors, (doctor, doctorId) => {
      if (selectedDoctorNames.includes(get(doctor, 'fullname', ''))) {
        selectedDoctorIds.push(doctorId)
      }
    })

    // Save doctor IDs to redux store
    addDoctorID(selectedDoctorIds)
  }

  if (initialVideo === '{}') {
    return (
      <Button
        disabled
        style={{
          padding: 0,
          width: '100%',
          height: '100%',
          textAlign: 'left',
        }}
        type={'link'}>
        No previous session
      </Button>
    )
  } else {
    return (
      <div>
        <Button
          style={{
            padding: 0,
            width: '100%',
            height: '100%',
            textAlign: 'left',
          }}
          type={'link'}
          onClick={saveFilters}>
          Restore session
        </Button>
        {isVisible && (
          <VideoModal
            video={video}
            isVisible={isVisible}
            toggleVisible={toggleVisible}
            activeKey={'1'}
          />
        )}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addFilters: addFiltersFlow,
      addDoctorID: addDoctorIDsFlow,
      addInputSearch: addInputSearchFlow,
      addUserFilters: addUserFiltersFlow,
      addFiltersCheck: addFiltersCheckFlow,
    },
    dispatch,
  )

const mapStateToProps = (s: RootState) => ({
  initialFilter: initialFilterSelector(s),
  initialSearchField: initialSearchFieldSelector(s),
  initialVideo: initialVideoSelector(s),
  allDoctors: getAllDoctors(s),
})

export default connect(mapStateToProps, mapDispatchToProps)(RestoreVideoColumn)
