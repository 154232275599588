import { Icon } from '@blueprintjs/core'
import { BLACK,RED } from 'common/colors'
import { Spacing } from 'common/stylings'
import Modal from 'components/Modal'
import Stack from 'components/Stack'
import Text from 'components/Typography'
import React from 'react'

interface Props {
  isOpen: boolean
  message: string
  status: number
  hideModal: (...args: any) => void
}

const ErrorModal: React.FC<Props> = ({
  isOpen,
  message,
  status,
  hideModal,
}) => (
  <Modal
    visible={isOpen}
    onCancel={() => hideModal({ isOpen: false })}
    footer={null}>
    <Stack vertical gutter={Spacing.MEDIUM}>
      <Stack alignItems="center" gutter={Spacing.REGULAR}>
        <Icon
          icon="delete"
          style={{ paddingTop: 7, color: RED, fontSize: 24 }}
        />
        <Text
          style={{ paddingLeft: 8 }}
          fontWeight="bold"
          color={BLACK}
          fontSize={18}>
          {status} something went wrong...
        </Text>
      </Stack>
      <Text
        style={{ paddingTop: 10, fontSize: 15 }}
        color={BLACK}
        fontSize={14}>
        {message}
      </Text>
    </Stack>
  </Modal>
)

export default ErrorModal
