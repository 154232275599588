import { User } from 'common/models'
import { keyBy } from 'lodash'
import { ActionType, getType } from 'typesafe-actions'

import * as authActions from './actionCreators'

export type AuthActionType = ActionType<typeof authActions>

interface AuthState {
  isAuthenticated: boolean
  user: User
  users: Dict<User>
}

const initialState: Readonly<AuthState> = {
  isAuthenticated: false,
  user: {
    uid: '',
    fullname: '',
    email: '',
    role: '',
  },
  users: {},
}

export default (state = initialState, action: AuthActionType) => {
  let users: Dict<User>
  switch (action.type) {
    case getType(authActions.setCurrentUser):
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      }
    case getType(authActions.fetchAllUsersInfo.success):
      users = keyBy(action.payload, 'uid')
      return {
        ...state,
        users,
      }
    case getType(authActions.loginUser.failure):
    case getType(authActions.logoutUser.success):
      return initialState
    default:
      return state
  }
}
