import _ from 'lodash'
import { createSelector } from 'reselect'
import { RootState } from 'store/rootReducer'

const doctorSelector = (s: RootState) => s.doctor

export const getAllDoctors = createSelector(doctorSelector, s => s.doctors)

export const groupDoctorsByName = createSelector(getAllDoctors, doctors =>
  _.groupBy(doctors, doctor => doctor.fullname),
)
