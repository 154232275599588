import _ from 'lodash'
import { ActionType,getType } from 'typesafe-actions'

import RootAction from '../rootAction'
import { RootState } from '../rootReducer'
import { ErrorState } from './reducer'

const emptyErrorState: ErrorState = {
  isError: false,
  message: '',
  statusCode: 200,
}

export const createLoadingSelector = (actions: ActionType<RootAction>[]) => (
  state: RootState,
) => {
  return _(actions).some(action => {
    const type = getType(action)
    const matches = /(.*)__Request$/.exec(type)

    if (!matches) {
      return true
    }
    const [, requestName] = matches

    return _.get(state.status, [requestName, 'loading'])
  })
}

export const createErrorSelector = (actions: ActionType<RootAction>[]) => (
  state: RootState,
) => {
  return (
    _(actions)
      .map(action => {
        const type = getType(action)
        const matches = /(.*)__Failure$/.exec(type)

        if (!matches) {
          return emptyErrorState
        }
        const [, requestName] = matches
        return _.get(state.status, [requestName, 'error'])
      })
      .compact()
      .first() || emptyErrorState
  )
}

export const createSuccessSelector = (actions: ActionType<RootAction>[]) => (
  state: RootState,
) => {
  return _(actions).some(action => {
    const type = getType(action)
    const matches = /(.*)__Success$/.exec(type)

    if (!matches) {
      return true
    }
    const [, requestName] = matches

    return _.get(state.status, [requestName, 'success'])
  })
}
