import { Frame } from 'common/models'
import { createAsyncAction } from 'typesafe-actions'

export const retrieveVideoFrames = createAsyncAction(
  'Retrieve_Video_Frames__Request',
  'Retrieve_Video_Frames__Success',
  'Retrieve_Video_Frames__Failure',
)<{ videoId: string }, Array<Frame>, Error>()

export const deleteFrame = createAsyncAction(
  'Delete_Frame__Request',
  'Delete_Frame__Success',
  'Delete_Frame__Failure',
)<{ frameId: string }, { frameId: string }, Error>()

export const bulkDeleteFrames = createAsyncAction(
  'Bulk_Delete_Frame__Request',
  'Bulk_Delete_Frame__Success',
  'Bulk_Delete_Frame__Failure',
)<{ frameIds: string[] }, { frameIds: string[] }, Error>()
