import React from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

interface Props {
  importLogsData: any
}

interface DoctorImportLog {
  name: string
  count: any
}

const transformImportLogs = (importLogsData: any): DoctorImportLog[] => {
  const transformedLogs: DoctorImportLog[] = []

  Object.entries(importLogsData).forEach(([key, value]) => {
    transformedLogs.push({ name: key, count: value })
  })

  return transformedLogs.sort((a, b) => (a.count > b.count ? -1 : 1))
}

const ImportChart: React.FC<Props> = ({ importLogsData }) => {
  const transformedLogs = transformImportLogs(importLogsData)

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        barGap="1%"
        data={transformedLogs}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" interval={0} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          isAnimationActive={false}
          dataKey="count"
          radius={8}
          fill={'#85A5FF'}
          name="Imported video count">
          <LabelList
            dataKey="count"
            position="center"
            angle={0}
            offset={25}
            fill="black"
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export default ImportChart
