import axios from 'axios'
import { Entry } from 'common/models'
import { get } from 'lodash'
import { stringify } from 'query-string'
import { Dispatch } from 'redux'

import {
  addVideoHistoryEntry,
  fetchVideoData,
  NewEntryPayload,
} from './actionCreators'

export const fetchVideoDataFlow = (payload: { videoId: string }) => (
  dispatch: Dispatch,
) => {
  dispatch(fetchVideoData.request(payload))
  axios
    .get(`/history/video?${stringify(payload)}`)
    .then(res => {
      const entries: Entry[] = get(res.data, 'entries')
      dispatch(fetchVideoData.success(entries))
    })
    .catch(err => dispatch(fetchVideoData.failure(err)))
}

export const addVideoHistoryEntryFlow = (payload: NewEntryPayload) => (
  dispatch: Dispatch,
) => {
  dispatch(addVideoHistoryEntry.request(payload))
  axios
    .post('/history/video', payload)
    .then(res => {
      const entry: Entry = get(res.data, 'entry')
      dispatch(addVideoHistoryEntry.success(entry))
    })
    .catch(err => dispatch(addVideoHistoryEntry.failure(err)))
}
