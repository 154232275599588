import { Input } from 'antd'
import { Spacing } from 'common/stylings'
import FormItem from 'components/Form/FormItem'
import Modal from 'components/Modal'
import Stack from 'components/Stack'
import React from 'react'
import {
  Field,
  FieldRenderProps,
  Form,
  FormRenderProps,
} from 'react-final-form'
import { AnyAction,Dispatch } from 'redux'
import { required } from 'utilities/validations'

interface Props {
  isOpen: boolean
  hideModal: (...args: any) => void
  createModel: (payload: {
    name: string
  }) => (disptach: Dispatch<AnyAction>) => void
}

const NewModelModal: React.FC<Props> = ({ isOpen, hideModal, createModel }) => {
  const getInitialFormValues = () => ({
    name: '',
  })

  const onSubmit = (values: { name: string }) => {
    createModel(values)
  }

  return (
    <Form onSubmit={onSubmit} initialValues={getInitialFormValues()}>
      {(formProps: FormRenderProps) => (
        <form onSubmit={formProps.handleSubmit}>
          <Modal
            visible={isOpen}
            onCancel={() => hideModal({ isOpen: false })}
            onOk={() => formProps.handleSubmit()}
            okButtonProps={{ htmlType: 'submit' }}
            title="Create New Model"
          >
            <Stack vertical gutter={Spacing.MEDIUM} fillParentWidth>
              <Field name="name" validate={required}>
                {(p: FieldRenderProps<string, HTMLElement>) => (
                  <FormItem
                    label="Model Name"
                    meta={p.meta}
                    input={
                      <Input
                        value={p.input.value}
                        onChange={p.input.onChange}
                      />
                    }
                  />
                )}
              </Field>
            </Stack>
          </Modal>
        </form>
      )}
    </Form>
  )
}

export default NewModelModal
