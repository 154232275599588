import React from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

interface Props {
  exportClasses: string[][]
  exportFrameCount: string[][]
  desiredClass: string
}

interface ClassesChartData {
  className: string
  frameCount: string
}

const transformChartData = (
  exportClasses: string[][],
  exportFrameCount: string[][],
): ClassesChartData[] => {
  const flatClass = exportClasses.flat()
  const flatFrameCount = exportFrameCount.flat()

  const transformedChartData: ClassesChartData[] = []

  flatClass.forEach((className, index) => {
    const frameCount = flatFrameCount[index]
    transformedChartData.push({ className, frameCount })
  })

  return transformedChartData
    .reduce((acc: ClassesChartData[], info: ClassesChartData) => {
      const sameClassName = acc.find(a => a.className === info.className)
      if (sameClassName) {
        const idx = acc.findIndex(a => a.className === info.className)
        acc[idx].frameCount += info.frameCount
      } else {
        acc.push(info)
      }

      return acc
    }, [])
    .sort((a, b) => (a.frameCount > b.frameCount ? -1 : 1))
}

const ClassesChart: React.FC<Props> = ({
  exportClasses,
  exportFrameCount,
  desiredClass,
}) => {
  let classesData = transformChartData(exportClasses, exportFrameCount)

  if (desiredClass !== undefined && desiredClass !== 'Choose class') {
    classesData = classesData.filter(obj => obj.className === desiredClass)
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        barGap="1%"
        data={classesData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="className" interval={0} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          isAnimationActive={false}
          dataKey="frameCount"
          radius={8}
          fill={'#eb906e'}
          name="Classes count">
          <LabelList
            dataKey="frameCount"
            position="center"
            angle={0}
            offset={25}
            fill="black"
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export default ClassesChart
