import { Spacing } from 'common/stylings'
import styled from 'styled-components'

interface Props {
  size?: Spacing
}

const Spacer = styled.div<Props>`
  padding-top: ${({ size }) => size || Spacing.EXTRA_SMALL};
  padding-bottom: ${({ size }) => size || Spacing.EXTRA_SMALL};
`
export default Spacer
