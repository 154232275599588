import { VideoSegment } from 'common/models'
import Modal from 'components/Modal'
import TabContainer, { TabPane } from 'components/Tab'
import React from 'react'

import AnalyticsTab from './AnalyticsTab'
import FrameTab from './FrameTab'
import HistoryTab from './HistoryTab'
import VideoTab from './VideoTab'

interface Props {
  video: VideoSegment
  isVisible: boolean
  toggleVisible: (isVisible: boolean) => void
  activeKey: string
}

const VideoModal: React.FC<Props> = ({
  video,
  isVisible,
  toggleVisible,
  activeKey,
}) => {
  const [isVideo, setIsVideo] = React.useState(false)
  const [isZip, setIsZip] = React.useState(false)
  const [isCSV, setIsCSV] = React.useState(false)

  const isVideoFile = (video: VideoSegment) => {
    const videoExtensions = ['mp4', 'mov', 'avi', 'mpeg', 'mpg']
    return (
      video.filename &&
      videoExtensions.some(extension =>
        video.filename.toLowerCase().endsWith(extension),
      )
    )
  }

  const isZipFile = (video: VideoSegment): boolean => {
    return video.filename?.toLowerCase().endsWith('.zip') ?? false
  }

  const isCSVFile = (video: VideoSegment): boolean => {
    return video.filename?.toLowerCase().endsWith('.csv') ?? false
  }

  React.useEffect(() => {
    if (isVideoFile(video)) {
      setIsVideo(true)
    } else {
      setIsVideo(false)
    }
  }, [video])

  React.useEffect(() => {
    if (isZipFile(video)) {
      setIsZip(true)
    } else {
      setIsZip(false)
    }
  }, [video])

  React.useEffect(() => {
    if (isCSVFile(video)) {
      setIsCSV(true)
    } else {
      setIsCSV(false)
    }
  }, [video])

  const [activeTab, setActiveTab] = React.useState('')
  if (activeTab === '0') {
    activeKey = '0'
  }
  if (activeTab === '1') {
    activeKey = '1'
  }
  if (activeTab === '2') {
    activeKey = '2'
  }
  if (activeTab === '3') {
    activeKey = '3'
  }
  return (
    <Modal
      visible={isVisible}
      footer={null}
      onCancel={() => toggleVisible(false)}
      width={780}
      zIndex={1}>
      <TabContainer activeKey={activeKey} onChange={setActiveTab}>
        {/* <TabPane tab="Frames" key="0">
          <FrameTab video={video} />
        </TabPane> */}
        <TabPane tab="Video" key="1">
          <VideoTab
            video={video}
            isVideo={isVideo}
            isZip={isZip}
            isCSV={isCSV}
            setActiveTab={setActiveTab}
            toggleVisible={toggleVisible}
          />
        </TabPane>
        {/* <TabPane tab="History" key="2">
          <HistoryTab videoId={video._id} />
        </TabPane> */}
        <TabPane tab="Analytics" key="3">
          <AnalyticsTab videoId={video._id} />
        </TabPane>
      </TabContainer>
    </Modal>
  )
}

export default VideoModal
