import { Model,ModelVersion } from 'common/models'
import { find,get, keyBy, map } from 'lodash'
import * as authActions from 'store/authentication/actionCreators'
import { AuthActionType } from 'store/authentication/reducer'
import { ActionType, getType } from 'typesafe-actions'

import * as modelActions from './actionCreators'
import { parseModel,parseVersion } from './helper'

export type ModelActionType = ActionType<typeof modelActions>

interface ModelState {
  allModels: Dict<Model>
  allModelVersions: Dict<ModelVersion>
}

const initialState: Readonly<ModelState> = {
  allModels: {},
  allModelVersions: {},
}

export default (
  state = initialState,
  action: ModelActionType | AuthActionType,
) => {
  let versionId
  let currLatestVersion
  switch (action.type) {
    // Model
    case getType(modelActions.createNewModel.success): {
      const newModel: Model = parseModel(get(action.payload, 'model'))
      return {
        ...state,
        allModels: {
          ...state.allModels,
          [newModel._id]: newModel,
        },
      }
    }
    case getType(modelActions.retrieveAllModels.success): {
      const models: Model[] = map(action.payload.models, model =>
        parseModel(model),
      )
      const allModels: Dict<Model> = keyBy(models, '_id')
      return {
        ...state,
        allModels,
      }
    }
    // ModelVersion aka SubVersion
    case getType(modelActions.retrieveAllModelVersions.success): {
      const versions: ModelVersion[] = map(action.payload.versions, version =>
        parseVersion(version),
      )
      const allModelVersions: Dict<ModelVersion> = keyBy(versions, '_id')
      return {
        ...state,
        allModelVersions,
      }
    }
    case getType(modelActions.retrieveModelVersion.success): {
      const modelVersion: ModelVersion = parseVersion(
        get(action.payload, 'version'),
      )
      return {
        ...state,
        allModelVersions: {
          ...state.allModelVersions,
          [modelVersion._id]: modelVersion,
        },
      }
    }
    case getType(modelActions.createNewModelVersion.success): {
      const newModelVersion: ModelVersion = parseVersion(
        get(action.payload, 'version'),
      )
      return {
        ...state,
        allModelVersions: {
          ...state.allModelVersions,
          [newModelVersion._id]: newModelVersion,
        },
      }
    }
    case getType(modelActions.updateModelVersion.success): {
      const versionId = action.payload.versionId
      const updatedProperties = action.payload.payload
      return {
        ...state,
        allModelVersions: {
          ...state.allModelVersions,
          [versionId]: {
            ...state.allModelVersions[versionId],
            ...updatedProperties,
          },
        },
      }
    }
    case getType(modelActions.setLatestVersion.success):
      versionId = action.payload.versionId
      currLatestVersion = find(
        state.allModelVersions,
        version => (version.latest = true),
      )
      if (!currLatestVersion) {
        return {
          ...state,
          allModelVersions: {
            ...state.allModelVersions,
            [versionId]: {
              ...state.allModelVersions[versionId],
              latest: true,
            },
          },
        }
      }

      return {
        ...state,
        allModelVersions: {
          ...state.allModelVersions,
          [versionId]: {
            ...state.allModelVersions[versionId],
            latest: true,
          },
          [currLatestVersion._id]: {
            ...currLatestVersion,
            latest: false,
          },
        },
      }

    case getType(authActions.loginUser.failure): {
      return initialState
    }
    default:
      return state
  }
}
