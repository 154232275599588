import { DatePicker, Input } from 'antd'
import { DARK_BLUE } from 'common/colors'
import { Spacing } from 'common/stylings'
import Stack from 'components/Stack'
import { Text } from 'components/Typography'
import { Dayjs, isDayjs } from 'dayjs'
import { isEmpty } from 'lodash'
import { RangeValue } from 'rc-picker/lib/interface'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { addSearchFieldFlow } from 'store/restoreVideoState/actions'
import {
  initialFiltersCheckSelector,
  initialInputSearchSelector,
  initialSearchFieldSelector,
} from 'store/restoreVideoState/selectors'
import { RootState } from 'store/rootReducer'

export let searchString: string

const { RangePicker } = DatePicker
const { Search } = Input

type AllProps = StoreProps<typeof mapStateToProps, typeof mapDispatchToProps> &
  Props &
  SearchProps

interface Props {
  setDateRange: (dateString: any) => void
  initialStartDate: Dayjs
  initialEndDate: Dayjs
  clearDates: () => void
}

export interface SearchProps {
  onSearch: (value: string) => void
}

const FilterPanel: React.FC<AllProps> = ({
  setDateRange,
  onSearch,
  initialStartDate,
  initialEndDate,
  clearDates,
  initialInputSearch,
  addSearchField,
  initialFiltersCheck,
  initialSearchField,
}) => {
  const onChange = (
    dateDayjs: RangeValue<Dayjs>,
    dateString: [string, string],
  ) => {
    if (isEmpty(dateDayjs)) {
      clearDates()
      return
    }
    const start = dateDayjs![0]
    const end = dateDayjs![1]
    if (isDayjs(start) && isDayjs(end)) {
      setDateRange([start.toISOString(), end.toISOString()])
      return
    }
    setDateRange(dateString)
  }

  const setSearch = (searchInput: string) => {
    if (searchInput !== '') {
      onSearch(searchInput)
    } else {
      addSearchField('')
      onSearch('')
    }
    searchString = searchInput
  }

  return (
    <Stack gutter={Spacing.LARGE}>
      <div
        style={{
          width: '65%',
          paddingBottom: '20px',
          paddingRight: '30px',
        }}>
        <Stack vertical fillParentWidth gutter={Spacing.SMALL} width="40%">
          <Stack alignItems="center" gutter={Spacing.EXTRA_SMALL}>
            <Text color={DARK_BLUE} fontWeight={600} fontSize={14}>
              Search by date
            </Text>
          </Stack>
          <RangePicker
            defaultValue={[initialStartDate, initialEndDate]}
            onChange={onChange}
            size="large"
            allowClear={true}
          />
        </Stack>
      </div>
      <Stack vertical fillParentWidth gutter={Spacing.SMALL} width="60%">
        <Text color={DARK_BLUE} fontWeight={600} fontSize={14}>
          Search by video
        </Text>
        <Search
          placeholder={
            initialFiltersCheck ? initialSearchField : 'Enter S3 directory..'
          }
          enterButton={true}
          onSearch={value => setSearch(value)}
          size="large"
          allowClear={true}
          disabled={initialFiltersCheck}
        />
      </Stack>
    </Stack>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addSearchField: addSearchFieldFlow,
    },
    dispatch,
  )

const mapStateToProps = (s: RootState) => ({
  initialInputSearch: initialInputSearchSelector(s),
  initialFiltersCheck: initialFiltersCheckSelector(s),
  initialSearchField: initialSearchFieldSelector(s),
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterPanel)
