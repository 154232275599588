import {
  LabelboxDataset,
  LabelboxProject,
  LabelboxProjectRelation,
} from 'common/models'
import { createAsyncAction } from 'typesafe-actions'

export type FrameUploadPayload = {
  projectId: string
  datasetId: string
  frameIds: string[]
}

export const fetchProjectDatasetRelations = createAsyncAction(
  'Fetch_Labelbox_Relations__Request',
  'Fetch_Labelbox_Relations__Success',
  'Fetch_Labelbox_Relations__Failure',
)<{ page: number }, LabelboxProjectRelation[], Error>()

export const fetchLabelboxProjects = createAsyncAction(
  'Fetch_Labelbox_Projects__Request',
  'Fetch_Labelbox_Projects__Success',
  'Fetch_Labelbox_Projects__Failure',
)<void, LabelboxProject[], Error>()

export const fetchLabelboxDatasets = createAsyncAction(
  'Fetch_Labelbox_Datasets__Request',
  'Fetch_Labelbox_Datasets__Success',
  'Fetch_Labelbox_Datasets__Failure',
)<void, LabelboxDataset[], Error>()

export const createLabelboxProject = createAsyncAction(
  'Create_Labelbox_Project__Request',
  'Create_Labelbox_Project__Success',
  'Create_Labelbox_Project__Failure',
)<string, LabelboxProject, Error>()

export const createLabelboxDataset = createAsyncAction(
  'Create_Labelbox_Dataset__Request',
  'Create_Labelbox_Dataset__Success',
  'Create_Labelbox_Dataset__Failure',
)<string, LabelboxDataset, Error>()

// the images are from users' desktop
export const uploadLabelboxImage = createAsyncAction(
  'Upload_Labelbox_Image__Request',
  'Upload_Labelbox_Image__Success',
  'Upload_Labelbox_Image__Failure',
)<FormData, { projectId: string; datasetId: string }, Error>()

// the frames are chosen by the users from the frame tab
// please have a better naming if you have anny new ideas!
export const uploadLabelboxFrame = createAsyncAction(
  'Upload_Labelbox_Frame__Request',
  'Upload_Labelbox_Frame__Success',
  'Upload_Labelbox_Frame__Failure',
)<FrameUploadPayload, { projectId: string; datasetId: string }, Error>()
