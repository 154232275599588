import * as routes from 'router/routes'
import { ActionType, getType } from 'typesafe-actions'

import * as routerActions from './actionCreators'

export type RouterActionType = ActionType<typeof routerActions>

interface RouterState {
  initialWindowLocation: string
}

const initialState: Readonly<RouterState> = {
  initialWindowLocation: routes.MAIN,
}

export default (state = initialState, action: RouterActionType) => {
  switch (action.type) {
    case getType(routerActions.setWindowLocation):
      return {
        initialWindowLocation: action.payload,
      }
    default:
      return state
  }
}
