import React from 'react'

const NotFound: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div>
        <h1 style={{ fontSize: '2rem', textAlign: 'center' }}>404</h1>
        <p style={{ fontSize: '1.5rem', textAlign: 'center' }}>
          Page Not Found
        </p>
      </div>
    </div>
  )
}

export default NotFound
