import styled from 'styled-components'

interface Props {
  height: number
}

const Logo = styled.img<Props>`
  height: ${({ height }) => height + 'px'};
  position: absolute;
`

export default Logo
