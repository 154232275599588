import { Icon } from '@blueprintjs/core'
import { Popover, Select, Tag } from 'antd'
import { Spacing } from 'common/stylings'
import { confirm } from 'components/Modal'
import Stack from 'components/Stack'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { getUserIdOfCurrUser } from 'store/authentication/selectors'
import { RootState } from 'store/rootReducer'
import { editVideoTagFlowIndiv } from 'store/videoSegments/actions'
import {
  getAllTags,
  getVideoAssetsDateRange,
} from 'store/videoSegments/selectors'

import { VideoSegment } from '../../../common/models'

const { Option } = Select

type Props = StoreProps<typeof mapStateToProps, typeof mapDispatchToProps> &
  OwnProps

interface OwnProps {
  tags?: string[]
  videos?: VideoSegment[]
}

const IndividualTagColumn: React.FC<Props> = ({
  tags = [],
  editVideoTag,
  currUserId,
  dateRange,
  allTags,
  videos = [],
}) => {
  const [tagToRemove, setTagToRemove] = React.useState<string | null>(null)
  const [isWarningModalOpen, toggleWarningModalVisible] = React.useState(false)

  const addTag = (newTag: string) => {
    if (tags && !_.isEmpty(newTag) && !tags.includes(newTag)) {
      for (const id of extractVideoIds(videos)) {
        editVideoTag(
          {
            videoId: id,
            tags: [...tags, newTag],
            batch: videos[0].batch,
          },
          currUserId,
          dateRange,
        )
      }
    }
  }

  const extractVideoIds = (videos: VideoSegment[]) => {
    const videoIds: string[] = []
    for (const video in videos) {
      videoIds.push(videos[video]._id)
    }
    return videoIds
  }

  const removeTag = () => {
    for (const id of extractVideoIds(videos)) {
      const newTags = _.filter(tags, tag => tag !== tagToRemove)
      editVideoTag(
        { videoId: id, tags: newTags, batch: videos[0].batch },
        currUserId,
        dateRange,
      )
      setTagToRemove(null)
    }
  }

  const displayWarningModal = () => {
    confirm({
      title: 'Do you want to delete the tag?',
      content: "You can't recover the tag after deleting it.",
      icon: <Icon icon="warning-sign" intent="warning" />,
      onOk: () => {
        removeTag()
        toggleWarningModalVisible(false)
      },
      onCancel: () => toggleWarningModalVisible(false),
    })
  }

  const renderHoverPopoverContent = (tags: string[]) => {
    return (
      <div>
        {tags.map((tag: string) => (
          <Tag key={tag}>{tag}</Tag>
        ))}
      </div>
    )
  }

  const renderTagsOverview = () => {
    const sortedTags = tags.sort(Intl.Collator().compare)
    const size = sortedTags ? sortedTags.length : 0
    const extraTags = sortedTags.slice(3)

    if (size) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              maxWidth: '165px',
            }}>
            {tags.map(
              (tag, index) =>
                index < 3 && (
                  <div key={index}>
                    <Tag
                      style={{
                        whiteSpace: 'normal',
                        maxWidth: '165px',
                      }}>
                      {tag}
                    </Tag>
                  </div>
                ),
            )}
          </div>
          {size > 3 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flexShrink: 0,
                marginRight: '4px',
              }}>
              <Popover
                overlayStyle={{
                  width: 'max-content',
                }}
                content={renderHoverPopoverContent(extraTags)}>
                <Tag>+ {size - 3}</Tag>
              </Popover>
            </div>
          ) : null}
        </div>
      )
    }
    return null
  }

  const renderPopoverContent = () => {
    return (
      <>
        <Stack
          gutter={Spacing.REGULAR}
          vertical
          style={{ minWidth: 150, maxWidth: 150, position: 'relative' }}>
          <Select
            value={tags}
            mode="tags"
            autoFocus
            onSelect={(e: string) => addTag(e)}
            onDeselect={(e: string) => {
              setTagToRemove(e)
              toggleWarningModalVisible(true)
            }}>
            {allTags.map((tag: string) => (
              <Option key={tag} value={tag}>
                {tag}
              </Option>
            ))}
          </Select>
        </Stack>
        {isWarningModalOpen && displayWarningModal()}
      </>
    )
  }

  return (
    <Stack justifyContent={'flex-start'} gutter={Spacing.SMALL}>
      {renderTagsOverview()}
      <Popover content={renderPopoverContent()} trigger="click">
        <Icon
          style={{
            cursor: 'pointer',
            position: 'absolute',
            right: 0,
            alignSelf: 'center',
          }}
          icon="edit"
        />
      </Popover>
    </Stack>
  )
}

const mapStateToProps = (s: RootState) => ({
  currUserId: getUserIdOfCurrUser(s),
  dateRange: getVideoAssetsDateRange(s),
  allTags: getAllTags(s),
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      editVideoTag: editVideoTagFlowIndiv,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(IndividualTagColumn)
