import _ from 'lodash'

/**
 * @desc: a bunch of utility functions that checks for validity and returns the error state string
 */

export const composeValidators = (...validators: any[]) => (value: any) => {
  return validators.reduce(
    (error: any, validator: any) => error || validator(value),
    undefined,
  )
}

export const required = (value: any) =>
  _.isEmpty(value) ? 'Required.' : undefined

export const mustBeNumber = (value: any) =>
  _.isNumber(value) ? undefined : 'Must be number.'

export const mustBeEmail = (value: any) => {
  const re = /\S+@\S+\.\S+/
  return re.test(value) ? undefined : 'Must be in email format.'
}

export const mustBe8Char = (value: any) => {
  const password = value || ''
  return password.length >= 8
    ? undefined
    : 'Password must at least be 8 characters long.'
}
