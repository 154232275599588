import AppLayout from 'components/Layout'
import ModalContainer from 'components/Modal/ModalContainer'
import React from 'react'
import { Provider as StoreProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist-kaliber/integration/react'
import Router from 'router'
import store, { persistor } from 'store'

const App: React.FC = () => {
  return (
    <StoreProvider store={store}>
      <BrowserRouter>
        <PersistGate persistor={persistor}>
          <ModalContainer />
          <AppLayout>
            <Router />
          </AppLayout>
        </PersistGate>
      </BrowserRouter>
    </StoreProvider>
  )
}

export default App
