import styled from 'styled-components'

const BodyLayout = styled.div<{}>`
  padding-top: 130px;
  padding-bottom: 60px;
  padding-left: 30px;
  padding-right: 30px
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  width: 1024px;
  @media (min-width: 1152px) {
    width: 1024px;
  }
  @media (min-width: 1440.1px) {
    width: 80%;
  }
  @media (max-width: 1440px) {
    width: 100%;
  }
`

export default BodyLayout
