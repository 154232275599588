import axios from 'axios'
import { get } from 'lodash'
import { Dispatch } from 'redux'

import {
  fetchAllUsersInfo,
  fetchCurrUserInfo,
  setCurrentUser,
} from './actionCreators'


export const fetchCurrUserInfoFlow = (userId: string) => (
  dispatch: Dispatch,
) => {
  dispatch(fetchCurrUserInfo.request())
  axios
    .get(`/user/info?user_id=${userId}`)
    .then(res => {
      dispatch(fetchCurrUserInfo.success())
      dispatch(setCurrentUser(get(res.data, 'data')))
    })
    .catch(err => dispatch(fetchCurrUserInfo.failure(err)))
}

export const fetchAllUsersInfoFlow = () => (dispatch: Dispatch) => {
  dispatch(fetchAllUsersInfo.request())

  // To be used for debugging - uncomment this to see the parameters of the request
  // axios.interceptors.request.use(request => {
  //   console.log('Starting Request', JSON.stringify(request, null, 2))
  //   return request
  // })

  axios
    .get('/user/all')
    .then(res => {
      dispatch(fetchAllUsersInfo.success(get(res.data, 'data')))
    })
    .catch(err => {
      dispatch(fetchAllUsersInfo.failure(err))
    })
}

