import { Doctor } from 'common/models'
import { keyBy } from 'lodash'
import { ActionType, getType } from 'typesafe-actions'

import * as doctorActions from './actionCreators'

export type DoctorActionType = ActionType<typeof doctorActions>

interface DoctorState {
  doctors: Dict<Doctor>
}

const initialState: Readonly<DoctorState> = {
  doctors: {},
}

export default (state = initialState, action: DoctorActionType) => {
  switch (action.type) {
    case getType(doctorActions.fetchAllDoctorsInfo.success): {
      const doctors: Dict<Doctor> = keyBy(action.payload, '_id')
      return {
        ...state,
        doctors,
      }
    }
    case getType(doctorActions.createNewDoctor.success): {
      const { _id, fullname, email } = action.payload
      return {
        ...state,
        doctors: {
          ...state.doctors,
          [_id]: { _id, fullname, email },
        },
      }
    }
    default:
      return state
  }
}
