import { Icon } from '@blueprintjs/core'
import { BLACK,GREEN } from 'common/colors'
import { Spacing } from 'common/stylings'
import Modal from 'components/Modal'
import Stack from 'components/Stack'
import Text from 'components/Typography'
import React from 'react'

interface Props {
  isOpen: boolean
  message: string
  hideModal: (...args: any) => void
}

const SuccessModal: React.FC<Props> = ({ isOpen, message, hideModal }) => (
  <Modal
    visible={isOpen}
    onCancel={() => hideModal({ isOpen: false })}
    footer={null}>
    <Stack vertical gutter={Spacing.MEDIUM}>
      <Stack alignItems="center" gutter={Spacing.REGULAR}>
        <Icon
          icon="confirm"
          style={{ paddingTop: 5, color: GREEN, fontSize: 24 }}
        />
        <Text
          style={{ paddingLeft: 8 }}
          fontWeight="bold"
          color={BLACK}
          fontSize={18}>
          Success!
        </Text>
      </Stack>
      <Text
        style={{
          paddingTop: 10,
          fontSize: 15,
          paddingRight: 24,
        }}
        color={BLACK}
        fontSize={14}>
        {message}
      </Text>
    </Stack>
  </Modal>
)

export default SuccessModal
