import { Select } from 'antd'
import Modal from 'components/Modal'
import Text from 'components/Typography'
import _ from 'lodash'
import React, { FocusEvent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { getUserIdOfCurrUser } from 'store/authentication/selectors'
import { RootState } from 'store/rootReducer'
import { bulkAddRegionFlow } from 'store/videoSegments/actions'
import {
  getAllRegions,
  getVideoAssetsDateRange,
} from 'store/videoSegments/selectors'

const { Option } = Select

type Props = OwnProps &
  StoreProps<typeof mapStateToProps, typeof mapDispatchToProps>

interface OwnProps {
  isOpen: boolean
  videoIds: string[]
  hideModal: (...args: any) => void
}

const AddRegionModal: React.FC<Props> = ({
  isOpen,
  hideModal,
  videoIds,
  allRegions,
  bulkAddRegion,
}) => {
  const [newRegion, setRegion] = React.useState<string>('')

  const submit = () => {
    if (!_.isEmpty(newRegion)) {
      bulkAddRegion({ videoIds, region: newRegion })
    }
  }

  return (
    <Modal
      visible={isOpen}
      onCancel={() => hideModal({ isOpen: false })}
      onOk={submit}
    >
      <Text>Add Region</Text>
      <Select
        autoFocus
        showSearch
        value={newRegion}
        style={{ width: '100%' }}
        showArrow={false}
        onSearch={(e: string) => setRegion(e)}
        notFoundContent={null}
        onSelect={(e: string) => setRegion(e)}
        onBlur={(e: FocusEvent<HTMLElement>) =>
          setRegion(_.get(e.target, 'value', ''))
        }
        placeholder="Enter region..."
      >
        {showNewOption(newRegion, allRegions) && (
          <Option key={0} value={newRegion}>
            {newRegion}
          </Option>
        )}
        {allRegions.map((region: string) => (
          <Option key={region} value={region}>
            {region}
          </Option>
        ))}
      </Select>
    </Modal>
  )
}

const showNewOption = (val: string | undefined, allRegions: string[]) => {
  if (!val || val.trim().length === 0) {
    return false
  }
  return !allRegions.includes(val)
}

const mapStateToProps = (s: RootState) => ({
  currUserId: getUserIdOfCurrUser(s),
  dateRange: getVideoAssetsDateRange(s),
  allRegions: getAllRegions(s),
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ bulkAddRegion: bulkAddRegionFlow }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AddRegionModal)
