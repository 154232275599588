import { createSelector } from 'reselect'
import { RootState } from 'store/rootReducer'

const filterSelector = (s: RootState) => s.restoreVideo

export const initialFilterSelector = createSelector(
  filterSelector,
  s => s.initialFilter,
)

export const initialSearchFieldSelector = createSelector(
  filterSelector,
  s => s.initialSearchField,
)

export const initialVideoSelector = createSelector(
  filterSelector,
  s => s.initialVideo,
)

export const initialDoctorIDsSelector = createSelector(
  filterSelector,
  s => s.initialDoctorID,
)

export const initialInputSearchSelector = createSelector(
  filterSelector,
  s => s.initialInputSearch,
)

export const initialUserFilterSelector = createSelector(
  filterSelector,
  s => s.initialUserFilter,
)

export const initialFiltersCheckSelector = createSelector(
  filterSelector,
  s => s.initialFiltersCheck,
)
