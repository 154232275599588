import { Menu as AntMenu } from 'antd'
import { DARK_BLUE, PALE_DARK_BLUE } from 'common/colors'
import styled from 'styled-components'

const AntMenuItem = AntMenu.Item

interface Props {
  fontWeight?: string
}

const Menu = styled(AntMenu)<Props>`
  && {
    color: ${PALE_DARK_BLUE};
    ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
    width: 100%;
    height: 100%;
  }
  .ant-menu-item {
    color: ${PALE_DARK_BLUE};
  }
  .ant-menu-item-selected {
    color: ${DARK_BLUE};
  }
`

export const MenuItem = styled(AntMenuItem)<Props>`
  && {
    ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
    &: hover {
      color: ${DARK_BLUE};
    }
    height: 70%;
  }
  .ant-menu-item-selected {
    color: ${DARK_BLUE};
  }
`

export default Menu
