import { Doctor } from 'common/models'
import { createAsyncAction } from 'typesafe-actions'

export const fetchAllDoctorsInfo = createAsyncAction(
  'Fetch_All_Doctors_Info__Request',
  'Fetch_All_Doctors_Info__Success',
  'Fetch_All_Doctors_Info__Failure',
)<void, Doctor[], Error>()

export const createNewDoctor = createAsyncAction(
  'Create_New_Doctor__Request',
  'Create_New_Doctor__Success',
  'Create_New_Doctor__Failure',
)<{ fullname: string; email: string }, Doctor, Error>()
