import { Spin } from 'antd'
import { Empty } from 'antd'
import { FilterValue } from 'antd/lib/table/interface'
import { Spacing } from 'common/stylings'
import Stack from 'components/Stack'
import dayjs from 'dayjs'
import { forEach, isEmpty } from 'lodash'
import React, { FC, ReactText } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import {
  fetchLabelboxDatasetsFlow,
  fetchLabelboxProjectsFlow,
} from 'store/augmentedMasks/actions'
import { fetchAllDoctorsInfoFlow } from 'store/doctor/actions'
import { getAllDoctors } from 'store/doctor/selectors'
import { fetchExportLogsFlow } from 'store/exportLogs/actions'
import { getExportLogs } from 'store/exportLogs/selectors'
import { addSearchFieldFlow } from 'store/restoreVideoState/actions'
import {
  initialDoctorIDsSelector,
  initialFiltersCheckSelector,
  initialInputSearchSelector,
  initialUserFilterSelector,
} from 'store/restoreVideoState/selectors'
import { RootState } from 'store/rootReducer'
import {
  createErrorSelector,
  createLoadingSelector,
  createSuccessSelector,
} from 'store/status/selectors'
import { fetchTrainingLogsFlow } from 'store/trainingLogs/actions'
import { getTrainingLogs } from 'store/trainingLogs/selectors'
import {
  batchUploadToEncord,
  batchUploadToSuperAnnotate,
  changeVideoAssetDateRange,
} from 'store/videoSegments/actionCreators'
import {
  fetchVideoBatchesFilterFlow,
  fetchVideoSegmentsFilterFlow,
  getVideoFilterFieldsFlow,
} from 'store/videoSegments/actions'
import {
  getBatchList,
  getTotalBatchesNumber,
  getTotalVideosNumber,
  getTotalVideosSentNumber,
  getVideoFilterFields,
  getVideoList,
} from 'store/videoSegments/selectors'
import { getISODate } from 'utilities/datetime'

import FilterPanel from '../videoAssets/FilterPanel'
import NestedVideoTable from '../videoAssets/VideoTable/NestedVideoTable'

type Props = StoreProps<typeof mapStateToProps, typeof mapDispatchToProps>

let doctorIDs: string[]
let userFilters: any
let inputSearch: string

const SMEVideoAssets: FC<Props> = ({
  videos,
  fetchVideoSegmentsWithFilter,
  fetchVideoBatchesWithFilter,
  changeDateRange,
  getFilterFields,
  filterFields,
  fetchAllDoctors,
  allDoctors,
  fetchLabelboxProjects,
  fetchLabelboxDatasets,
  totalVideos,
  videosByBatch,
  numVidoesSent,
  totalBatches,
  addSearchField,
  initialDoctorID,
  initialInputSearch,
  initialUserFilter,
  initialFiltersCheck,
  isBatchUploadedToSuperAnnotate,
  isBatchUploadingToSuperAnnotate,
  batchUploadToSuperAnnotateError,
  isBatchUploadedToEncord,
  isBatchUploadingToEncord,
  batchUploadToEncordError,
  fetchExportLogs,
  exportLogs,
  fetchTrainingLogs,
  trainingLogs,
}) => {
  const initialEndDate = dayjs(new Date())
  const initialStartDate = dayjs('2019-01-01')
  const [currentPage, setPageNumber] = React.useState(1)
  const [dateRange, setDateRange] = React.useState([
    getISODate(initialStartDate),
    getISODate(initialEndDate),
  ])
  // Even though filters above includes doctor, it only saves doctor names
  // As a doctor may have multiple emails, we need to retrieve the ids for the respective emails
  // Ant design table is wonky so I have used a separate state here
  // Please refactor it if you believe there is a better way
  const [selectedDoctorIds, onDoctorIdsChange] = React.useState<string[]>([])
  const [keywords, setSearchField] = React.useState('')
  const [filters, onFiltersChange] = React.useState<
    | Record<string, ReactText[] | null>
    | Record<string, FilterValue | null>
    | null
  >(null)
  const loadLogs = () => {
    fetchExportLogs()
    fetchTrainingLogs()
  }

  React.useEffect(loadLogs, [fetchExportLogs, fetchTrainingLogs])

  const toggleParameters = () => {
    if (initialDoctorID.length === 0) {
      doctorIDs = selectedDoctorIds
    } else {
      doctorIDs = initialDoctorID
    }

    if (initialUserFilter === null) {
      userFilters = filters
    } else {
      userFilters = initialUserFilter
    }

    if (initialInputSearch === '') {
      inputSearch = keywords
    } else {
      inputSearch = initialInputSearch
    }
  }

  toggleParameters()

  // This is for togggling the inclusive and exclusive search in tags filter
  const [isInclusiveSearch, toggleSearchPattern] = React.useState(true)

  const [smeS3BucketName, setSMES3BucketName] = React.useState("sme-media");
  React.useEffect(() => {
    const authDomain = process.env.REACT_APP_AUTH0_DOMAIN || "";

    if (authDomain.includes("demo")) {
      setSMES3BucketName("sme-media-demo");
    }
  }, []);

  const getPayload = () => {
    const payload = {}
    if (!isEmpty(inputSearch)) {
      payload['keywords'] = inputSearch
      if (keywords !== '') {
        addSearchField(keywords)
      }
    }
    if (!isEmpty(dateRange)) {
      const startDate = dateRange[0]
      const endDate = dateRange[1]
      payload['startDate'] = startDate
      payload['endDate'] = endDate
      changeDateRange({ startDate, endDate })
    }
    // filters only saves doctors' names and not id
    forEach(userFilters, (val, key) => {
      if (key !== 'doctor' && val) {
        payload[key] = val
      }
    })
    payload['doctor'] = doctorIDs
    payload['s3BucketName'] = smeS3BucketName

    return payload
  }

  const clearDates = () => {
    setDateRange([])
    setPageNumber(1)
  }

  const fetchVideoSegmentsByFilterPayload = () => {
    const payload = getPayload()
    getFilterFields(payload)
    fetchVideoSegmentsWithFilter(currentPage, payload)
  }

  const fetchVideoBatchesByFilterPayload = () => {
    const payload = getPayload()
    getFilterFields(payload)
    fetchVideoBatchesWithFilter(currentPage, payload)
  }

  const loadInitialData = () => {
    fetchAllDoctors()
    fetchLabelboxProjects()
    fetchLabelboxDatasets()
  }

  React.useEffect(fetchVideoSegmentsByFilterPayload, [
    currentPage,
    dateRange,
    inputSearch,
    userFilters,
    doctorIDs,
    isInclusiveSearch,
  ])
  React.useEffect(fetchVideoBatchesByFilterPayload, [
    currentPage,
    dateRange,
    inputSearch,
    userFilters,
    doctorIDs,
    isInclusiveSearch,
  ])
  React.useEffect(loadInitialData, [])

  const [loadTimeoutExceeded, setLoadTimeoutExceeded] = React.useState(false)

  React.useEffect(() => {
    if (isEmpty(videosByBatch) && inputSearch === '' && !userFilters) {
      const timeoutId = setTimeout(() => {
        setLoadTimeoutExceeded(true)
      }, 10000)
      return () => clearTimeout(timeoutId)
    } else {
      return
    }
  }, [videosByBatch, inputSearch, userFilters])

  if (isEmpty(videosByBatch) && inputSearch === '' && !userFilters) {
    if (loadTimeoutExceeded) {
      return (
        <div
          style={{
            textAlign: 'center',
            margin: '250px',
          }}>
          <Empty description="No videos found within this time frame. Please refresh the page." />
        </div>
      )
    } else {
      return (
        <div
          style={{
            textAlign: 'center',
            margin: '250px',
          }}>
          <Spin />
        </div>
      )
    }
  }

  const handleSearch = (searchTerm: string) => {
    setSearchField(searchTerm)
    setPageNumber(1)
  }

  return (
    <Stack vertical gutter={Spacing.MEDIUM}>
      <FilterPanel
        initialStartDate={initialStartDate}
        initialEndDate={initialEndDate}
        setDateRange={setDateRange}
        onSearch={handleSearch}
        clearDates={clearDates}
      />
      <NestedVideoTable
        filteredVideos={videos}
        filterFields={filterFields}
        videoBatches={videosByBatch}
        totalBatches={totalBatches}
        allDoctors={allDoctors}
        numVidoesSent={numVidoesSent}
        totalVideos={totalVideos}
        currentPage={currentPage}
        setPageNumber={setPageNumber}
        filters={userFilters}
        onFiltersChange={onFiltersChange}
        onDoctorIdsChange={onDoctorIdsChange}
        isInclusiveSearch={isInclusiveSearch}
        toggleSearchPattern={toggleSearchPattern}
        onSearch={handleSearch}
        initialFiltersCheck={initialFiltersCheck}
        isBatchUploadingToSuperAnnotate={isBatchUploadingToSuperAnnotate}
        isBatchUploadedToSuperAnnotate={isBatchUploadedToSuperAnnotate}
        getBatchUploadToSuperAnnotateErrorSelector={
          batchUploadToSuperAnnotateError
        }
        isBatchUploadingToEncord={isBatchUploadingToEncord}
        isBatchUploadedToEncord={isBatchUploadedToEncord}
        getBatchUploadToEncordErrorSelector={batchUploadToEncordError}
        exportLogs={exportLogs}
        trainingLogs={trainingLogs}
        isSMEVideoAssetsPage={true}
        isDicomAssetsPage={false}
      />
    </Stack>
  )
}

const getBatchUploadToEncordSuccessSelector = createSuccessSelector([
  batchUploadToEncord.success,
])

const getBatchUploadToEncordLoadingSelector = createLoadingSelector([
  batchUploadToEncord.request,
])

const getBatchUploadToEncordErrorSelector = createErrorSelector([
  batchUploadToEncord.failure,
])

const getBatchUploadToSuperAnnotateSuccessSelector = createSuccessSelector([
  batchUploadToSuperAnnotate.success,
])

const getBatchUploadToSuperAnnotateLoadingSelector = createLoadingSelector([
  batchUploadToSuperAnnotate.request,
])

const getBatchUploadToSuperAnnotateErrorSelector = createErrorSelector([
  batchUploadToSuperAnnotate.failure,
])

const mapStateToProps = (state: RootState) => ({
  videos: getVideoList(state),
  videosByBatch: getBatchList(state),
  totalVideos: getTotalVideosNumber(state),
  totalBatches: getTotalBatchesNumber(state),
  filterFields: getVideoFilterFields(state),
  allDoctors: getAllDoctors(state),
  numVidoesSent: getTotalVideosSentNumber(state),
  initialDoctorID: initialDoctorIDsSelector(state),
  initialInputSearch: initialInputSearchSelector(state),
  initialUserFilter: initialUserFilterSelector(state),
  initialFiltersCheck: initialFiltersCheckSelector(state),
  isBatchUploadingToSuperAnnotate:
    getBatchUploadToSuperAnnotateLoadingSelector(state),
  isBatchUploadedToSuperAnnotate:
    getBatchUploadToSuperAnnotateSuccessSelector(state),
  batchUploadToSuperAnnotateError:
    getBatchUploadToSuperAnnotateErrorSelector(state),
  isBatchUploadingToEncord: getBatchUploadToEncordLoadingSelector(state),
  isBatchUploadedToEncord: getBatchUploadToEncordSuccessSelector(state),
  batchUploadToEncordError: getBatchUploadToEncordErrorSelector(state),
  exportLogs: getExportLogs(state),
  trainingLogs: getTrainingLogs(state),
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchVideoSegmentsWithFilter: fetchVideoSegmentsFilterFlow,
      fetchVideoBatchesWithFilter: fetchVideoBatchesFilterFlow,
      changeDateRange: changeVideoAssetDateRange,
      getFilterFields: getVideoFilterFieldsFlow,
      fetchAllDoctors: fetchAllDoctorsInfoFlow,
      fetchLabelboxProjects: fetchLabelboxProjectsFlow,
      fetchLabelboxDatasets: fetchLabelboxDatasetsFlow,
      addSearchField: addSearchFieldFlow,
      fetchExportLogs: fetchExportLogsFlow,
      fetchTrainingLogs: fetchTrainingLogsFlow,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(SMEVideoAssets)
