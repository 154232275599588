import { Dispatch } from 'redux'

import {
  addDoctorIDs,
  addFilters,
  addFiltersCheck,
  addInputSearch,
  addSearchField,
  addUserFilters,
  addVideo,
} from './actionCreators'

export const addFiltersFlow = (filters: string) => (dispatch: Dispatch) =>
  dispatch(addFilters(filters))

export const addSearchFieldFlow = (searchField: string) => (
  dispatch: Dispatch,
) => dispatch(addSearchField(searchField))

export const addVideoFlow = (video: string) => (dispatch: Dispatch) =>
  dispatch(addVideo(video))

export const addDoctorIDsFlow = (doctorIDs: string[]) => (dispatch: Dispatch) =>
  dispatch(addDoctorIDs(doctorIDs))

export const addInputSearchFlow = (inputSearch: string) => (
  dispatch: Dispatch,
) => dispatch(addInputSearch(inputSearch))

export const addUserFiltersFlow = (
  filters: Record<string, React.ReactText[] | null> | null,
) => (dispatch: Dispatch) => dispatch(addUserFilters(filters))

export const addFiltersCheckFlow = (filtersCheck: boolean) => (
  dispatch: Dispatch,
) => dispatch(addFiltersCheck(filtersCheck))
