import axios from 'axios'
import { Dispatch } from 'redux'

import { getTrainingLogs } from './actionCreators'

export const fetchTrainingLogsFlow = () => (dispatch: Dispatch) => {
  dispatch(getTrainingLogs.request())
  axios
    .get('/logs/training_logs')
    .then(res => {
      const TrainingLogs: any = res.data.training_logs
      dispatch(getTrainingLogs.success(TrainingLogs))
    })
    .catch(err => dispatch(getTrainingLogs.failure(err)))
}
