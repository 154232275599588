import { VideoBatch, VideoSegment } from 'common/models'
import _ from 'lodash'
import { createSelector } from 'reselect'
import { getAllDoctors } from 'store/doctor/selectors'
import { RootState } from 'store/rootReducer'
import {
  getAllMonthsFromDateRange,
  getAllWeeksFromDateRange,
  getDifferenceInDays,
  getMonthAndYearString,
  getWeekRangeString,
} from 'utilities/datetime'

const THREE_MONTHS_IN_DAYS = 120

const videoSegmentSelector = (state: RootState) => state.videoSegment

export const getVideoList = createSelector(
  [videoSegmentSelector],
  videoSegment => {
    const filteredVideoList = _.orderBy(
      videoSegment.filteredVideoList,
      'upload_date',
      'desc',
    )
    const videoDict: Dict<VideoSegment> = _.keyBy(filteredVideoList, '_id')
    return videoDict
  },
)

export const getBatchList = createSelector(
  [videoSegmentSelector],
  videoSegment => {
    const filteredVideoList = _.orderBy(
      videoSegment['batchDict'],
      'name',
      'desc',
    )
    const batchDict: Dict<VideoBatch> = _.keyBy(filteredVideoList, 'name')
    const batchList = []
    for (const item in batchDict) {
      const datasetTagsArray: string[][] = []
      let commonDatasetTags: string[] = []

      const classificationTagsArray: string[][] = []
      let commonClassificationTags: string[] = []

      for (let i = 0; i < batchDict[item].videos.length; i++) {
        datasetTagsArray.push(batchDict[item].videos[i].tags)
      }

      for (let i = 0; i < batchDict[item].videos.length; i++) {
        classificationTagsArray.push(
          batchDict[item].videos[i].classificationTags,
        )
      }

      if (datasetTagsArray !== undefined && datasetTagsArray.length > 0) {
        commonDatasetTags = datasetTagsArray.reduce(
          (a: string[], b: string[]) =>
            a === undefined || null
              ? (a = [])
              : a.filter((c: string) =>
                  b === undefined || null
                    ? (b = [])
                    : c === undefined || null
                    ? (c = '')
                    : b.includes(c),
                ),
        )
      }

      if (
        classificationTagsArray !== undefined &&
        classificationTagsArray.length > 0
      ) {
        commonClassificationTags = classificationTagsArray.reduce(
          (a: string[], b: string[]) =>
            a === undefined || null
              ? (a = [])
              : a.filter((c: string) =>
                  b === undefined || null
                    ? (b = [])
                    : c === undefined || null
                    ? (c = '')
                    : b.includes(c),
                ),
        )
      }

      const batch: VideoBatch = {
        batch: item,
        videos: batchDict[item].videos,
        upload_date: batchDict[item].videos[0].upload_date,
        region: batchDict[item].videos[0].region,
        doctor: batchDict[item].videos[0].doctor,
        tags: commonDatasetTags,
        classificationTags: commonClassificationTags,
      }
      batchList.push(batch)
    }
    return _.keyBy(batchList, 'batch')
  },
)

const getStartDate = createSelector(
  [videoSegmentSelector],
  videoSegment => videoSegment.startDate,
)

const getEndDate = createSelector(
  [videoSegmentSelector],
  videoSegment => videoSegment.endDate,
)

export const getVideoAssetsDateRange = createSelector(
  [getStartDate, getEndDate],
  (startDate, endDate) => ({ startDate, endDate }),
)

export const getTotalVideosNumber = createSelector(
  [videoSegmentSelector],
  videoSegment => videoSegment.total,
)

export const getTotalVideosSentNumber = createSelector(
  [videoSegmentSelector],
  videoSegment => videoSegment.number_videos_sent,
)

export const getVideoFilterFields = createSelector(
  [videoSegmentSelector],
  videoSegment => videoSegment.filterFields,
)

export const getAllTags = createSelector(
  [getVideoFilterFields],
  filterFields => filterFields.tags && filterFields.tags.sort(),
)

export const getAllClassificationTags = createSelector(
  [getVideoFilterFields],
  filterFields =>
    filterFields.classificationTags && filterFields.classificationTags.sort(),
)

export const getAllRegions = createSelector(
  [getVideoFilterFields],
  filterFields => filterFields.regions && filterFields.regions.sort(),
)

export const groupDoctorsByDistinctName = createSelector(
  [getVideoFilterFields, getAllDoctors],
  (fields, allDoctors) => {
    const doctorIds = fields.doctors
    return _.groupBy(doctorIds, id => _.get(allDoctors[id], ['fullname'], ''))
  },
)

export const getVideoSummaryByRegion = createSelector(
  [getVideoList],
  videos => {
    const groupedVideosByRegion = _.groupBy(videos, video => {
      return _.isEmpty(video.region) ? undefined : video.region
    })
    return _.mapValues(groupedVideosByRegion, videos => _.size(videos))
  },
)

export const getVideoSummaryByDoctor = createSelector(
  [getVideoList, getAllDoctors],
  (videos, doctors) => {
    const groupedVideosByDoctor = _.groupBy(videos, video => {
      const doctorId = video.doctor
      if (_.isEmpty(doctorId)) {
        return undefined
      }
      return _.get(doctors[doctorId], 'fullname', '')
    })
    return _.mapValues(groupedVideosByDoctor, videos => _.size(videos))
  },
)

export const getVideoSummaryByDateRange = createSelector(
  [getVideoList, getStartDate, getEndDate],
  (videos, startDate, endDate) => {
    const dayDiff = getDifferenceInDays(startDate, endDate)
    const getGroupedVideos =
      dayDiff < THREE_MONTHS_IN_DAYS
        ? getGroupedVideosByWeeks
        : getGroupedVideosByMonths
    const groupedVideos = getGroupedVideos(startDate, endDate, _.values(videos))

    return _.mapValues(groupedVideos, videos => _.size(videos))
  },
)

const getGroupedVideosByWeeks = (
  startDate: string,
  endDate: string,
  videos: VideoSegment[],
) => {
  const allWeeks = getAllWeeksFromDateRange(startDate, endDate)
  const groupedVideos = _.groupBy(videos, video =>
    getWeekRangeString(video.upload_date),
  )

  return _.reduce(
    allWeeks,
    (videos, key) => ({
      ...videos,
      [key]: groupedVideos[key] || {},
    }),
    {},
  )
}

const getGroupedVideosByMonths = (
  startDate: string,
  endDate: string,
  videos: VideoSegment[],
) => {
  const allMonths = getAllMonthsFromDateRange(startDate, endDate)
  const groupedVideos = _.groupBy(videos, video =>
    getMonthAndYearString(video.upload_date),
  )

  return _.reduce(
    allMonths,
    (videos, key) => ({
      ...videos,
      [key]: groupedVideos[key] || {},
    }),
    {},
  )
}

export const getGroupedVideosByUploadBatch = createSelector(
  [getVideoList],
  videos => {
    const groupedVideos = _.groupBy(_.values(videos), video => video.batch)
    const keys = _.keys(groupedVideos)
    const groupedBatch = [] as VideoBatch[]
    for (const key in keys) {
      const batch: VideoBatch = {
        batch: keys[key],
        videos: _.get(groupedVideos, keys[key]),
        upload_date: _.get(groupedVideos, keys[key])[0].upload_date,
        region: _.get(groupedVideos, keys[key])[0].region,
        doctor: _.get(groupedVideos, keys[key])[0].doctor,
        tags: getVideoTags(_.get(groupedVideos, keys[key])),
      }
      groupedBatch.push(batch)
    }

    const batchDict: Dict<VideoBatch> = _.keyBy(groupedBatch, 'batch')

    return batchDict
  },
)

const getVideoTags = (videos: VideoSegment[]) => {
  const tags: string[] = []
  for (const i in videos) {
    const videoTags: string[] = videos[i].tags
    for (const tag in videoTags) {
      //TODO Doublecheck this if statement to make sure it is correct.
      if (tags.find(t => t !== videoTags[tag])) {
        tags.push(videoTags[tag])
      }
    }
  }
  return tags
}

export const getTotalBatchesNumber = createSelector(
  [videoSegmentSelector],
  videoSegment => {
    return videoSegment.total_number_of_batches
  },
)
