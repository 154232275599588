import {
  LabelboxDataset,
  LabelboxProject,
  LabelboxProjectRelation,
} from 'common/models'
import { findIndex,keyBy } from 'lodash'
import { ActionType, getType } from 'typesafe-actions'

import * as augmentedMasksActions from './actionCreators'

export type AugmentedMasksActionType = ActionType<typeof augmentedMasksActions>

interface AugmentedMasksState {
  relations: Dict<LabelboxProjectRelation>
  projects: Dict<LabelboxProject>
  totalProjects: number
  datasets: Dict<LabelboxDataset>
  totalDatasets: number
  newProjectId: string
  newDatasetId: string
}

const initialState: Readonly<AugmentedMasksState> = {
  relations: {},
  projects: {},
  totalProjects: 0,
  datasets: {},
  totalDatasets: 0,
  newProjectId: '',
  newDatasetId: '',
}

export default (state = initialState, action: AugmentedMasksActionType) => {
  switch (action.type) {
    case getType(augmentedMasksActions.fetchProjectDatasetRelations.success): {
      const relations: LabelboxProjectRelation[] = action.payload
      const relationsDict = keyBy(relations, 'id')
      return {
        ...state,
        relations: {
          ...state.relations,
          ...relationsDict,
        },
      }
    }

    case getType(augmentedMasksActions.fetchLabelboxProjects.success): {
      const projects: LabelboxProject[] = action.payload
      const updatedProjects = { ...state.projects, ...keyBy(projects, 'id') }
      const totalProjects = Object.keys(updatedProjects).length
      return {
        ...state,
        projects: updatedProjects,
        totalProjects,
      }
    }

    case getType(augmentedMasksActions.fetchLabelboxDatasets.success): {
      const datasets: LabelboxDataset[] = action.payload
      const updatedDatasets = { ...state.datasets, ...keyBy(datasets, 'id') }
      const totalDatasets = Object.keys(updatedDatasets).length
      return {
        ...state,
        datasets: updatedDatasets,
        totalDatasets,
      }
    }

    case getType(augmentedMasksActions.createLabelboxProject.success): {
      const project: LabelboxProject = action.payload
      return {
        ...state,
        projects: {
          [project.id]: project,
          ...state.projects,
        },
        newProjectId: project.id,
        totalProjects: state.totalProjects + 1,
      }
    }

    case getType(augmentedMasksActions.createLabelboxDataset.success): {
      const dataset: LabelboxDataset = action.payload
      return {
        ...state,
        datasets: {
          [dataset.id]: dataset,
          ...state.datasets,
        },
        newDatasetId: dataset.id,
        totalDatasets: state.totalDatasets + 1,
      }
    }

    case getType(augmentedMasksActions.uploadLabelboxFrame.success):
    case getType(augmentedMasksActions.uploadLabelboxImage.success): {
      const projectId: string = action.payload.projectId
      const datasetId: string = action.payload.datasetId
      const project: LabelboxProject = state.projects[projectId]
      const dataset: LabelboxDataset = state.datasets[datasetId]
      const relation: LabelboxProjectRelation = state.relations[projectId]
      const { id, name, updatedAt, createdAt } = project
      if (!relation) {
        return {
          ...state,
          relations: {
            ...state.relations,
            [projectId]: {
              id,
              name,
              updatedAt,
              createdAt,
              datasets: [dataset],
            },
          },
        }
      }

      const foundIndex = findIndex(relation.datasets, ds => ds.id === datasetId)
      if (foundIndex > -1) {
        return state
      }

      return {
        ...state,
        relations: {
          ...state.relations,
          [projectId]: {
            ...relation,
            datasets: [...relation.datasets, dataset],
          },
        },
      }
    }

    default:
      return state
  }
}
