import { Select } from 'antd'
import { LabeledValue } from 'antd/lib/select'
import Modal from 'components/Modal'
import Text from 'components/Typography'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { getUserIdOfCurrUser } from 'store/authentication/selectors'
import { RootState } from 'store/rootReducer'
import { bulkAddTagsFlow } from 'store/videoSegments/actions'
import {
  getAllClassificationTags,
  getAllTags,
  getVideoAssetsDateRange,
} from 'store/videoSegments/selectors'

const { Option } = Select

type Props = OwnProps &
  StoreProps<typeof mapStateToProps, typeof mapDispatchToProps>

interface OwnProps {
  isOpen: boolean
  videoIds: string[]
  hideModal: (...args: any) => void
}

const AddTagModal: React.FC<Props> = ({
  isOpen,
  hideModal,
  videoIds,
  allTags,
  bulkAddTags,
  allClassificationTags,
}) => {
  const [newTags, setTags] = React.useState<string[]>([])

  const onTagSelect = (val: string | number | LabeledValue) => {
    const newTag = val.toString()
    if (!newTags.includes(newTag)) {
      setTags([...newTags, newTag])
    }
  }

  const onTagDeselect = (val: string | number | LabeledValue) => {
    const tagToRemove = val.toString()
    const filteredTags = _.filter(newTags, tag => tag !== tagToRemove)
    setTags(filteredTags)
  }

  const submit = () => {
    if (!_.isEmpty(newTags)) {
      bulkAddTags({ videoIds, tags: newTags, classificationTags: newTags })
      hideModal({ isOpen: false })
    }
  }

  const combinedTags = [...(allTags || []), ...(allClassificationTags || [])]

  return (
    <Modal
      visible={isOpen}
      onCancel={() => hideModal({ isOpen: false })}
      onOk={submit}>
      <Text>Add Tags</Text>
      <Select
        mode="tags"
        autoFocus
        style={{ width: '100%' }}
        onSelect={onTagSelect}
        onDeselect={onTagDeselect}
        placeholder="Add a tag...">
        {combinedTags.map((tag: string) => (
          <Option key={tag} value={tag}>
            {tag}
          </Option>
        ))}
      </Select>
    </Modal>
  )
}

const mapStateToProps = (s: RootState) => ({
  currUserId: getUserIdOfCurrUser(s),
  dateRange: getVideoAssetsDateRange(s),
  allTags: getAllTags(s),
  allClassificationTags: getAllClassificationTags(s),
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ bulkAddTags: bulkAddTagsFlow }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AddTagModal)
