import axios from 'axios'
import { getCookie } from 'utilities/cookie'

const configureAxios = (accessToken?: string) => {
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_API_HOST
  axios.defaults.headers.common['X-CSRF-TOKEN'] = getCookie('csrf_access_token')
  axios.defaults.withCredentials = true
  if (accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
    return
  }
}

export const createModelToolAxiosInstance = () => {
  const token = localStorage.getItem('jwtAccessToken') || ''
  if (!token) {
    return null
  }
  const axiosModelInstance = axios.create({
    baseURL: process.env.REACT_APP_MODEL_API_ENDPOINT,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-CSRF-TOKEN': getCookie('csrf_access_token'),
    },
  })
  return axiosModelInstance
}

export default configureAxios
