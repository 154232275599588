import { ActionType, getType } from 'typesafe-actions'

import * as modalActions from './actionCreators'

export type ModalActionType = ActionType<typeof modalActions>

interface ModalState {
  modalType: any
  modalProps: any
}

const initialState: Readonly<ModalState> = {
  modalType: null,
  modalProps: {},
}

export default (state = initialState, action: ModalActionType) => {
  switch (action.type) {
    case getType(modalActions.showModal):
      return {
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps,
      }
    case getType(modalActions.hideModal):
      return initialState
    default:
      return state
  }
}
