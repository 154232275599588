import { useAuth0 } from '@auth0/auth0-react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import Analytics from 'main/Analytics'
import DataLineage from 'main/DataLineage'
import NotFound from 'main/DefaultPage'
import DicomAssets from 'main/dicomAssets'
import QA from 'main/QA'
import smeVideoAssets from 'main/smeVideoAssets'
import VideoAssets from 'main/videoAssets'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, useLocation } from 'react-router'
import { bindActionCreators, Dispatch } from 'redux'
import {
  fetchAllUsersInfoFlow,
  fetchCurrUserInfoFlow,
} from 'store/authentication/actions'
import {
  initialiseAuthentication,
  JWT_ACCESS_TOKEN,
  setAuthToken,
  useGetAccessToken,
} from 'store/authentication/helpers'
import { getAuthenticatedState } from 'store/authentication/selectors'
import { RootState } from 'store/rootReducer'

import * as routes from './routes'

type Props = StoreProps<typeof mapStateToProps, typeof mapDispatchToProps>

const AppRouter: FC<Props> = ({ fetchAllUsersInfo, fetchCurrUserInfo }) => {
  const { user, isAuthenticated } = useAuth0()
  const { accessToken } = useGetAccessToken({
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
  })
  const { pathname } = useLocation()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  React.useEffect(() => {
    setAuthToken(accessToken)
    if (accessToken) {
      initialiseAuthentication()
      loadInitialData()
    }
  }, [accessToken])

  const loadInitialData = () => {
    const accessToken = localStorage.getItem(JWT_ACCESS_TOKEN)
    if (accessToken && user && user.sub) {
      fetchCurrUserInfo(user.sub.replace('auth0|', ''))
    }
    if (isAuthenticated) {
      fetchAllUsersInfo()
    }
  }

  return (
    <Switch>
      <Route
        exact
        path={routes.MAIN}
        component={withAuthenticationRequired(VideoAssets)}
      />
      <Route
        exact
        path={routes.SME_VIDEO_ASSETS}
        component={withAuthenticationRequired(smeVideoAssets)}
      />
      <Route
        exact
        path={routes.DICOM_ASSETS}
        component={withAuthenticationRequired(DicomAssets)}
      />
      <Route
        exact
        path={routes.DATA_LINEAGE}
        component={withAuthenticationRequired(DataLineage)}
      />
      <Route
        exact
        path={routes.QA}
        component={withAuthenticationRequired(QA)}
      />
      <Route
        exact
        path={routes.ANALYTICS}
        component={withAuthenticationRequired(Analytics)}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}

const mapStateToProps = (s: RootState) => ({
  isAuthenticated: getAuthenticatedState(s),
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchCurrUserInfo: fetchCurrUserInfoFlow,
      fetchAllUsersInfo: fetchAllUsersInfoFlow,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter)
