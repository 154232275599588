/**
 * @desc: This component only exists in changing the value of regions
 * @desc: A more appropriate name should be <RegionColumn />
 * @desc: Leave it as <EditableColumn /> instead
 * @desc: if there are other components that use similar properties
 */

import { Select } from 'antd'
import { PropertyType } from 'common/entryType'
import Stack from 'components/Stack'
import { get } from 'lodash'
import React, { FocusEvent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { getUserIdOfCurrUser } from 'store/authentication/selectors'
import { RootState } from 'store/rootReducer'
import { editVideoPropertyFlow } from 'store/videoSegments/actions'
import {
  getAllRegions,
  getVideoAssetsDateRange,
} from 'store/videoSegments/selectors'

import { VideoSegment } from '../../../common/models'

const { Option } = Select

type Props = OwnProps &
  StoreProps<typeof mapStateToProps, typeof mapDispatchToProps>

interface OwnProps {
  value?: string
  property: PropertyType
  videos: VideoSegment[]
}

const EditableColumn: React.FC<Props> = ({
  value,
  property,
  videos = [],
  editVideoProperty,
  currUserId,
  dateRange,
  allRegions,
}) => {
  const [newValue, setField] = React.useState(value)
  React.useEffect(() => {
    setField(value)
  }, [value])

  const extractVideoIds = (videos: VideoSegment[]) => {
    const videoIds: string[] = []
    for (const video in videos) {
      videoIds.push(videos[video]._id)
    }
    return videoIds
  }

  const submitEditedField = (e: string) => {
    if (value === e) {
      return
    }

    const newValue = toTitleCase(e.trim())
    if (newValue.length === 0) {
      return
    }
    for (const id of extractVideoIds(videos)) {
      editVideoProperty(
        {
          [property]: newValue,
          videoId: id,
          batch: videos['0'].batch,
        },
        currUserId,
        dateRange,
        property,
        value,
        newValue,
      )
    }
  }

  return (
    <Stack>
      <Select
        showSearch
        value={newValue}
        style={{ width: '100%' }}
        showArrow={false}
        onSearch={(e: string) => setField(e)}
        notFoundContent={null}
        onSelect={submitEditedField}
        onBlur={(e: FocusEvent<HTMLElement>) =>
          submitEditedField(get(e.target, 'value', ''))
        }
        placeholder="Enter region...">
        {showNewOption(newValue, allRegions) && (
          <Option key={0} value={newValue || ''}>
            {newValue}
          </Option>
        )}
        {allRegions?.map((region: string) => (
          <Option key={region} value={region}>
            {region}
          </Option>
        ))}
      </Select>
    </Stack>
  )
}

const showNewOption = (val: string | undefined, allRegions: string[]) => {
  if (!val || val.trim().length === 0) {
    return false
  }
  if (Array.isArray(allRegions) && allRegions.includes(val)) {
    return false
  }
  return true
}

//Convert a string to title case
function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

const mapStateToProps = (s: RootState) => ({
  currUserId: getUserIdOfCurrUser(s),
  dateRange: getVideoAssetsDateRange(s),
  allRegions: getAllRegions(s),
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      editVideoProperty: editVideoPropertyFlow,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(EditableColumn)
