import { ImportLog } from 'common/models'
import { keyBy } from 'lodash'
import { ActionType, getType } from 'typesafe-actions'

import * as logsActions from './actionCreators'

export type LogActionType = ActionType<typeof logsActions>

interface LogsState {
  importLogs: Dict<any>
}

const initialState: Readonly<LogsState> = {
  importLogs: {},
}

export default (state = initialState, action: LogActionType) => {
  switch (action.type) {
    case getType(logsActions.getImportLogs.success): {
      const importLogs: Dict<ImportLog> = keyBy(action.payload, '_id')
      return {
        ...state,
        importLogs,
      }
    }
    default:
      return state
  }
}
