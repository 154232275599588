import './DataLineage.css'

import { Button, Spin } from 'antd'
import { Table } from 'antd'
import { ExportLog, ImportLog } from 'common/models'
import Stack from 'components/Stack'
import { values } from 'lodash'
import { getVideoDoctorName, mapSurgeonIDtoEmail } from 'main/Analytics/helper'
import React, { FC, useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Chart } from 'react-google-charts'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { fetchExportLogsFlow } from 'store/exportLogs/actions'
import { getExportLogs } from 'store/exportLogs/selectors'
import { fetchImportLogsFlow } from 'store/importLogs/actions'
import { getImportLogs } from 'store/importLogs/selectors'
import { RootState } from 'store/rootReducer'
import { fetchTrainingLogsFlow } from 'store/trainingLogs/actions'
import { getTrainingLogs } from 'store/trainingLogs/selectors'

type Props = OwnProps &
  StoreProps<typeof mapStateToProps, typeof mapDispatchToProps>

interface OwnProps {
  importLogs?: Dict<ImportLog>
  exportLogs?: Dict<ExportLog>
}

// second step to format into react-google-charts's accepted format
function processDataArray(dataArray: any[]): any[] {
  const sourceTotals = {}

  for (let i = 0; i < dataArray.length; i++) {
    const item = dataArray[i],
      key = item[0]
    if (Object.prototype.hasOwnProperty.call(sourceTotals, key)) {
      sourceTotals[key] += item[2]
    } else {
      sourceTotals[key] = item[2]
    }
  }

  const destTotals = {}

  for (let i = 0; i < dataArray.length; i++) {
    const item = dataArray[i],
      key = item[1]
    if (destTotals[key]) {
      destTotals[key] += item[2]
    } else {
      destTotals[key] = item[2]
    }
  }

  for (const key in destTotals) {
    if (Object.prototype.hasOwnProperty.call(destTotals, key)) {
      if (Object.prototype.hasOwnProperty.call(sourceTotals, key)) {
        delete sourceTotals[key]
      }
    }
  }

  function isKeyInObject(query: any, object: Record<string, any>): boolean {
    return Object.prototype.hasOwnProperty.call(object, query)
  }

  dataArray.forEach(function (d) {
    // Check if d[0] key is in source totals
    if (isKeyInObject(d[0], sourceTotals)) {
      d[0] = d[0] + ' (' + sourceTotals[d[0]] + ')'
    }

    // check of d[1] key exists in dest totals
    if (isKeyInObject(d[1], destTotals)) {
      d[1] = '(' + destTotals[d[1]] + ') ' + d[1]
    }

    // deal with destination keys in source index in original arr
    // check if key in position 0 of original chart data is available in destTotals object
    // change its name to the name key in destTotals
    if (isKeyInObject(d[0], destTotals)) {
      d[0] = '(' + destTotals[d[0]] + ') ' + d[0]
    }
  })

  return dataArray
}

export const options = {
  sankey: {
    node: {
      nodePadding: 10,
      label: {
        fontName: 'Arial',
        fontSize: 16,
        color: '#000',
        bold: true,
        italic: false,
      },
    },
  },
}

const DataLineage: FC<Props> = ({
  fetchImportLogs,
  importLogs,
  fetchExportLogs,
  exportLogs,
  fetchTrainingLogs,
  trainingLogs,
}) => {
  const loadLogs = () => {
    fetchImportLogs()
    fetchExportLogs()
    fetchTrainingLogs()
  }

  React.useEffect(loadLogs, [
    fetchExportLogs,
    fetchImportLogs,
    fetchTrainingLogs,
  ])

  // filter for only video metadata because image metadata also exists in export_logs metadata
  const filteredVideoNameKeys = Object.keys(exportLogs).filter(key => {
    return 'videoName' in exportLogs[key]
  })

  const filteredVideos = filteredVideoNameKeys.reduce((obj, key) => {
    obj[key] = exportLogs[key]
    return obj
  }, {})

  const importLogsArray = Object.values(importLogs)
  const exportLogsArray = Object.values(filteredVideos)
  const trainingLogsArray = Object.values(trainingLogs)

  const kneeVideosImport = importLogsArray.filter(
    video => video.videoRegion === 'Knee',
  )

  const kneeVideosExport = exportLogsArray.filter(
    (video: any) => video.videoRegion === 'Knee',
  )

  const kneeVideosTraining = trainingLogsArray.filter(
    video => video.video_region === 'Knee',
  )

  const shoulderVideosImport = importLogsArray.filter(
    video => video.videoRegion === 'Shoulder',
  )

  const shoulderVideosExport = exportLogsArray.filter(
    (video: any) => video.videoRegion === 'Shoulder',
  )

  const shoulderVideosTraining = trainingLogsArray.filter(
    video => video.video_region === 'Shoulder',
  )

  const groupedKneeTrainingData = kneeVideosTraining.reduce<{
    [key: string]: number
  }>((acc, curr) => {
    const modelName = curr.model_name.toLowerCase()
    const splitString = modelName.split('_')
    let modelNameWithoutClass = ''
    const modelNameIncludesClass = splitString.length > 3
    if (modelNameIncludesClass) {
      const anatomy = splitString[0]
      const typeOfModel = splitString[1]
      const modelVersion = splitString[3]

      modelNameWithoutClass = `${anatomy}_${typeOfModel}_${modelVersion}`
    } else {
      modelNameWithoutClass = modelName
    }

    modelNameWithoutClass = modelNameWithoutClass.toLowerCase()

    if (!acc[modelNameWithoutClass]) {
      acc[modelNameWithoutClass] = 1
    } else {
      acc[modelNameWithoutClass]++
    }
    return acc
  }, {})

  const groupedShoulderTrainingData = shoulderVideosTraining.reduce<{
    [key: string]: number
  }>((acc, curr) => {
    const modelName = curr.model_name.toLowerCase()
    const splitString = modelName.split('_')
    let modelNameWithoutClass = ''
    const modelNameIncludesClass = splitString.length > 3
    if (modelNameIncludesClass) {
      const anatomy = splitString[0]
      const typeOfModel = splitString[1]
      const modelVersion = splitString[3]
      modelNameWithoutClass = `${anatomy}_${typeOfModel}_${modelVersion}`
    } else {
      modelNameWithoutClass = modelName
    }

    modelNameWithoutClass = modelNameWithoutClass.toLowerCase()

    if (!acc[modelNameWithoutClass]) {
      acc[modelNameWithoutClass] = 1
    } else {
      acc[modelNameWithoutClass]++
    }
    return acc
  }, {})

  const lengthOfLabeledKneeVideos = kneeVideosExport.length

  const totalTrainedKneeVideos = Object.values(groupedKneeTrainingData).reduce(
    (acc: number, count: number) => acc + count,
    0,
  )

  const untrainedKneeVideos = lengthOfLabeledKneeVideos - totalTrainedKneeVideos

  const untrainedKnee = [
    `(${lengthOfLabeledKneeVideos}) Labeled`,
    `(${untrainedKneeVideos}) Untrained`,
    untrainedKneeVideos,
  ]

  const trainingKnee = [
    ...Object.entries(groupedKneeTrainingData).map(([modelName, count]) => {
      const splitString = modelName.split('_')
      let refactoredModelName = ''
      const modelNameHasCorrectSyntax = splitString.length > 2
      if (modelNameHasCorrectSyntax) {
        const capitalizedFirstCharAnatomy = splitString[0]
          .charAt(0)
          .toUpperCase()
        const restOfAnatomyString = splitString[0].slice(1)
        const typeOfModel = splitString[1].toUpperCase()
        const modelVersion = splitString[2].toUpperCase()

        refactoredModelName = `${capitalizedFirstCharAnatomy}${restOfAnatomyString}_${typeOfModel}_${modelVersion}`
      } else {
        refactoredModelName = modelName
      }
      return [
        `(${lengthOfLabeledKneeVideos}) Labeled`,
        `(${count}) ${refactoredModelName}`,
        count,
      ]
    }),
    ...(untrainedKneeVideos > 0 ? [untrainedKnee] : []),
  ]

  const lengthOfLabeledShoulderVideos = shoulderVideosExport.length

  const totalTrainedShoulderVideos = Object.values(
    groupedShoulderTrainingData,
  ).reduce((acc: number, count: number) => acc + count, 0)

  const untrainedShoulderVideos =
    lengthOfLabeledShoulderVideos - totalTrainedShoulderVideos

  const untrainedShoulder = [
    `(${lengthOfLabeledShoulderVideos}) Labeled`,
    `(${untrainedShoulderVideos}) Untrained`,
    untrainedShoulderVideos,
  ]

  const trainingShoulder = [
    ...Object.entries(groupedShoulderTrainingData).map(([modelName, count]) => {
      const splitString = modelName.split('_')
      let refactoredModelName = ''
      const modelNameHasCorrectSyntax = splitString.length > 2
      if (modelNameHasCorrectSyntax) {
        const capitalizedFirstCharAnatomy = splitString[0]
          .charAt(0)
          .toUpperCase()
        const restOfAnatomyString = splitString[0].slice(1)
        const typeOfModel = splitString[1].toUpperCase()
        const modelVersion = splitString[2].toUpperCase()

        refactoredModelName = `${capitalizedFirstCharAnatomy}${restOfAnatomyString}_${typeOfModel}_${modelVersion}`
      } else {
        refactoredModelName = modelName
      }
      return [
        `(${lengthOfLabeledShoulderVideos}) Labeled`,
        `(${count}) ${refactoredModelName}`,
        count,
      ]
    }),
    ...(untrainedShoulderVideos > 0 ? [untrainedShoulder] : []),
  ]

  const labeledShoulder = [
    `(${shoulderVideosImport.length}) Shoulder`,
    `(${lengthOfLabeledShoulderVideos}) Labeled`,
    lengthOfLabeledShoulderVideos,
  ]

  const unlabeledShoulderVideosLength =
    shoulderVideosImport.length - shoulderVideosExport.length

  const unlabeledShoulder = [
    `(${shoulderVideosImport.length}) Shoulder`,
    `(${unlabeledShoulderVideosLength}) Unlabeled`,
    unlabeledShoulderVideosLength,
  ]

  const labeledKnee = [
    `(${kneeVideosImport.length}) Knee`,
    `(${lengthOfLabeledKneeVideos}) Labeled`,
    lengthOfLabeledKneeVideos,
  ]

  const unlabeledKneeVideosLength =
    kneeVideosImport.length - kneeVideosExport.length

  const unlabeledKnee = [
    `(${kneeVideosImport.length}) Knee`,
    `(${unlabeledKneeVideosLength}) Unlabeled`,
    unlabeledKneeVideosLength,
  ]

  const noModelShoulder = [
    `(${unlabeledShoulderVideosLength}) Unlabeled`,
    `(${unlabeledShoulderVideosLength}) No model`,
    unlabeledShoulderVideosLength,
  ]

  const noModelKnee = [
    `(${unlabeledKneeVideosLength}) Unlabeled`,
    `(${unlabeledKneeVideosLength}) No model`,
    unlabeledKneeVideosLength,
  ]

  const doctorEmailsForShoulder = [
    ...[shoulderVideosImport.map(item => item.videoDoctorEmail)],
  ]

  const doctorEmailsForKnee = [
    ...[kneeVideosImport.map(item => item.videoDoctorEmail)],
  ]

  // get {'doctor': 'count'}
  function manipulateArray(dataArray: string[][]): object[] {
    const emailCount = {}
    for (const subArray of dataArray) {
      for (const email of subArray) {
        if (Object.prototype.hasOwnProperty.call(emailCount, email)) {
          emailCount[email]++
        } else {
          emailCount[email] = 1
        }
      }
    }
    return Object.entries(emailCount).map(([email, count]) => ({
      [email]: count,
    }))
  }

  const transformedImportLogsShoulder = manipulateArray(doctorEmailsForShoulder)

  const transformedImportLogsKnee = manipulateArray(doctorEmailsForKnee)

  // change doctor id to doctor name
  function changeVideoDoctorIdToName(dataArray: object[]): object[] {
    return dataArray.map(obj => {
      const key = Object.keys(obj)[0]
      return { [getVideoDoctorName(key)]: obj[key] }
    })
  }

  const finalImportLogsShoulder = changeVideoDoctorIdToName(
    transformedImportLogsShoulder,
  )

  const finalImportLogsKnee = changeVideoDoctorIdToName(
    transformedImportLogsKnee,
  )

  // first step to format into react-google-charts's accepted format
  function manipulateDataShoulder(data: object[]): string[][] {
    const manipulatedData = [['From', 'To', 'Weight']]
    const emailCount = {}

    for (const item of data) {
      for (const key in Object.keys(item)) {
        const email = Object.keys(item)[key]
        if (Object.prototype.hasOwnProperty.call(item, email)) {
          if (Object.prototype.hasOwnProperty.call(emailCount, email)) {
            emailCount[email] += item[email]
          } else {
            emailCount[email] = item[email]
          }
        }
      }
    }

    for (const key in Object.keys(emailCount)) {
      const email = Object.keys(emailCount)[key]
      if (Object.prototype.hasOwnProperty.call(emailCount, email)) {
        manipulatedData.push([email, 'Shoulder', emailCount[email]])
      }
    }

    return manipulatedData
  }

  // first step to format into react-google-charts's accepted format
  function manipulateDataKnee(data: object[]): string[][] {
    const manipulatedData = [['From', 'To', 'Weight']]
    const emailCount = {}

    for (const item of data) {
      for (const key in Object.keys(item)) {
        const email = Object.keys(item)[key]
        if (Object.prototype.hasOwnProperty.call(item, email)) {
          if (Object.prototype.hasOwnProperty.call(emailCount, email)) {
            emailCount[email] += item[email]
          } else {
            emailCount[email] = item[email]
          }
        }
      }
    }

    for (const key in Object.keys(emailCount)) {
      const email = Object.keys(emailCount)[key]
      if (Object.prototype.hasOwnProperty.call(emailCount, email)) {
        manipulatedData.push([email, 'Knee', emailCount[email]])
      }
    }

    return manipulatedData
  }

  const importLogsToShowShoulder = manipulateDataShoulder(
    finalImportLogsShoulder,
  )

  const importLogsToShowKnee = manipulateDataKnee(finalImportLogsKnee)

  const shoulderDataFinal = [
    ...processDataArray(importLogsToShowShoulder),
    unlabeledShoulder,
    labeledShoulder,
    ...trainingShoulder,
    noModelShoulder,
  ]

  const kneeDataFinal = [
    ...processDataArray(importLogsToShowKnee),
    unlabeledKnee,
    labeledKnee,
    ...trainingKnee,
    noModelKnee,
  ]

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1500)
  }, [])

  // manipulation for compatability with antd table
  function convertListToNestedObject(list: any) {
    const nestedObject: any = {}

    for (const item of list) {
      const modelName = item.model_name.toLowerCase()
      // eslint-disable-next-line no-prototype-builtins
      if (!nestedObject.hasOwnProperty(modelName)) {
        nestedObject[modelName] = {
          model_name: item.model_name,
          videos: [],
        }
      }
      nestedObject[modelName].videos.push(item)
    }

    return nestedObject
  }

  const nestedTrainingLogs = convertListToNestedObject(trainingLogsArray)

  function addVideoCount(data: any) {
    const result = {}

    for (const key in data) {
      result[key] = {
        ...data[key],
        video_count: data[key].videos.length,
      }
    }

    return result
  }

  const trainingLogsData: any = addVideoCount(nestedTrainingLogs)

  function splitByModelName(input: any[]) {
    const shoulderArray: any = []
    const kneeArray: any = []

    input.forEach(obj => {
      if (obj.model_name.includes('Shoulder')) {
        shoulderArray.push(obj)
      } else if (obj.model_name.includes('Knee')) {
        kneeArray.push(obj)
      }
    })

    return {
      shoulderArray,
      kneeArray,
    }
  }

  const { shoulderArray, kneeArray } = splitByModelName(
    values(trainingLogsData),
  )

  function sortModels(models: any) {
    const sortedModels = [...models]
    sortedModels.sort((a, b) => {
      const versionA = a.model_name.match(/\d+(\.\d+)*/)?.[0] || '0'
      const versionB = b.model_name.match(/\d+(\.\d+)*/)?.[0] || '0'

      const compareVersion = versionA.localeCompare(versionB, undefined, {
        numeric: true,
        sensitivity: 'base',
      })

      if (compareVersion !== 0) {
        return compareVersion
      }

      return a.model_name.localeCompare(b.model_name, undefined, {
        ignorePunctuation: true,
        sensitivity: 'base',
      })
    })

    return sortedModels
  }

  const sortedShoulderArray = sortModels(shoulderArray)
  const sortedKneeArray = sortModels(kneeArray)

  const getOuterColumns = () => {
    const columns = [
      {
        title: 'Model Name',
        dataIndex: 'model_name',
        key: 'model_name',
        width: '50%',
        sorter: (a: any, b: any) => {
          const versionA = a.model_name.match(/\d+(\.\d+)*/)?.[0] || '0'
          const versionB = b.model_name.match(/\d+(\.\d+)*/)?.[0] || '0'

          const compareVersion = versionA.localeCompare(versionB, undefined, {
            numeric: true,
            sensitivity: 'base',
          })

          if (compareVersion !== 0) {
            return compareVersion
          }

          return a.model_name.localeCompare(b.model_name, undefined, {
            ignorePunctuation: true,
            sensitivity: 'base',
          })
        },
        render: (modelName: string) => {
          const splitString = modelName.split('_')
          const modelNameIncludesClass = splitString.length > 3
          if (modelNameIncludesClass) {
            const capitalizedFirstCharAnatomy = splitString[0]
              .charAt(0)
              .toUpperCase()
            const restOfAnatomyString = splitString[0].slice(1)
            const typeOfModel = splitString[1].toUpperCase()
            const className = splitString[2]
            const modelVersion = splitString[3].toUpperCase()

            return `${capitalizedFirstCharAnatomy}${restOfAnatomyString}_${typeOfModel}_${className}_${modelVersion}`
          } else {
            return modelName
          }
        },
      },
      {
        title: 'Total videos used to train this model',
        dataIndex: 'video_count',
        key: 'video_count',
        width: '50%',
        sorter: (a: any, b: any) => a.video_count - b.video_count,
      },
    ]

    return columns
  }

  const outerColumn = getOuterColumns()

  const getInnerColumns = () => {
    const columns = [
      {
        title: 'Video Name',
        dataIndex: 'video_name',
        key: 'video_name',
        width: '33%',
      },
      {
        title: 'Surgeon Email',
        dataIndex: 'surgeon_id',
        key: 'surgeon_id',
        width: '33%',
        render: (surgeon_id: any) => (
          <div>{mapSurgeonIDtoEmail(surgeon_id)}</div>
        ),
      },
      {
        title: 'Dataset Name',
        dataIndex: 'dataset_name',
        key: 'dataset_name',
        width: '33%',
      },
    ]
    return columns
  }

  const expandedRow = (row: any) => {
    const columns = getInnerColumns()

    let nestedData: any[] = []
    const currentBatch = values(nestedTrainingLogs)
    for (let i = 0; i < currentBatch.length; i++) {
      if (row.model_name === currentBatch[i].model_name) {
        nestedData = currentBatch[i].videos
        break
      }
    }

    nestedData.sort((a, b) =>
      a.video_name.localeCompare(
        b.video_name,
        navigator.languages[0] || navigator.language,
        {
          numeric: true,
          ignorePunctuation: true,
          sensitivity: 'base',
        },
      ),
    )

    return (
      <Table
        columns={columns}
        dataSource={nestedData}
        pagination={{
          defaultPageSize: 5,
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20'],
        }}
        rowKey={(batch: any) => batch._id}
      />
    )
  }

  const csvHeaders = [
    'Model Name',
    'Dataset Name',
    'Video Name',
    'Surgeon Email',
  ]

  const csvDataKnee = [
    csvHeaders,
    ...values(sortedKneeArray).flatMap(({ model_name, videos }) =>
      videos.map(
        ({
          dataset_name,
          video_name,
          surgeon_id,
        }: {
          dataset_name: any
          video_name: any
          surgeon_id: any
        }) => {
          const datasetName = dataset_name || ''
          const videoName = video_name || ''
          const surgeonEmail = surgeon_id ? mapSurgeonIDtoEmail(surgeon_id) : ''

          return [model_name || '', datasetName, videoName, surgeonEmail]
        },
      ),
    ),
  ]

  const csvDataShoulder = [
    csvHeaders,
    ...values(sortedShoulderArray).flatMap(({ model_name, videos }) =>
      videos.map(
        ({
          dataset_name,
          video_name,
          surgeon_id,
        }: {
          dataset_name: any
          video_name: any
          surgeon_id: any
        }) => {
          const datasetName = dataset_name || ''
          const videoName = video_name || ''
          const surgeonEmail = surgeon_id ? mapSurgeonIDtoEmail(surgeon_id) : ''

          return [model_name || '', datasetName, videoName, surgeonEmail]
        },
      ),
    ),
  ]

  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <div>
          <Stack>
            <h1 style={{ paddingRight: '1rem' }}>Knee Model Logs</h1>
            <Button>
              <CSVLink filename={'knee-models-logs.csv'} data={csvDataKnee}>
                Download as CSV
              </CSVLink>
            </Button>
          </Stack>
          <Table
            columns={outerColumn}
            expandable={{
              expandedRowRender: expandedRow,
            }}
            dataSource={values(sortedKneeArray)}
            rowKey={(batch: any) => batch.model_name}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              pageSizeOptions: ['5', '10', '20'],
            }}
          />
          <div>
            {Object.keys(importLogs).length > 0 &&
            Object.keys(exportLogs).length > 0 &&
            Object.keys(trainingLogs).length > 0 ? (
              <>
                <h1>Knee Models</h1>
                <div className="sankey-chart">
                  <Chart
                    chartType="Sankey"
                    width="90%"
                    height="800px"
                    data={kneeDataFinal}
                    options={options}
                  />
                </div>
              </>
            ) : null}
            <Stack>
              <h1 style={{ marginTop: '20px', paddingRight: '1rem' }}>
                Shoulder Model Logs
              </h1>
              <Button style={{ marginTop: '20px' }}>
                <CSVLink
                  filename={'shoulder-models-logs.csv'}
                  data={csvDataShoulder}>
                  Download as CSV
                </CSVLink>
              </Button>
            </Stack>
            <Table
              columns={outerColumn}
              expandable={{
                expandedRowRender: expandedRow,
              }}
              dataSource={values(sortedShoulderArray)}
              rowKey={(batch: any) => batch.model_name}
              pagination={{
                defaultPageSize: 5,
                showSizeChanger: true,
                pageSizeOptions: ['5', '10', '20'],
              }}
            />
            {Object.keys(importLogs).length > 0 &&
            Object.keys(exportLogs).length > 0 &&
            Object.keys(trainingLogs).length > 0 ? (
              <>
                <h1 style={{ marginTop: '10px' }}>Shoulder Models</h1>
                <div className="sankey-chart">
                  <Chart
                    chartType="Sankey"
                    width="90%"
                    height="800px"
                    data={shoulderDataFinal}
                    options={options}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  importLogs: getImportLogs(state),
  exportLogs: getExportLogs(state),
  trainingLogs: getTrainingLogs(state),
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchImportLogs: fetchImportLogsFlow,
      fetchExportLogs: fetchExportLogsFlow,
      fetchTrainingLogs: fetchTrainingLogsFlow,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(DataLineage)
