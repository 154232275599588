import { Icon } from '@blueprintjs/core'
import { BLACK, GREEN } from 'common/colors'
import { Spacing } from 'common/stylings'
import Modal from 'components/Modal'
import Stack from 'components/Stack'
import Text from 'components/Typography'
import React from 'react'

const PROJECT_LINK = 'https://app.labelbox.com/projects/'

interface Props {
  isOpen: boolean
  projectId: string
  hideModal: (...args: any) => void
}

const UploadSuccessModal: React.FC<Props> = ({
  isOpen,
  projectId,
  hideModal,
}) => {
  const link = PROJECT_LINK + projectId
  return (
    <Modal
      visible={isOpen}
      onCancel={() => hideModal({ isOpen: false })}
      onOk={() => hideModal({ isOpen: false })}
    >
      <Stack vertical gutter={Spacing.MEDIUM}>
        <Stack alignItems="center" gutter={Spacing.REGULAR}>
          <Icon icon="confirm" style={{ color: GREEN, fontSize: 24 }} />
          <Text fontWeight="bold" color={BLACK} fontSize={18}>
            Uploaded successfully
          </Text>
        </Stack>
        <a target="_blank" rel="noopener noreferrer" href={link}>
          {link}
        </a>
      </Stack>
    </Modal>
  )
}
export default UploadSuccessModal
