import axios from 'axios'
import { Dispatch } from 'redux'

import { getExportLogs } from './actionCreators'

export const fetchExportLogsFlow = () => (dispatch: Dispatch) => {
  dispatch(getExportLogs.request())
  axios
    .get('/logs/export_logs')
    .then(res => {
      const exportLogs: any = res.data.export_logs
      dispatch(getExportLogs.success(exportLogs))
    })
    .catch(err => dispatch(getExportLogs.failure(err)))
}
