import './TagColumn.css'

import { Icon } from '@blueprintjs/core'
import { Popover, Select, Tag } from 'antd'
import { LabeledValue } from 'antd/lib/select'
import { Spacing } from 'common/stylings'
import { confirm } from 'components/Modal'
import Stack from 'components/Stack'
import _, { uniq } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { getUserIdOfCurrUser } from 'store/authentication/selectors'
import { RootState } from 'store/rootReducer'
import { editVideoTagFlow } from 'store/videoSegments/actions'
import {
  getAllClassificationTags,
  getVideoAssetsDateRange,
} from 'store/videoSegments/selectors'

import { VideoSegment } from '../../../common/models'

const { Option } = Select

type Props = StoreProps<typeof mapStateToProps, typeof mapDispatchToProps> &
  OwnProps

interface OwnProps {
  classificationTags?: string[]
  videos?: VideoSegment[]
}

const BatchClassificationTagColumn: React.FC<Props> = ({
  classificationTags = [],
  editVideoTag,
  currUserId,
  dateRange,
  allClassificationTags,
  videos = [],
}) => {
  const [tagToRemove, setTagToRemove] = React.useState()
  const [isWarningModalOpen, toggleWarningModalVisible] = React.useState(false)
  const [newTags, setTags] = React.useState<string[]>([])

  const onTagSelect = (val: string | number | LabeledValue) => {
    const newTag = val.toString()
    if (!newTags.includes(newTag)) {
      setTags([...newTags, newTag])
    }

    if (classificationTags && !_.isEmpty(newTag)) {
      for (const video of videos) {
        if (video['classificationTags'] === undefined) {
          video['classificationTags'] = []
        }
        editVideoTag(
          {
            videoId: video['_id'],
            classificationTags: uniq([...video['classificationTags'], newTag]),
            batch: video['batch'],
          },
          currUserId,
          dateRange,
        )
      }
    }
  }

  const onTagDeselect = (val: any) => {
    setTagToRemove(val)
    toggleWarningModalVisible(true)
  }

  const removeTag = () => {
    for (const video of videos) {
      if (video['classificationTags'] === undefined) {
        video['classificationTags'] = []
      }

      const newTags = _.filter(
        video['classificationTags'],
        tag => tag !== tagToRemove,
      )

      editVideoTag(
        {
          videoId: video['_id'],
          classificationTags: uniq([...newTags]),
          batch: video['batch'],
        },
        currUserId,
        dateRange,
      )
      setTagToRemove(undefined)
    }
  }

  const displayWarningModal = () => {
    confirm({
      title: 'Do you want to delete the tag?',
      content: "You can't recover the tag after deleting it.",
      icon: <Icon icon="warning-sign" intent="warning" />,
      onOk: () => {
        removeTag()
        toggleWarningModalVisible(false)
      },
      onCancel: () => toggleWarningModalVisible(false),
    })
  }

  const renderPopoverContent = () => {
    const sortedTags = Array.isArray(allClassificationTags)
      ? allClassificationTags.sort(Intl.Collator().compare)
      : []
    const selectedTags = Array.isArray(classificationTags)
      ? classificationTags
      : []
    const isModalOpen = !!isWarningModalOpen

    return (
      <div>
        <Stack
          gutter={Spacing.REGULAR}
          vertical
          style={{ minWidth: 150, maxWidth: 150, position: 'relative' }}>
          <Select
            value={selectedTags}
            mode="tags"
            autoFocus
            onSelect={onTagSelect}
            onDeselect={onTagDeselect}>
            {sortedTags.map((tag: string) => (
              <Option key={tag} value={tag}>
                {tag}
              </Option>
            ))}
          </Select>
        </Stack>
        {isModalOpen && displayWarningModal()}
      </div>
    )
  }

  const renderHoverPopoverContent = (classificationTags: string[]) => {
    return (
      <div>
        {classificationTags.map((tag: string) => (
          <Tag key={tag}>{tag}</Tag>
        ))}
      </div>
    )
  }

  const renderTagsOverview = () => {
    const sortedTags = classificationTags.sort(Intl.Collator().compare)
    const size = sortedTags ? sortedTags.length : 0
    const extraTags = sortedTags.slice(4)

    if (size) {
      return (
        <div>
          <Stack
            style={{
              marginBottom: 7,
            }}>
            {sortedTags[0] && <Tag>{sortedTags[0]}</Tag>}
            {sortedTags[1] && <Tag>{sortedTags[1]}</Tag>}
          </Stack>
          <Stack>
            {sortedTags[2] && <Tag>{sortedTags[2]}</Tag>}
            {sortedTags[3] && <Tag>{sortedTags[3]}</Tag>}
            {size >= 5 && (
              <Popover
                overlayStyle={{
                  width: 'max-content',
                }}
                content={renderHoverPopoverContent(extraTags)}>
                <Tag>...</Tag>
              </Popover>
            )}
          </Stack>
        </div>
      )
    }
    return
  }

  return (
    <Stack
      justifyContent={'flex-start'}
      gutter={Spacing.SMALL}
      style={{
        marginRight: '12px',
      }}>
      {renderTagsOverview()}
      <Popover
        overlayStyle={{
          width: 'max-content',
        }}
        content={renderPopoverContent()}
        trigger="click">
        <Icon
          style={{
            cursor: 'pointer',
            position: 'absolute',
            right: 0,
            alignSelf: 'center',
          }}
          icon="edit"
        />
      </Popover>
    </Stack>
  )
}

const mapStateToProps = (s: RootState) => ({
  currUserId: getUserIdOfCurrUser(s),
  dateRange: getVideoAssetsDateRange(s),
  allClassificationTags: getAllClassificationTags(s),
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      editVideoTag: editVideoTagFlow,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BatchClassificationTagColumn)
