import { get } from 'lodash'
import RootAction from 'store/rootAction'

/**
 * Inspiraton from
 * https://medium.com/stashaway-engineering/react-redux-tips-better-way-to-handle-loading-flags-in-your-reducers-afda42a804c6
 */
export type ErrorState = {
  isError: boolean
  message: string
  statusCode: number
}
interface StatusState {
  loading: boolean
  success: boolean
  error: ErrorState
}

const initialState: Readonly<Dict<StatusState>> = {}

const ERROR = 'Failure'
const SUCCESS = 'Success'
const LOADING = 'Request'

export default (state = initialState, action: RootAction) => {
  const { type } = action
  const matches = /(.*)__(Request$|Success$|Failure$)/.exec(type)

  if (!matches) {
    return state
  }

  const [, requestName, requestState] = matches

  if (requestState === LOADING) {
    return {
      ...state,
      [requestName]: {
        loading: true,
        success: false,
        error: { isError: false, message: '', statusCode: 200 },
      },
    }
  }

  if (requestState === SUCCESS) {
    return {
      ...state,
      [requestName]: {
        loading: false,
        success: true,
        error: { isError: false, message: '', statusCode: 200 },
      },
    }
  }

  if (requestState === ERROR) {
    const message: string = get(
      action.payload,
      ['response', 'data', 'message'],
      'An unknown error has occured',
    )
    const statusCode: number = get(action.payload, ['response', 'status'], 500)
    return {
      ...state,
      [requestName]: {
        loading: false,
        success: false,
        error: {
          isError: true,
          message,
          statusCode,
        },
      },
    }
  }

  return state
}
