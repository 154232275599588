const emailToDoctorMap = {
  'cole.research@rushortho.com': 'Dr. Cole',
  'kdwilliams560@gmail.com': 'Dr. Mall',
  'sklassen@motion-ortho.com': 'Dr. King',
  'vkhabie@aol.com': 'Dr. Khabie',
  'erica.dobos@gmail.com': 'Dr. Gutentag',
  'mark@kaliberlabs.com': 'Kaliber Labs Internal',
  'kerryjoe@mac.com': 'Dr. Pitts',
  'brian.r.waterman@gmail.com': 'Dr. Waterman',
  'pjdenard@gmail.com': 'Dr. Denard',
  'danielle.brandonisio@dupagemd.com': 'Dr. Romeo',
  'rangelo@kaliberlabs.com': 'Dr. Angelo',
  'sgralitzer@scoi.com': 'Sara Gralitzer',
  'rekap-uploader@kaliberlabs.com': 'Rekap',
  'ciccone.ortho@gmail.com': 'Dr. Ciccone',
  'kerry_james_placeholder@kaliberlabs.com': 'Kerry James',
  'k.ng@kaliberlabs.com': 'Kenneth Test',
  'y.tan@kaliber.ai': 'Yekai Test 2',
  'y.tan@kaliberlabs.com': 'Yekai Test',
  'robertmeislin@gmail.com': 'Dr. Meislin',
  'freedbergd@gmail.com': 'Dr. Douglas Freedberg',
  'breuss@mac.com': 'breuss@mac.com',
  't.nathe@proliancesurgeons.com': 'Dr. T. Nathe',
  'd.mickelson@proliancesurgeons.com': 'Dr. D. Mickelson',
  'atferry@gmail.com': 'Dr. Amon Ferry',
  'favbone@aol.com': 'Dr. Favorito',
  'orlandosportsdoc@yahoo.com': 'Dr. Schwartzberg',
  'sab-uploader@kaliberlabs.com': 'SAB Uploader',
}

const surgeonIDToEmailMap = {
  1: 'cole.research@rushortho.com',
  2: 'erica.dobos@gmail.com',
  3: 'kdwilliams560@gmail.com',
  4: 'sklassen@motion-ortho.com',
  5: 'vkhabie@aol.com',
  6: 'rangelo@kaliberlabs.com',
  7: 'kerry_james_placeholder@kaliberlabs.com',
  8: 'brian.r.waterman@gmail.com',
  9: 'pjdenard@gmail.com',
  10: 'danielle.brandonisio@dupagemd.com',
  11: 'sgralitzer@scoi.com',
  12: 'rekap-uploader@kaliberlabs.com',
  13: 'ciccone.ortho@gmail.com',
  14: 'kerryjoe@mac.com',
  15: 'mark@kaliberlabs.com',
  16: 'y.tan@kaliberlabs.com',
  17: 'k.ng@kaliberlabs.com',
  18: 'y.tan@kaliber.ai',
  19: 'robertmeislin@gmail.com',
  20: 'freedbergd@gmail.com',
  21: 'breuss@mac.com',
  22: 't.nathe@proliancesurgeons.com',
  23: 'd.mickelson@proliancesurgeons.com',
  24: 'atferry@gmail.com',
  25: 'favbone@aol.com',
  26: 'orlandosportsdoc@yahoo.com',
  27: 'sab-uploader@kaliberlabs.com',
}

export function getVideoDoctorName(videoDoctorEmail: string): string {
  return emailToDoctorMap[videoDoctorEmail] || 'Test email'
}

export function mapSurgeonIDtoEmail(surgeonID: number): string {
  return surgeonIDToEmailMap[surgeonID]
}
