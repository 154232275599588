import axios from 'axios'
import { ImportLog } from 'common/models'
import { Dispatch } from 'redux'

import { getImportLogs } from './actionCreators'

export const fetchImportLogsFlow = () => (dispatch: Dispatch) => {
  dispatch(getImportLogs.request())
  axios
    .get('/logs/import_logs')
    .then(res => {
      const importLogs: ImportLog = res.data.import_logs
      dispatch(getImportLogs.success(importLogs))
    })
    .catch(err => dispatch(getImportLogs.failure(err)))
}
