import EntryType from 'common/entryType'
import { Entry } from 'common/models'
import { createAsyncAction } from 'typesafe-actions'

export type NewEntryPayload = {
  videoId: string
  userId: string
  action: string
  type: EntryType
}

export const fetchVideoData = createAsyncAction(
  'Retrieve_Video_Data__Request',
  'Retrieve_Video_Data__Success',
  'Retrieve_Video_Data__Failure',
)<{ videoId: string }, Entry[], Error>()

export const addVideoHistoryEntry = createAsyncAction(
  'Add_Video_History_Entry__Request',
  'Add_Video_History_Entry__Success',
  'Add_Video_History_Entry__Failure',
)<NewEntryPayload, Entry, Error>()
