import queryString from 'query-string'

export const getCookie = (name: string) => {
  if (!document.cookie || document.cookie === '') {
    return ''
  }

  const cookieStrs = document.cookie.split(';')
  const cookies = cookieStrs.reduce((values, cookieStr) => {
    const cookie = queryString.parse(cookieStr)
    return {
      ...values,
      ...cookie,
    }
  }, {})
  return cookies[name]
}