import { ActionType, getType } from 'typesafe-actions'

import * as filterActions from './actionCreators'

export type FilterActionType = ActionType<typeof filterActions>

interface FilterState {
  initialFilter: string
  initialSearchField: string
  initialVideo: string
  initialDoctorID: string[]
  initialInputSearch: string
  initialUserFilter: Record<string, React.ReactText[] | null> | null
  initialFiltersCheck: boolean
}

const initialState: Readonly<FilterState> = {
  initialFilter:
    '{"doctor":null,"region":null,"tags":null,"classificationTags":null}',
  initialSearchField: '',
  initialVideo: '{}',
  initialDoctorID: [],
  initialInputSearch: '',
  initialUserFilter: null,
  initialFiltersCheck: false,
}

export default (state = initialState, action: FilterActionType) => {
  switch (action.type) {
    case getType(filterActions.addFilters): {
      return {
        ...state,
        initialFilter: action.payload,
      }
    }
    case getType(filterActions.addSearchField): {
      return {
        ...state,
        initialSearchField: action.payload,
      }
    }
    case getType(filterActions.addVideo): {
      return {
        ...state,
        initialVideo: action.payload,
      }
    }
    case getType(filterActions.addDoctorIDs): {
      return {
        ...state,
        initialDoctorID: action.payload,
      }
    }
    case getType(filterActions.addInputSearch): {
      return {
        ...state,
        initialInputSearch: action.payload,
      }
    }
    case getType(filterActions.addUserFilters): {
      return {
        ...state,
        initialUserFilter: action.payload,
      }
    }
    case getType(filterActions.addFiltersCheck): {
      return {
        ...state,
        initialFiltersCheck: action.payload,
      }
    }
    default:
      return state
  }
}
