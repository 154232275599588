import { Dispatch } from 'redux'

import { hideModal,showModal } from './actionCreators'

export const showModalFlow = (modalType: any, modalProps: any) => (
  dispatch: Dispatch,
) => {
  dispatch(showModal({ modalType, modalProps }))
}

export const hideModalFlow = (modalProps: any) => (dispatch: Dispatch) => {
  dispatch(hideModal({ modalProps }))
}
