import AddRegionModal from './AddRegionModal'
import AddTagModal from './AddTagModal'
import CreateUploaderAccountModal from './CreateUploaderAccountModal'
import ErrorModal from './ErrorModal'
import NewModelModal from './NewModelModal'
import NewModelVersionModal from './NewModelVersionModal'
import SuccessModal from './SuccessModal'
import UploadLBSuccessModal from './UploadLBSuccessModal'

const MODAL_COMPONENTS = {
  ERROR: ErrorModal,
  SUCCESS: SuccessModal,
  UPLOAD_LB_SUCCESS_MODAL: UploadLBSuccessModal,
  ADD_TAG_MODAL: AddTagModal,
  ADD_REGION_MODAL: AddRegionModal,
  CREATE_VERSION_MODAL: NewModelVersionModal,
  CREATE_MODEL_MODAL: NewModelModal,
  CREATE_UPLOADER_ACCOUNT_MODAL: CreateUploaderAccountModal,
}

export default MODAL_COMPONENTS
