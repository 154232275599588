import styled from 'styled-components'

const HeaderLayout = styled.div<{}>`
  position: fixed;
  height: 118px;
  width: 100%;
  left: 0px;
  top: 0px;
  padding-left: 30px;
  padding-right: 30px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(220, 220, 220, 0.25);
`

export default HeaderLayout
