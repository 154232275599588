import './Analytics.css'

import { Button, DatePicker } from 'antd'
import { Select } from 'antd'
import { DARK_BLUE } from 'common/colors'
import { ExportLog, ImportLog } from 'common/models'
import { Spacing } from 'common/stylings'
import Stack from 'components/Stack'
import { Text } from 'components/Typography'
import { Dayjs, isDayjs } from 'dayjs'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { RangeValue } from 'rc-picker/lib/interface'
import React, { FC, useState } from 'react'
import { CSVLink } from 'react-csv'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { fetchExportLogsFlow } from 'store/exportLogs/actions'
import { getExportLogs } from 'store/exportLogs/selectors'
import { fetchImportLogsFlow } from 'store/importLogs/actions'
import { getImportLogs } from 'store/importLogs/selectors'
import { RootState } from 'store/rootReducer'
import { getISODate } from 'utilities/datetime'

import ClassChart from './ClassChart'
import ExportChart from './ExportChart'
import { getVideoDoctorName } from './helper'
import ImportChart from './ImportChart'

const { RangePicker } = DatePicker

type Props = OwnProps &
  StoreProps<typeof mapStateToProps, typeof mapDispatchToProps>

interface OwnProps {
  importLogs?: Dict<ImportLog>
  exportLogs?: Dict<ExportLog>
}

const Analytics: FC<Props> = ({
  fetchImportLogs,
  importLogs,
  fetchExportLogs,
  exportLogs,
}) => {
  const initialStartDate = dayjs('2022-10-01')
  const initialEndDate = dayjs(new Date())

  const [dateRange, setDateRange] = React.useState([
    getISODate(initialStartDate),
    getISODate(initialEndDate),
  ])

  const clearDates = () => {
    setDateRange([])
  }

  const onChange = (
    dateDayjs: RangeValue<Dayjs>,
    dateString: [string, string],
  ) => {
    if (isEmpty(dateDayjs)) {
      clearDates()
      return
    }
    const start = dateDayjs?.[0]
    const end = dateDayjs?.[1]
    if (isDayjs(start) && isDayjs(end)) {
      setDateRange([start.toISOString(), end.toISOString()])
      return
    }
    setDateRange(dateString)
  }

  const loadLogs = () => {
    fetchImportLogs()
    fetchExportLogs()
  }

  React.useEffect(loadLogs, [fetchExportLogs, fetchImportLogs])

  const { Option } = Select

  const [desiredSurgeonEmail, setDesiredSurgeonEmail] = useState(
    'Choose surgeon email',
  )

  const handleSurgeonEmailChange = (value: string) => {
    setDesiredSurgeonEmail(value)
  }

  const [desiredClass, setDesiredClass] = useState('Choose class')

  const handleClassChange = (value: string) => {
    setDesiredClass(value)
  }

  const importDoctorKneeEmails: string[] = []
  const importDoctorShoulderEmails: string[] = []

  const exportDoctorKneeEmails: string[] = []
  const exportDoctorShoulderEmails: string[] = []

  const exportFrameCountKnee: string[][] = []
  const exportFrameCountShoulder: string[][] = []

  const exportClassesKnee: string[][] = []
  const exportClassesShoulder: string[][] = []

  const exportDoctorEmailsKneeCSV: string[] = []
  const exportDoctorEmailsShoulderCSV: string[] = []

  const importLogsArray = Object.values(importLogs)

  const splitVideosByRegion = (videos: any[]) => {
    if (!Array.isArray(videos)) {
      throw new TypeError('Expected an array of videos')
    }

    const kneeVideos = []
    const shoulderVideos = []
    const otherVideos = []

    for (const video of videos) {
      if (!video || typeof video !== 'object') {
        throw new TypeError('Expected video to be an object')
      }

      let videoRegion = video.videoRegion

      if (typeof videoRegion !== 'string') {
        videoRegion = ''
      }

      if (videoRegion === 'Knee') {
        kneeVideos.push(video)
      } else if (videoRegion === 'Shoulder') {
        shoulderVideos.push(video)
      } else {
        otherVideos.push(video)
      }
    }

    return [kneeVideos, shoulderVideos, otherVideos]
  }

  const [kneeImportArray, shoulderImportArray, otherKneeVideos] =
    splitVideosByRegion(importLogsArray)

  // filter for only video metadata because image metadata also exists in export_logs metadata
  const filteredVideoNameKeys = Object.keys(exportLogs).filter(key => {
    return 'videoName' in exportLogs[key]
  })

  const filteredVideos = filteredVideoNameKeys.reduce((obj, key) => {
    obj[key] = exportLogs[key]
    return obj
  }, {})

  const exportLogsArray = Object.values(filteredVideos)

  const [kneeExportArray, shoulderExportArray, otherShoulderVideos] =
    splitVideosByRegion(exportLogsArray)

  const startDate = new Date(dateRange[0]).getTime()
  const endDate = new Date(dateRange[1]).getTime()

  // add one day for correct filtering of logs
  const filteredEndDate = endDate + 86400000

  const uniqueDoctorEmails = [
    ...new Set(importLogsArray.map(item => item.videoDoctorEmail)),
  ]

  const classesArray = [
    ...new Set(exportLogsArray.map((item: any) => item.classes)),
  ]
  const flatClassesArray = classesArray.flat()
  const uniqueClassesArray = [...new Set(flatClassesArray)]

  // for filtered import knee logs
  const filteredImportKneeLogs = kneeImportArray.filter((d: any) => {
    const time = new Date(d.uploadDate).getTime()
    const videoDoctorEmail = d.videoDoctorEmail
    if (
      desiredSurgeonEmail !== 'Choose surgeon email' &&
      desiredSurgeonEmail !== undefined &&
      dateRange.length !== 0
    ) {
      return (
        startDate < time &&
        time < filteredEndDate &&
        videoDoctorEmail === desiredSurgeonEmail
      )
    } else {
      return startDate < time && time < filteredEndDate
    }
  })

  // for filtered import shoulder logs
  const filteredImportShoulderLogs = shoulderImportArray.filter((d: any) => {
    const time = new Date(d.uploadDate).getTime()
    const videoDoctorEmail = d.videoDoctorEmail
    if (
      desiredSurgeonEmail !== 'Choose surgeon email' &&
      desiredSurgeonEmail !== undefined &&
      dateRange.length !== 0
    ) {
      return (
        startDate < time &&
        time < filteredEndDate &&
        videoDoctorEmail === desiredSurgeonEmail
      )
    } else {
      return startDate < time && time < filteredEndDate
    }
  })

  // for filtered export logs
  const filteredExportLogs = exportLogsArray.filter((d: any) => {
    const time = new Date(d.uploadDate).getTime()
    const videoDoctorEmail = d.surgeonEmail

    if (
      desiredSurgeonEmail !== 'Choose surgeon email' &&
      desiredSurgeonEmail !== undefined
    ) {
      return (
        startDate < time &&
        time < filteredEndDate &&
        videoDoctorEmail === desiredSurgeonEmail
      )
    } else {
      return startDate < time && time < filteredEndDate
    }
  })

  // for filtered export knee logs
  const filteredExportKneeLogs = kneeExportArray.filter((d: any) => {
    const time = new Date(d.uploadDate).getTime()
    const videoDoctorEmail = d.surgeonEmail

    if (
      desiredSurgeonEmail !== 'Choose surgeon email' &&
      desiredSurgeonEmail !== undefined
    ) {
      return (
        startDate < time &&
        time < filteredEndDate &&
        videoDoctorEmail === desiredSurgeonEmail
      )
    } else {
      return startDate < time && time < filteredEndDate
    }
  })

  // for filtered export shoulder logs
  const filteredExportShoulderLogs = shoulderExportArray.filter((d: any) => {
    const time = new Date(d.uploadDate).getTime()
    const videoDoctorEmail = d.surgeonEmail

    if (
      desiredSurgeonEmail !== 'Choose surgeon email' &&
      desiredSurgeonEmail !== undefined
    ) {
      return (
        startDate < time &&
        time < filteredEndDate &&
        videoDoctorEmail === desiredSurgeonEmail
      )
    } else {
      return startDate < time && time < filteredEndDate
    }
  })

  if (filteredImportKneeLogs.length > 0) {
    Object.entries(filteredImportKneeLogs).forEach(([, value]) => {
      importDoctorKneeEmails.push(getVideoDoctorName(value.videoDoctorEmail))
    })
  } else if (dateRange.length === 0 || filteredImportKneeLogs.length === 0) {
    Object.entries(filteredImportKneeLogs).forEach(() => {
      importDoctorKneeEmails.push()
    })
  } else {
    Object.entries(importLogs).forEach(([, value]) => {
      importDoctorKneeEmails.push(getVideoDoctorName(value.videoDoctorEmail))
    })
  }

  if (filteredImportShoulderLogs.length > 0) {
    Object.entries(filteredImportShoulderLogs).forEach(([, value]) => {
      importDoctorShoulderEmails.push(
        getVideoDoctorName(value.videoDoctorEmail),
      )
    })
  } else if (
    dateRange.length === 0 ||
    filteredImportShoulderLogs.length === 0
  ) {
    Object.entries(filteredImportShoulderLogs).forEach(() => {
      importDoctorShoulderEmails.push()
    })
  } else {
    Object.entries(importLogs).forEach(([, value]) => {
      importDoctorShoulderEmails.push(
        getVideoDoctorName(value.videoDoctorEmail),
      )
    })
  }

  if (filteredExportLogs.length > 0) {
    Object.entries(filteredExportLogs).forEach(([, value]: any) => {
      if (value.videoRegion == 'Knee') {
        exportDoctorKneeEmails.push(getVideoDoctorName(value.surgeonEmail))
        exportClassesKnee.push(value.classes)
        exportFrameCountKnee.push(value.annotatedFrameCount)
        exportDoctorEmailsKneeCSV.push(value.surgeonEmail)
      }

      if (value.videoRegion == 'Shoulder') {
        exportDoctorShoulderEmails.push(getVideoDoctorName(value.surgeonEmail))
        exportClassesShoulder.push(value.classes)
        exportFrameCountShoulder.push(value.annotatedFrameCount)
        exportDoctorEmailsShoulderCSV.push(value.surgeonEmail)
      }
    })
  } else if (
    dateRange.length === 0 ||
    (filteredExportLogs.length === 0 && desiredSurgeonEmail !== undefined)
  ) {
    Object.entries(filteredExportLogs).forEach(() => {
      exportDoctorKneeEmails.push()
      exportDoctorShoulderEmails.push()
      exportClassesKnee.push()
      exportClassesShoulder.push()
      exportFrameCountKnee.push()
      exportFrameCountShoulder.push()
      exportDoctorEmailsKneeCSV.push()
      exportDoctorEmailsShoulderCSV.push()
    })
  } else {
    Object.entries(exportLogs).forEach(([, value]) => {
      if (value.videoRegion == 'Knee') {
        exportDoctorKneeEmails.push(getVideoDoctorName(value.surgeonEmail))
        exportClassesKnee.push(value.classes)
        exportFrameCountKnee.push(value.annotatedFrameCount)
        exportDoctorEmailsKneeCSV.push(value.surgeonEmail)
      }

      if (value.videoRegion == 'Shoulder') {
        exportDoctorShoulderEmails.push(getVideoDoctorName(value.surgeonEmail))
        exportClassesShoulder.push(value.classes)
        exportFrameCountShoulder.push(value.annotatedFrameCount)
        exportDoctorEmailsShoulderCSV.push(value.surgeonEmail)
      }
    })
  }

  const importDoctorKneeData = {}
  const importDoctorShoulderData = {}
  const exportDoctorKneeData = {}
  const exportDoctorShoulderData = {}

  importDoctorKneeEmails.forEach(element => {
    importDoctorKneeData[element] = (importDoctorKneeData[element] || 0) + 1
  })

  importDoctorShoulderEmails.forEach(element => {
    importDoctorShoulderData[element] =
      (importDoctorShoulderData[element] || 0) + 1
  })

  exportDoctorKneeEmails.forEach(element => {
    exportDoctorKneeData[element] = (exportDoctorKneeData[element] || 0) + 1
  })

  exportDoctorShoulderEmails.forEach(element => {
    exportDoctorShoulderData[element] =
      (exportDoctorShoulderData[element] || 0) + 1
  })

  const generateCSVData = (doctorEmails: any[], dateRange: any[]) => {
    if (doctorEmails.length === 0 || dateRange.length === 0) {
      return { headers: [], data: [] }
    }

    const stringDates = dateRange.map(date =>
      new Date(date).toLocaleDateString('en-CA'),
    )

    const doctorCounts = doctorEmails.reduce((acc, name) => {
      acc[name] = (acc[name] || 0) + 1
      return acc
    }, {})

    const data = Object.entries(doctorCounts).map(([doctorName, count]) => ({
      doctorName,
      count,
    }))

    const csvData = [
      { doctorName: `From: ${stringDates[0]}`, count: `To: ${stringDates[1]}` },
      ...data,
    ]

    return { data: csvData }
  }

  const importCSVKneeData = generateCSVData(importDoctorKneeEmails, dateRange)
  const importKneeHeaders =
    [
      { label: 'Doctor Name', key: 'doctorName' },
      { label: 'Knee Video Count', key: 'count' },
    ] || []
  const importKneeData = importCSVKneeData.data || []

  const importCSVShoulderData = generateCSVData(
    importDoctorShoulderEmails,
    dateRange,
  )
  const importShoulderHeaders =
    [
      { label: 'Doctor Name', key: 'doctorName' },
      { label: 'Shoulder Video Count', key: 'count' },
    ] || []
  const importShoulderData = importCSVShoulderData.data || []

  const exportCSVKneeData = generateCSVData(exportDoctorKneeEmails, dateRange)
  const exportCSVShoulderData = generateCSVData(
    exportDoctorShoulderEmails,
    dateRange,
  )
  const exportKneeHeaders =
    [
      { label: 'Doctor Name', key: 'doctorName' },
      { label: 'Knee Video Count', key: 'count' },
    ] || []
  const exportShoulderHeaders =
    [
      { label: 'Doctor Name', key: 'doctorName' },
      { label: 'Shoulder Video Count', key: 'count' },
    ] || []
  const exportKneeData = exportCSVKneeData.data || []
  const exportShoulderData = exportCSVShoulderData.data || []

  const csvHeadersKnee = [
    { label: 'Doctor Name', key: 'surgeonName' },
    { label: 'Knee Classes', key: 'class' },
    { label: 'Frame Counts', key: 'count' },
    { label: 'Total Frame Count', key: 'totalCount' },
    { label: 'Total Video Count', key: 'totalVideoCount' },
  ]

  const csvHeadersShoulder = [
    { label: 'Doctor Name', key: 'surgeonName' },
    { label: 'Shoulder Classes', key: 'class' },
    { label: 'Frame Counts', key: 'count' },
    { label: 'Total Frame Count', key: 'totalCount' },
    { label: 'Total Video Count', key: 'totalVideoCount' },
  ]

  function generateClassesCSV(
    inputArray: string[],
    exportDoctorEmailsCSV: string[],
    exportClasses: string[][],
    exportFrameCount: any,
    dateRange: any[],
  ): any[] {
    if (
      inputArray.length === 0 ||
      exportDoctorEmailsCSV.length === 0 ||
      exportClasses.length === 0 ||
      exportFrameCount.length === 0
    ) {
      return []
    }

    const stringDates = dateRange.map(date =>
      new Date(date).toLocaleDateString('en-CA'),
    )

    const data: any = {}
    const videoCounts: { [key: string]: number } = {}

    inputArray.forEach((surgeonName: string) => {
      if (!videoCounts[surgeonName]) {
        videoCounts[surgeonName] = 1
      } else {
        videoCounts[surgeonName]++
      }
    })

    exportDoctorEmailsCSV.forEach((email: string, index: number) => {
      const surgeonName = getVideoDoctorName(email)

      if (!data[surgeonName]) {
        data[surgeonName] = {
          surgeonName: surgeonName,
          class: [],
          count: [],
          totalCount: 0,
        }
      }

      exportClasses[index]?.forEach((name: string, classIndex: number) => {
        const classPos = data[surgeonName].class.findIndex(
          (cls: string) => cls === name,
        )
        if (classPos === -1) {
          data[surgeonName].class.push(name)
          data[surgeonName].count.push(exportFrameCount[index]?.[classIndex])
          data[surgeonName].totalCount += exportFrameCount[index]?.[classIndex]
        } else {
          data[surgeonName].count[classPos] +=
            exportFrameCount[index]?.[classIndex]
          data[surgeonName].totalCount += exportFrameCount[index]?.[classIndex]
        }
      })
    })

    const formattedData = Object.values(data).map((entry: any) => {
      return {
        ...entry,
        totalVideoCount: videoCounts[entry.surgeonName],
      }
    })

    const csvData = [
      {
        surgeonName: `From: ${stringDates[0]}`,
        class: `To: ${stringDates[1]}`,
      },
      ...formattedData,
    ]

    return csvData
  }

  const formattedKneeLogs = generateClassesCSV(
    exportDoctorKneeEmails,
    exportDoctorEmailsKneeCSV,
    exportClassesKnee,
    exportFrameCountKnee,
    dateRange,
  )

  const formattedShoulderLogs = generateClassesCSV(
    exportDoctorShoulderEmails,
    exportDoctorEmailsShoulderCSV,
    exportClassesShoulder,
    exportFrameCountShoulder,
    dateRange,
  )

  return (
    <div>
      <Stack gutter={Spacing.LARGE}>
        <Stack vertical fillParentWidth gutter={Spacing.SMALL} width="40%">
          <Stack alignItems="start" gutter={Spacing.EXTRA_SMALL}>
            <Text color={DARK_BLUE} fontWeight={600} fontSize={14}>
              Filter by date
            </Text>
          </Stack>
          <RangePicker
            style={{
              width: '80%',
            }}
            defaultValue={[initialStartDate, initialEndDate]}
            onChange={onChange}
            size="large"
            allowClear={true}
          />
        </Stack>
        <Stack
          style={{
            alignSelf: 'center',
          }}
          vertical
          fillParentWidth
          gutter={Spacing.SMALL}
          width="40%">
          <Stack alignItems="start" gutter={Spacing.EXTRA_SMALL}>
            <Text color={DARK_BLUE} fontWeight={600} fontSize={14}>
              Filter by surgeon email
            </Text>
          </Stack>
          <Select
            defaultValue={desiredSurgeonEmail}
            style={{ width: 350, fontSize: '20px' }}
            allowClear
            onChange={handleSurgeonEmailChange}>
            {uniqueDoctorEmails.map((uniqueDoctorEmail: string) => (
              <Option
                key={getVideoDoctorName(uniqueDoctorEmail)}
                value={uniqueDoctorEmail}>
                {getVideoDoctorName(uniqueDoctorEmail)}
              </Option>
            ))}
          </Select>
        </Stack>
        <Stack
          style={{
            marginTop: '1px',
          }}
          vertical
          fillParentWidth
          gutter={Spacing.SMALL}
          width="40%">
          <Stack alignItems="start" gutter={Spacing.EXTRA_SMALL}>
            <Text color={DARK_BLUE} fontWeight={600} fontSize={14}>
              Filter by classes
            </Text>
          </Stack>
          <Select
            defaultValue={desiredClass}
            style={{ width: 350, fontSize: '20px' }}
            allowClear={true}
            onChange={handleClassChange}>
            {uniqueClassesArray.map((uniqueClass: string) => (
              <Option key={uniqueClass} value={uniqueClass}>
                {uniqueClass}
              </Option>
            ))}
          </Select>
        </Stack>
      </Stack>
      <div
        style={{
          textAlign: 'center',
          margin: '25px',
        }}></div>
      <Stack>
        <h1 style={{ paddingRight: '1rem' }}>
          Selected Knee Data (Inhance to Darwin) -{' '}
          {filteredImportKneeLogs.length === 0 && dateRange.length === 0
            ? importDoctorKneeEmails.length
            : filteredImportKneeLogs.length}{' '}
          videos
        </h1>
        <Button>
          <CSVLink
            data={importKneeData}
            headers={importKneeHeaders}
            filename={'Selected-Knee-Data.csv'}>
            Download as CSV
          </CSVLink>
        </Button>
      </Stack>
      <ImportChart importLogsData={importDoctorKneeData} />
      <Stack>
        <h1 style={{ paddingRight: '1rem' }}>
          Selected Shoulder Data (Inhance to Darwin) -{' '}
          {filteredImportShoulderLogs.length === 0 && dateRange.length === 0
            ? importDoctorShoulderEmails.length
            : filteredImportShoulderLogs.length}{' '}
          videos
        </h1>
        <Button>
          <CSVLink
            data={importShoulderData}
            headers={importShoulderHeaders}
            filename={'Selected-Shoulder-Data.csv'}>
            Download as CSV
          </CSVLink>
        </Button>
      </Stack>
      <ImportChart importLogsData={importDoctorShoulderData} />
      <Stack>
        <h1 style={{ paddingTop: '1rem', paddingRight: '1rem' }}>
          Labeled Knee Data (Darwin to S3) -{' '}
          {filteredExportKneeLogs.length === 0 &&
          desiredSurgeonEmail === undefined
            ? exportDoctorKneeEmails.length
            : filteredExportKneeLogs.length}{' '}
          videos{' '}
        </h1>
        <Button style={{ marginTop: '1rem' }}>
          <CSVLink
            data={exportKneeData}
            headers={exportKneeHeaders}
            filename={'Labeled-Knee-Data.csv'}>
            Download as CSV
          </CSVLink>
        </Button>
      </Stack>
      <ExportChart exportLogsData={exportDoctorKneeData} />
      <Stack>
        <h1 style={{ paddingTop: '1rem', paddingRight: '1rem' }}>
          Labeled Shoulder Data (Darwin to S3) -{' '}
          {filteredExportShoulderLogs.length === 0 &&
          desiredSurgeonEmail === undefined
            ? exportDoctorShoulderEmails.length
            : filteredExportShoulderLogs.length}{' '}
          videos{' '}
        </h1>
        <Button style={{ marginTop: '1rem' }}>
          <CSVLink
            data={exportShoulderData}
            headers={exportShoulderHeaders}
            filename={'Labeled-Shoulder-Data.csv'}>
            Download as CSV
          </CSVLink>
        </Button>
      </Stack>
      <ExportChart exportLogsData={exportDoctorShoulderData} />
      <Stack>
        <h1 style={{ paddingTop: '1rem', paddingRight: '1rem' }}>
          Classes Distribution (Knee)
        </h1>
        <Button style={{ marginTop: '1rem' }}>
          <CSVLink
            data={formattedKneeLogs}
            headers={csvHeadersKnee}
            filename="Knee-Classes-Data.csv">
            Download as CSV
          </CSVLink>
        </Button>
      </Stack>
      <ClassChart
        exportFrameCount={exportFrameCountKnee}
        exportClasses={exportClassesKnee}
        desiredClass={desiredClass}
      />

      <Stack>
        <h1 style={{ paddingTop: '1rem', paddingRight: '1rem' }}>
          Classes Distribution (Shoulder)
        </h1>
        <Button style={{ marginTop: '1rem' }}>
          <CSVLink
            data={formattedShoulderLogs}
            headers={csvHeadersShoulder}
            filename="Shoulder-Classes-Data.csv">
            Download as CSV
          </CSVLink>
        </Button>
      </Stack>
      <ClassChart
        exportFrameCount={exportFrameCountShoulder}
        exportClasses={exportClassesShoulder}
        desiredClass={desiredClass}
      />
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  importLogs: getImportLogs(state),
  exportLogs: getExportLogs(state),
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchImportLogs: fetchImportLogsFlow,
      fetchExportLogs: fetchExportLogsFlow,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(Analytics)
