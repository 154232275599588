export enum Colors {
  PRIMARY = '#5d78ff',
  SUCCESS = '#0abb87',
  DANGER = '#fd397a',
  LIGHT = '#f8f9fa',
  DARK = '#343a40',
  GREY_BLUE = '#646c9a',
  PALE_GREY = '#a7abc3',
}

export const BLACK = '#1E1F21'
export const WHITE = '#fff'

export const RED = '#EB5757'
export const TANGERINE = '#ff991a'
export const YELLOW = '#FFBB28'
export const GREEN = '#79E4A5'

// BLUES
export const BLUE = '#1890FF'
export const GREY_BLUE = '#646c9a'
export const DARK_BLUE = '#485D71'
export const PALE_DARK_BLUE = '#485D7166'
export const PALE_BLUE = '#F3F4FB'

// GREYS
export const GREY = '#5D5D5D'
export const LIGHT_GREY = '#A0A0A0'
export const PALE_GREY = '#dadada42'
